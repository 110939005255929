import React, { useState, useEffect, useContext } from 'react';
import { LateSubmissionStyle } from '../../common/IndexTable/LateSubmissionActions';
import { ContinueMediumStyle, ContinueBigStyle } from '../../common/IndexTable/ContinueAnsweringOrMarkingActions';
import ActionModal from '../../common/IndexTable/ActionModal';
import { useModal } from '../../common/Hooks/useModal';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import '../Response.scss';
import propTypes from 'prop-types';
import { get, isEmpty } from 'lodash';

import Api from '../../api/call';
import {
  RESPONSES_UPDATE_ANSWERS_RESET_STORE,
  RESPONSES_SHOW,
  RESPONSES_SHOW_RESET_STORE,
} from '../../root/action-types';
// Components:
import { Pane, StatusHandler, SimpleButton, ButtonRow, Conditional, Loading } from '../../common/';
import { ClosableStatus } from '../../common/Form/Status';
import Question from '../QuestionTypes/Question';
import Timer from './Timer/Timer';
import ResponseMeta from '../ResponseMeta/ResponseMeta';
// Reducers:
import { QuestionProvider, QuestionsContext } from '../ResponseCreate/question-manager';
// Assets:
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

const initializeNextUnanswered = (unanswered, currentResponseId) => {
  if (unanswered === undefined) return undefined;
  const filtered = unanswered.filter((item) => item.state.responseId !== currentResponseId);

  const [nextUnanswered, ...restUnanswered] = filtered;

  return nextUnanswered === undefined
    ? undefined
    : { ...nextUnanswered, state: { ...nextUnanswered.state, unanswered: restUnanswered } };
};

const ActionButtons = ({ submit, disabled }) => (
  <ButtonRow theme="right controls">
    <SimpleButton
      text="SUBMIT"
      onClick={submit}
      disabled={disabled}
    />
  </ButtonRow>
)

const Buttons = ({ submit, cancel, disabled }) => (
  <ButtonRow theme="right controls">
    <div>Are you sure?</div>
    <SimpleButton text="SUBMIT" onClick={submit} disabled={disabled} />
    <SimpleButton
      text="CANCEL"
      theme="muted"
      onClick={cancel}
      disabled={disabled}
    />
  </ButtonRow>
)

const StartScreen = ({ meta, start }) => (
  <Pane theme="response answer">
    <h2 className="start-title">ANSWER</h2>
    <h2 className="answer-title">Constructed Response Question</h2>
    <ResponseMeta form={meta} finalised />
    <div className="start-screen">
      <p>
        In order to simulate exam conditions and prepare you for the real examination, a 15-minute timer will display
        upon starting each CRQ.
        <br />
        <br />
        Should you reach the end of the 15 minutes, an alert will display and you will have the option to either
        continue completing your answer or submit current progress.
        <br />
        <br />
        Should you leave the page prior to submitting your answer, you will lose your progress and have to restart.
        <br />
        <br />
        Therefore, please ensure you have the allotted 15 minutes available before clicking <strong>START</strong>.
      </p>
      <SimpleButton theme="time" onClick={start}>
        <div>Start</div>
        <FontAwesomeIcon icon={faClock} />
        <div>15&quot;</div>
      </SimpleButton>
      <NavLink to="/crq/dashboard">CANCEL AND GO BACK</NavLink>
    </div>
  </Pane>
);

const QuestionContextWrapper = (props) => {
  return (
    <QuestionProvider>
      <TimerWrapper {...props} />
    </QuestionProvider>
  )
}

const TimerWrapper = (props) => {
  const [ timeIsUp, setTimeIsUp ] = useState(false)
  const [ disabled, setDisabled ] = useState(true)

  return (
    <React.Fragment>
      <Timer disabled={disabled} setTimeIsUp={setTimeIsUp}/>
      <ResponseAnswer {...props} setTimerDisabled={setDisabled} timeIsUp={timeIsUp}/>
    </React.Fragment>
  )
}

function ResponseAnswer(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [hideStatus, setHideStatus] = useState(false);
  const [meta, setMeta] = useState({});
  const [id, setId] = useState(null);
  const [answered, setAnswered] = useState(false);
  const [started, setStarted] = useState(false);
  const [responseLoading, setResponseLoading] = useState(true);
  const questions = useContext(QuestionsContext)
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const response = useSelector((store) => store.responses.show);
  const status = useSelector((store) => store.responses.answerResponseStatus);
  const [questionErrors, setQuestionErrors] = useState([]);
  const storeUserId = useSelector((store) => store.session.current.id);
  const { responseId, uid, admin, submittedLate, unanswered } = get(props, 'location.state', {});
  const { openModal, closeModal, modalIsOpen } = useModal();
  const userId = admin ? Number(uid) : storeUserId;
  const nextUnanswered = initializeNextUnanswered(unanswered, responseId);
  const copiedFrom = get(response, 'data.copiedFrom', '')

  const loadResponse = () => {
    if (responseId === undefined) return history.push('/crq/dashboard');
    if (!responseLoading) {
      const data = get(response, 'data.data', '{}');
      setId(get(response, 'data.id'));
      setMeta(get(data, 'meta'));
      const answered = get(data, 'answeredBy', []).includes(userId);
      setAnswered(answered);
      const qs = get(data, 'questions', []);
      if (answered) {
        questions.dispatch({
          type: 'loadAnswers',
          data: qs,
          userId,
        });
      } else {
        questions.dispatch({
          type: 'reinitialize',
          data: qs,
        });
      }
    }
  };

  const isLoading = () => {
    if (!isEmpty(get(response, 'data'))) setResponseLoading(false);
  };

  const makeRequests = () => {
    if (responseId) Api({ request: RESPONSES_SHOW, id: responseId });

    return () => {
      dispatch({ type: RESPONSES_UPDATE_ANSWERS_RESET_STORE });
      dispatch({ type: RESPONSES_SHOW_RESET_STORE });
    };
  };

  const checkErrors = () => {
    if (get(status.errors, 'length')) {
      setAnswered(false)
      setConfirmSubmit(false)
      props.setTimerDisabled(false)
      if(modalIsOpen){
        closeModal()
      }
    }
    setQuestionErrors(get(status.errorData, 'questions', []));
  };

  useEffect(checkErrors, [status.errors, status.errorFields, status.errorData]);
  useEffect(loadResponse, [userId, responseLoading]);
  useEffect(isLoading, [response]);
  useEffect(makeRequests, []);
  useEffect(() => {
    if(props.timeIsUp){
      submitAnswers()
    }
  }, [props.timeIsUp]);

  function submitAnswers() {
    questions.dispatch({
      type: 'apiSubmitAnswers',
      responseId,
      userId,
      uid,
    });
    setAnswered(true);
    setHideStatus(false);
    props.setTimerDisabled(true)
    questions.dispatch({
      type: 'fillableExpandAnswers',
      data: questions.data,
      userId,
    });

    if (!modalIsOpen) {
      openModal();
    }
  }

  const invertHideStatus = () => {
    setHideStatus(!hideStatus);
  };

  const RenderStatus = () => {
    return (
      <ClosableStatus isError={status.errors} hideStatus={hideStatus} invertHideStatus={invertHideStatus}>
        <StatusHandler
          loading={status.loading}
          errors={status.errors}
          action={status.updated}
          onSuccess="CRQ answers have been submitted."
        />
      </ClosableStatus>
    );
  };

  const DisplayedButtons = () => {
    if (confirmSubmit) return (
      <Buttons
        submit={submitAnswers}
        cancel={() => setConfirmSubmit(false)}
        disabled={answered || responseLoading}
      />
    )
    return (
      <ActionButtons
        submit={() => setConfirmSubmit(true)}
        disabled={answered || responseLoading}
      />
    )
  };

  if (responseLoading) return <Loading />;
  if (!started && !answered) return (
    <StartScreen meta={meta} start={() => {
      setStarted(true)
      props.setTimerDisabled(false)
    }} />
  )
  return (
    <Pane theme="response answer">
      <DisplayModal
        openModal={openModal}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        submittedLate={submittedLate}
        nextUnanswered={nextUnanswered}
        admin={admin}
      />
      <RenderStatus />
      <Conditional if={!answered}>
        <DisplayedButtons />
      </Conditional>
      <Conditional if={admin && answered}>
        <CopiedFrom copiedFrom={copiedFrom} />
      </Conditional>
      <div className="answer-title-wrap">
        <h2 className="answer-title">Constructed Response Question</h2>
        <div className="field createdby">Created By: ECN-{get(response, 'data.data.creatorEcn', ' ')}</div>
      </div>
      <ResponseMeta form={meta} finalised />
      <QuestionIndex errors={questionErrors} data={questions.data} dispatch={questions.dispatch} answered={answered} />
      <Conditional if={!answered}>
        <DisplayedButtons />
      </Conditional>
    </Pane>
  );
}

const QuestionIndex = ({ data, dispatch, answered }) => {
  return data.map((item, idx) => {
    const { name } = item;
    const key = [name, idx].join('_');
    const itemProps = { item, idx };

    return <QuestionItem key={key} answered={answered} dispatch={dispatch} {...itemProps} />;
  });
};

const QuestionItem = React.memo(({ dispatch, answered, ...props }) => {
  const { item, idx } = props;
  const questionProps = {
    item: item,
    idx: idx,
    dispatch: dispatch,
    disableAnswer: answered,
    hideActions: true,
  };

  return <Question name={item.name} {...questionProps} />;
});

const DisplayModal = ({ nextUnanswered, submittedLate, openModal, closeModal, modalIsOpen, admin }) => {
  const examsToAnswer = nextUnanswered !== undefined;

  if (examsToAnswer) {
    const customStyle = admin || !submittedLate ? ContinueMediumStyle : ContinueBigStyle;
    const progressionQuestion = "Are you ready to Answer the next Question?"
    const progressionText = "If you choose not to continue, all progress of this Paper may be lost."
    return (
      <ActionModal
        data={{
          submittedLate,
          isAdmin: admin,
          progressionQuestion: progressionQuestion,
          progressionText: progressionText,
          nextUnfinished: nextUnanswered,
        }}
        customStyle={customStyle}
        openModal={openModal}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        model="CONTINUE_ANSWERING_OR_MARKING"
      />
    );
  } else if (submittedLate && !admin) {
    return (
      <ActionModal
        openModal={openModal}
        closeModal={closeModal}
        customStyle={LateSubmissionStyle}
        modalIsOpen={modalIsOpen}
        model="LATE_SUBMISSION"
      />
    );
  }
  return null;
};

const CopiedFrom = ({ copiedFrom }) => {
  if (copiedFrom) {
    const styles = {
      textAlign: "right",
      marginBottom: "0.5em"
    }
    return <div style={styles}>Substituted from {copiedFrom}</div>;
  }
  return null;
};

export default QuestionContextWrapper;

ResponseAnswer.propTypes = {
  response: propTypes.shape({
    loading: propTypes.bool,
    errors: propTypes.array,
    created: propTypes.bool,
  }),
  item: propTypes.shape({
    name: propTypes.string,
  }),
  match: propTypes.shape({
    params: propTypes.shape({
      id: propTypes.number,
    }),
  }),
  idx: propTypes.number,
  admin: propTypes.bool,
};

import React from 'react';
import { useSelector } from 'react-redux';
import { startCase } from 'lodash';
import propTypes from 'prop-types';
// Components:
import PagedIndexTable from './PagedIndexTable';

const ModelIndexTable = ({ model, dataMap, requestAction, defaultColumns, noShow }) => {
  const selector = ({ [model]: { index } }) => {
    return index;
  };
  const { data, count, total } = useSelector(selector);
  const indexColumns = defaultColumns.map((column) => ({
    Header: startCase(column),
    accessor: column,
  }));

  const mappedData = Object.values(data).map((o) => dataMap(o));

  return (
    <PagedIndexTable
      requestDataAction={requestAction}
      model={model}
      data={mappedData}
      columns={indexColumns}
      count={count}
      total={total}
      noShow={noShow}
    />
  );
};

export default ModelIndexTable;

ModelIndexTable.propTypes = {
  model: propTypes.string.isRequired,
  dataMap: propTypes.func.isRequired,
  requestAction: propTypes.string.isRequired,
  defaultColumns: propTypes.array.isRequired,
};

import React from 'react';
import './InputField.scss';
import propTypes from 'prop-types';
// Components:
import { Field } from '../../common/Form/Field';

// A standard Input Field.
function InputField(props) {
  const css = ['simple-field'];

  if (props.theme) {
    css.push(props.theme);
  }
  if (props.error) {
    css.push('error');
  }

  return (
    <Field className={css.join(' ')} label={props.label}>
      <input
        className={css.join(' ')}
        name={props.name}
        value={props.value || ''}
        onChange={props.onChange}
        type={props.type}
        placeholder={props.placeholder}
        disabled={props.disabled}
        autoComplete="off"
      />
    </Field>
  );
}

export default InputField;

InputField.propTypes = {
  disabled: propTypes.bool,
  error: propTypes.bool,
  theme: propTypes.string,
  name: propTypes.string,
  label: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  type: propTypes.string,
  placeholder: propTypes.string,
};

import { useState, useEffect } from 'react';
import { USERS } from '../../root/action-types';
import { useSelector } from 'react-redux';
import Api from '../../api/call';

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const { loading, data } = useSelector((store) => store.users.index);

  useEffect(() => {
    Api({
      request: USERS,
      params: { scope: 'all' },
    });
  }, []);

  useEffect(() => {
    if (loading) return;

    setUsers(data);
  }, [loading, data]);

  return { users, setUsers };
};

export default useUsers;

import React, { useEffect } from 'react';
import { QuestionProvider, QuestionsContext } from '../ResponseCreate/question-manager';
import Download from '../../api/Download';
import ResponseMeta from '../ResponseMeta/ResponseMeta';
import { QuestionIndex } from '../ResponseEmpty/QuestionIndex';
import AnswerGuidance from '../AnswerGuidance/AnswerGuidance';
import { Pane,
  ButtonRow,
  TabGroup,
  SimpleButton,
  TabContent,
} from '../../common/';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { RESPONSES_SHOW, RESPONSES_SHOW_RESET_STORE } from '../../root/action-types';
import Api from '../../api/call';

const getResponseById = (responseId) => {
  if (responseId) Api({ request: RESPONSES_SHOW, id: responseId });
};

const ResponseEmptyWrapper = (props) => {
  return (
    <QuestionProvider>
      <ResponseEmpty {...props}/>
    </QuestionProvider>
  )
}

function ResponseEmpty(props) {
  const questions = React.useContext(QuestionsContext)

  const dispatch = useDispatch();
  const { ecn } = get(props, 'location.state', {});
  const response = useSelector((store) => store.responses.show);
  const responseId = props.match.params.responseId;

  useEffect(() => {
    const data = get(response, 'data.data.questions', [])
    questions.dispatch({
      type: 'reinitialize',
      data: data,
    });
  }, [response])

  useEffect(() => {
    getResponseById(responseId);

    return () => {
      dispatch({ type: RESPONSES_SHOW_RESET_STORE });
    };
  }, []);

  return (
    <RenderQuestions
      meta={get(response, 'data.data.meta')}
      questions={questions.data}
      ecn={ecn}
      responseId={responseId}
    />
  );
}

const RenderQuestions = ({ meta, questions, ecn, responseId }) => {
  if (!meta || !questions || !ecn || !responseId) return null;

  return (
    <Pane theme="response create">
      <TabGroup labels={['CRQ', 'AGS']}>
        <TabContent className="custom-response">
          <h2>Constructed Response Question</h2>
          <div className="field createdby">Created By: ECN-{ecn}</div>
          <ResponseMeta form={meta} finalised />
          <QuestionIndex questions={questions} submitted={true} />
        </TabContent>
        <TabContent className="custom-response">
          <AnswerGuidance questions={questions} submitted={true}>
            <ResponseMeta form={meta} finalised />
          </AnswerGuidance>
        </TabContent>
      </TabGroup>
      <ButtonRow theme="right controls">
        <Download type="crq" id={responseId}>
          <SimpleButton text="Export as PDF" />
        </Download>
      </ButtonRow>
    </Pane>
  );
};
export default ResponseEmptyWrapper;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import { get } from 'lodash';
import Api from '../../api/call';
import './ResponseDashboard.scss';
import {
  GROUPS_SHOW_RESET_STORE,
  CURRENT_RESPONSE,
  CURRENT_ANSWER_RESPONSES,
  CURRENT_MARK_RESPONSES,
} from '../../root/action-types';
import useGroupResponses from '../useGroupResponses';
// Components:
import { Pane, TabGroup, TabContent, useSelectedArticles, useGroupUsers, Conditional } from '../../common/';
import MarksIndex from '../MarksIndex/MarksIndex';
import { AttachmentLink } from '../../attachment/Attachment';
import ResponseCreateTab from './ResponseCreateTab';
import ResponseAnswerTab from './ResponseAnswerTab';
import ResponseMarkTab from './ResponseMarkTab';

export const TopicBar = ({ to, title, prefix, topicClass, btnClass, btnText, nextToBtnText, onClick, disabled, attachment }) => (
  <div className={`topic-bar ${topicClass}`}>
    <div className="topic-prefix">{prefix}</div>
    <div className="topic-title" onClick={onClick}>
      <div className="topic">
        {title}
      </div>
      <Conditional if={attachment}>
        <AttachmentLink path={attachment} />
      </Conditional>
    <div className="nextBtnText">{nextToBtnText}</div>
    </div>
    <NavLink className={`topic-link ${btnClass}`} to={to} disabled={disabled}>
      {btnText}
    </NavLink>
  </div>
);

export const AdminTopicBar = ({ prefix, title, questions, children }) => (
  <div className="topic-bar">
    <div className="topic-prefix">{prefix}</div>
    <div className="topic-title">{title}</div>
    {questions.map((q, i) => (
      <NavLink key={i} className={`topic-link ${q.btnClass}`} to={q.to} disabled={q.disabled}>
        {q.btnText}
      </NavLink>
    ))}
    {children}
  </div>
);

function ResponseDashboard(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector((store) => store.session.current);
  const groupId = get(props, 'groupId', 1)
  const { responses } = useGroupResponses(groupId);

  useEffect(() => {
    if (!props.admin) {
      Api({ request: CURRENT_RESPONSE });
      Api({ request: CURRENT_ANSWER_RESPONSES });
      Api({ request: CURRENT_MARK_RESPONSES });
    }

    return () => dispatch({ type: GROUPS_SHOW_RESET_STORE });
  }, []);

  const getGroupId = () => {
    if (props.admin) {
      return groupId
    }
    return currentUser.groupId;
  };

  const articles = useSelectedArticles(get(props.currentPaper, 'id'))[0];
  const {
    groupUsers,
  } = useGroupUsers(getGroupId());

  const getTabLabels = () => {
    const TAB_LABELS = ['CREATE CRQ/AGS', 'ANSWER', 'MARK'];
    if (!props.admin) TAB_LABELS.push('YOUR MARKED PAPERS');
    return TAB_LABELS;
  };

  const blocked = () => {
    if (currentUser.admin) {
      return false;
    }

    if (!get(currentUser, 'groupId')) {
      return true;
    }
    if (currentUser.blocked) {
      return true;
    }

    return false;
  };

  const setInitialTab = () => {
    if (get(props, 'location.state.position')) {
      return props.location.state.position;
    }
    return 0;
  };

  return (
    <Pane>
      <div className="create-crq-container">
        <TabGroup labels={getTabLabels()} initial={setInitialTab()}>
          <ResponseCreateTab
            {...props}
            responses={responses}
            users={groupUsers}
            articles={articles}
            currentPaper={props.currentPaper}
            blocked={blocked()}
          />
          <ResponseAnswerTab
            {...props}
            currentUser={currentUser}
            users={groupUsers}
            articles={articles}
            currentPaper={props.currentPaper}
            blocked={blocked()}
          />
          <ResponseMarkTab
            {...props}
            currentUser={currentUser}
            users={groupUsers}
            articles={articles}
            currentPaper={props.currentPaper}
            blocked={blocked()}
          />
          {/* WTF eslint...? */}
          {props.admin ? null : (
            <TabContent className="response-pane">
              <MarksIndex />
            </TabContent>
          )}
        </TabGroup>
      </div>
    </Pane>
  );
}

export default ResponseDashboard;

ResponseDashboard.propTypes = {
  prefix: propTypes.string,
  title: propTypes.string,
  attachment: propTypes.string,
  btnText: propTypes.string,
  btnClass: propTypes.string,
  topicClass: propTypes.string,
  to: propTypes.string,
  disabled: propTypes.bool,
  onClick: propTypes.func,
  admin: propTypes.bool,
  location: propTypes.object,
  match: propTypes.shape({
    params: propTypes.shape({
      id: propTypes.string,
    }),
  }),
  currentPaper: propTypes.object,
};

TopicBar.propTypes = {
  to: propTypes.oneOfType([propTypes.object, propTypes.string]),
  title: propTypes.string,
  prefix: propTypes.oneOfType([propTypes.string, propTypes.number]),
  topicClass: propTypes.string,
  btnClass: propTypes.string,
  btnText: propTypes.string,
  disabled: propTypes.bool,
  attachment: propTypes.string,
  onClick: propTypes.func,
};

AdminTopicBar.propTypes = {
  to: propTypes.string,
  title: propTypes.string,
  prefix: propTypes.number,
  topicClass: propTypes.string,
  btnClass: propTypes.string,
  btnText: propTypes.string,
  disabled: propTypes.bool,
  attachment: propTypes.string,
  questions: propTypes.array,
  children: propTypes.node,
};

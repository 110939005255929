import React from 'react';
import { modalDefaultStyle } from './ModalDefaultStyle';
import SimpleRow from '../../common/SimpleRow/SimpleRow';
import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import './LateSubmissionModal.scss';
import { Conditional } from '../';

export const ContinueAnsweringOrMarkingActions = ({ data, actions }) => {
  const { closeModal } = actions;
  const { submittedLate, isAdmin, nextUnfinished, progressionText, progressionQuestion } = data;

  const closeAndRefresh = () => {
    closeModal();
    window.location.reload();
  };

  return (
    <div className="submission-background">
      <Conditional if={submittedLate && !isAdmin}>
        <p className="medium-header">{progressionQuestion}</p>
        <p className="submission-description">
          You have submitted after the deadline. This has been marked as a late submission.
        </p>
        <p className="submission-description">Faculty may be in touch to issue a Penalty Point.</p>
        <p className="submission-description">{progressionText}</p>
      </Conditional>
      <Conditional if={isAdmin || !submittedLate}>
        <p className="medium-header">{progressionQuestion}</p>
        <p className="submission-description">{progressionText}</p>
      </Conditional>
      <div className="empty-divider"></div>
      <SimpleRow>
        <button className="close-modal-btn" onClick={closeModal}>
          No
        </button>
        <div className="empty-divider"></div>
        <NavLink
          className="next-unfinished-btn"
          to={nextUnfinished || '/groups/'}
          onClick={closeAndRefresh}
          disabled={false}
        >
          Yes
        </NavLink>
      </SimpleRow>
    </div>
  );
};

export const ContinueBigStyle = {
  ...modalDefaultStyle,
  content: {
    ...modalDefaultStyle.content,
    height: '375px',
    width: '500px',
  },
};

export const ContinueMediumStyle = {
  ...modalDefaultStyle,
  content: {
    ...modalDefaultStyle.content,
    height: '350px',
    width: '400px',
  },
};

export const ContinueSmallStyle = {
  ...modalDefaultStyle,
  content: {
    ...modalDefaultStyle.content,
    height: '300px',
  },
};

ContinueAnsweringOrMarkingActions.propTypes = {
  data: propTypes.object,
  actions: propTypes.object,
};

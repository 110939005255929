import React from 'react';
import './ButtonRow.scss';
import propTypes from 'prop-types';

// A Horizontal Button Row.
function ButtonRow(props) {
  const css = ['button-row'];

  if (props.theme) {
    css.push(props.theme);
  }

  return <div className={css.join(' ')}>{props.children}</div>;
}

export default ButtonRow;

ButtonRow.propTypes = {
  theme: propTypes.string,
  children: propTypes.oneOfType([propTypes.element, propTypes.node]),
};

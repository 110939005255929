import { combineReducers } from 'redux';

import { ARTICLES, ARTICLES_ASSIGNMENT, ARTICLES_SHOW } from '../root/action-types';
import { standardModelCreateUpdateDestroy, standardShow } from '../api/standardReducers';

const DEFAULT_COLLECTIONS_STATE = { data: [], total: 0, count: 0 };

const index = (state = DEFAULT_COLLECTIONS_STATE, action) => {
  switch (action.type) {
    case `${ARTICLES}_START`:
    case `${ARTICLES_ASSIGNMENT}_START`:
      return {
        ...state,
        loading: true,
      };
    case `${ARTICLES}_SUCCESS`:
    case `${ARTICLES_ASSIGNMENT}_SUCCESS`: {
      return {
        ...state,
        ...action.response.data,
        loading: false,
      };
    }
    case `${ARTICLES}_FAILURE`:
    case `${ARTICLES_ASSIGNMENT}_FAILURE`:
      return {
        ...state,
        errors: action.response.data.errors,
        loading: false,
      };
    case `${ARTICLES}_RESET_STORE`:
      return DEFAULT_COLLECTIONS_STATE;
    default:
      return state;
  }
};

export default combineReducers({
  index,
  show: standardShow(ARTICLES_SHOW),
  article: standardModelCreateUpdateDestroy('article', ARTICLES),
});

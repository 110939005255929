import React, { useState } from 'react';
import { get } from 'lodash';
import { Conditional } from '../../common';
import './roundAccordion.scss';
import { RoundSelectorWithPapers, SearchBarWithList } from './AdminSegment';
import { RoundSelectorWithoutPapers, ResponseList } from './UserSegment';

const RenderUserSegments = ({ month, year, isVisible, invertVisible, papers }) => {
  return (
    <div>
      <RoundSelectorWithoutPapers month={month} year={year} isVisible={isVisible} invertVisible={invertVisible} />
      <ResponseList isVisible={isVisible} papers={papers} />
    </div>
  );
};

const RenderAdminSegments = ({
  month,
  year,
  isVisible,
  invertVisible,
  papers,
  activePaper,
  setActivePaper,
  responses,
}) => {
  return (
    <div>
      <RoundSelectorWithPapers
        month={month}
        year={year}
        isVisible={isVisible}
        invertVisible={invertVisible}
        papers={papers}
        activePaper={activePaper}
        setActivePaper={setActivePaper}
      />
      <SearchBarWithList isVisible={isVisible} responses={responses} />
    </div>
  );
};

const Segment = ({ period, admin }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [activePaper, setActivePaper] = useState(0);
  const papers = get(period, 'papers', []);
  const responses = get(papers, `[${activePaper}].responses`).sort((a, b) => {
    return a.responseId - b.responseId;
  });
  const month = get(period, 'month');
  const year = get(period, 'year');

  const invertVisible = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="accordionSegment">
      <Conditional if={admin}>
        <RenderAdminSegments
          month={month}
          year={year}
          isVisible={isVisible}
          invertVisible={invertVisible}
          papers={papers}
          activePaper={activePaper}
          setActivePaper={setActivePaper}
          responses={responses}
        />
      </Conditional>
      <Conditional if={!admin}>
        <RenderUserSegments
          month={month}
          year={year}
          isVisible={isVisible}
          invertVisible={invertVisible}
          papers={papers}
        />
      </Conditional>
    </div>
  );
};

export default Segment;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './NavBar.scss';
import propTypes from 'prop-types';
// Components:
import AdminNavBar from './AdminNavBar.jsx';
import MemberNavBar from './MemberNavBar.jsx';

function NavBar() {
  const { admin } = useSelector((store) => store.session.current);
  if (typeof admin === 'undefined') {
    return null;
  }
  return <nav role="navigation">{admin ? <AdminNavBar /> : <MemberNavBar />}</nav>;
}

export default withRouter(NavBar);

NavBar.propTypes = {
  admin: propTypes.bool,
};

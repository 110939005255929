export const standardForCollections = (action_name) => {
  const DEFAULT_COLLECTIONS_STATE = { data: [], total: 0, count: 0 };

  return (state = DEFAULT_COLLECTIONS_STATE, action) => {
    switch (action.type) {
      case `${action_name}_START`:
        return {
          ...state,
          loading: true,
        };
      case `${action_name}_SUCCESS`: {
        return {
          ...state,
          ...action.response.data,
          loading: false,
        };
      }
      case `${action_name}_FAILURE`:
        return {
          ...state,
          errors: action.response.data.errors,
          loading: false,
        };
      case `${action_name}_RESET_STORE`:
        return DEFAULT_COLLECTIONS_STATE;
      default:
        return state;
    }
  };
};

export const standardShow = (action_name) => {
  const DEFAULT_SHOW_STATE = {};

  return (state = DEFAULT_SHOW_STATE, action) => {
    switch (action.type) {
      case `${action_name}_START`:
        return {
          ...state,
          loading: true,
        };
      case `${action_name}_SUCCESS`:
        return {
          ...state,
          data: action.response.data,
          errors: null,
          loading: false,
        };
      case `${action_name}_FAILURE`:
        return {
          ...state,
          errors: action.response.data.errors,
          loading: false,
        };
      case `${action_name}_RESET_STORE`:
        return DEFAULT_SHOW_STATE;
      default:
        return state;
    }
  };
};

export const standardModelCreateUpdateDestroy = (model_name, action_name) => {
  const DEFAULT_CRUD_STATE = { created: false, updated: false, destroyed: false };

  return (state = DEFAULT_CRUD_STATE, action) => {
    switch (action.type) {
      case `${action_name}_CREATE_START`:
      case `${action_name}_UPDATE_START`:
      case `${action_name}_DESTROY_START`:
        return {
          ...state,
          success: false,
          created: false,
          updated: false,
          destroyed: false,
          loading: true,
        };
      case `${action_name}_CREATE_SUCCESS`:
        return {
          ...state,
          created: true,
          success: true,
          data: action.response.data.details,
          errors: action.response.data.errors,
          errorFields: action.response.data.errorFields,
          loading: false,
        };
      case `${action_name}_UPDATE_SUCCESS`:
        return {
          ...state,
          updated: true,
          success: true,
          data: action.response.data.details,
          errors: action.response.data.errors,
          errorFields: action.response.data.errorFields,
          loading: false,
        };
      case `${action_name}_DESTROY_SUCCESS`:
        return {
          ...state,
          destroyed: true,
          success: true,
          data: action.response.data.details,
          errors: action.response.data.errors,
          errorFields: action.response.data.errorFields,
          loading: false,
        };
      case `${action_name}_CREATE_FAILURE`:
      case `${action_name}_UPDATE_FAILURE`:
      case `${action_name}_DESTROY_FAILURE`:
        return {
          ...state,
          errors: action.response.data.errors,
          errorFields: action.response.data.errorFields,
          errorData: action.response.data.errorData,
          loading: false,
        };
      case `${action_name}_CREATE_RESET_STORE`:
      case `${action_name}_UPDATE_RESET_STORE`:
      case `${action_name}_DESTROY_RESET_STORE`:
        return DEFAULT_CRUD_STATE;
      default: {
        return state;
      }
    }
  };
};

export default {};

import React, { Fragment, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import propTypes from 'prop-types';
import Select from 'react-select';
import { options } from '../dropdownOptions.js';
import { EditButtons, ConfirmButtons } from './QuestionButtons';
import './ListQuestion.scss';
import { formats, toolbarModules, buildEvent } from '../../common/RichText/Constants';

import { Prefix } from './Prefix'
import AnswerBox from '../AnswerBox/AnswerBox';
import { Conditional } from '../../common/';
import { useDispatch } from 'react-redux';
import { RESPONSES_CLEAR_ERROR } from '../../root/action-types';

function ListQuestion({ dispatch, ...props }) {
  const [state, setState] = useState(props.item);
  const reduxDispatch = useDispatch()

  const setField = (e) =>
    setState({
      ...state,
      [e.currentTarget.name]: e.currentTarget.value,
    });

  const setMarks = (marks) =>
    setState({
      ...state,
      marks: marks,
    });

  const listAdd = () => {
    setState({
      ...state,
      list: [...state.list, 1],
    });
  };

  const listRemove = () => {
    const list = state.list;
    list.pop();

    setState({ ...state, list: list });
  };

  const Confirm = () => (
    <Fragment>
      <button className="add-answer-box" onClick={listAdd}>
        <div className="plus">+</div>
        <div>Click to add the number of list items a candidate will need to answer.</div>
      </button>
      <ConfirmButtons submitted={props.submitted} idx={props.idx} question={state} dispatch={dispatch} />
    </Fragment>
  );

  const MarkSelector = () => (
    <Select
      className="mark-dropdown"
      classNamePrefix="select"
      onChange={setMarks}
      value={state.marks}
      options={options}
    />
  );

  const ActionButtons = () => {
    if (props.hideActions) {
      return null;
    }
    return props.item.confirmed ? <EditButtons submitted={props.submitted} idx={props.idx} /> : <Confirm />;
  };

  const buildClassName = () =>
    `list-container
    ${!props.item.confirmed ? 'active' : ''}
    ${props.submitted ? 'crq-submitted' : ''}
    ${props.error ? 'error' : ''}`;

  const clearError = (field) => {
    reduxDispatch({ type: RESPONSES_CLEAR_ERROR, section: 'questions', field})
  }
  const onChangeEvent = (name, editor) => {
    const event = buildEvent(name, editor);
    setField(event);
    props.error && clearError(props.idx)
  };
  return (
    <div className={buildClassName()}>
      <div className="input-mark-container">
        <Prefix idx={props.idx} />
        {props.displayOnly ? (
          <div className="static">
            <ReactQuill theme="snow" modules={{ toolbar: false }} value={state.field} readOnly={true} />
          </div>
        ) : (
          <ReactQuill
            theme="snow"
            modules={toolbarModules(props.item.confirmed)}
            value={state.field}
            formats={formats}
            onChange={(content, delta, source, editor) => onChangeEvent('field', editor)}
            placeholder="Enter your question here"
            readOnly={props.item.confirmed}
          />
        )}
        {props.item.confirmed ? <div className="marks">{props.item.marks.label}</div> : <MarkSelector />}
      </div>
      <AnswerBoxList
        state={state}
        dispatch={dispatch}
        displayOnly={props.displayOnly}
        disableAnswer={props.disableAnswer}
        confirmed={props.item.confirmed}
        listRemove={listRemove}
        index={props.idx}
      />
      <ActionButtons />
    </div>
  );
}

const AnswerBoxList = ({ state, dispatch, displayOnly, disableAnswer, confirmed, listRemove, index }) =>
  state.list.map((item, idx) => {
    const userAnswerNumber = ['userAnswer', idx + 1].join('');
    return (
      <div className="list-answers" key={[item.name, idx].join('')}>
        <AnswerBox
          dispatch={dispatch}
          idx={index}
          listPrefix={(idx + 1).toString()}
          name={['userAnswer', idx + 1].join('')}
          disabled={disableAnswer}
          value={state[userAnswerNumber]}
          displayOnly={displayOnly || false}
        />
        <Conditional if={!confirmed}>
          <div className="remove" onClick={listRemove}>
            x
          </div>
        </Conditional>
      </div>
    );
  });
export default ListQuestion;

ListQuestion.propTypes = {
  item: propTypes.object,
  idx: propTypes.number,
  actions: propTypes.object,
  listAdd: propTypes.func,
  index: propTypes.number,
  setMarks: propTypes.func,
  hideActions: propTypes.bool,
  disableAnswer: propTypes.bool,
  submitted: propTypes.bool,
  displayOnly: propTypes.bool,
  error: propTypes.bool,
};

import React from 'react';
import './Status.scss';
import propTypes from 'prop-types';

function RenderCloseButton({ isError, invertHideStatus }) {
  if (isError) {
    return (
      <div className={'close-button'} onClick={invertHideStatus}>
        X
      </div>
    );
  }
  return null;
}

export function ClosableStatus(props) {
  const formatErrors = (errors) => {
    errors.map((e, index) => {
      return <div key={index}>{e}</div>;
    });
  };

  const getStyles = () => {
    if (props.hideStatus) {
      return 'hide-status';
    }
    return props.isError ? 'closable-form-status error' : 'form-status';
  };

  return (
    <div className={getStyles()}>
      <RenderCloseButton invertHideStatus={props.invertHideStatus} isError={props.isError} />
      {formatErrors(props.messages || [])}
      {props.children}
    </div>
  );
}

function Status(props) {
  const formatErrors = (errors) => {
    errors.map((e, index) => {
      return <div key={index}>{e}</div>;
    });
  };

  return (
    <div className={props.isError ? 'form-status error' : 'form-status'}>
      {formatErrors(props.messages || [])}
      {props.children}
    </div>
  );
}

export default Status;

Status.propTypes = {
  isError: propTypes.oneOfType([propTypes.array, propTypes.object, propTypes.bool]),
  messages: propTypes.array,
  children: propTypes.oneOfType([propTypes.element, propTypes.array, propTypes.string]),
};

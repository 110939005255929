import React, { useState, useContext, useEffect } from 'react';
import { useModal } from '../../common/Hooks/useModal';
import ActionModal from '../../common/IndexTable/ActionModal';
import { LateSubmissionStyle } from '../../common/IndexTable/LateSubmissionActions';
import { QuestionIndex } from './QuestionIndex';
import { useSelector, useDispatch } from 'react-redux';
import Api from '../../api/call';
import '../Response.scss';
import {
  RESPONSES_CREATE,
  RESPONSES_UPDATE,
  RESPONSES_CREATE_RESET_STORE,
  RESPONSES_ROLLBACK_STATUS,
  RESPONSES_ROLLBACK_STATUS_RESET_STORE,
  CURRENT_RESPONSE,
  CURRENT_RESPONSE_RESET_STORE,
} from '../../root/action-types';
import propTypes from 'prop-types';
import { get, invoke } from 'lodash';
// Components:
import { ClosableStatus } from '../../common/Form/Status';
import {
  Pane,
  StatusHandler,
  useForm,
  SimpleButton,
  ButtonRow,
  TabGroup,
  TabContent,
  Conditional,
} from '../../common/';
import Download from '../../api/Download';
import AnswerGuidance from '../AnswerGuidance/AnswerGuidance';
import { Picker } from './IndexPicker';
import ResponseMeta from '../ResponseMeta/ResponseMeta';
import { DefaultButtons, ConfirmationButtons } from './FormButtons';
// Managers
import { QuestionProvider, QuestionsContext } from './question-manager';
// Utils:
import { paperInProgress } from '../utils';
import { TOGGLES, INITIAL_META } from './constants';

const useResponseManager = (props, questions) => {
  const dispatch = useDispatch();

  const [hideStatus, setHideStatus] = useState(false);
  const meta = useForm(INITIAL_META);
  const toggle = useForm(TOGGLES);
  const [id, setId] = useState(null);

  const { admin, email } = useSelector((store) => store.session.current);

  const {
    submittedLate,
    paperStatus,
    userEcn,
  } = get(props, 'location.state', {});
  const { userId: uid } = get(props, 'match.params', {});
  const userId = uid ? Number(uid) : get(props, 'location.state.userId');

  const status = useSelector((store) => store.responses.responseStatus);

  const currentResponse = useSelector((store) => store.responses.current);
  const response = get(props, 'location.state.response', get(currentResponse, 'data'));

  useEffect(() => {
    if (userId && admin) Api({ request: CURRENT_RESPONSE, id: `?user_id=${userId}` });
  }, [userId, admin]);

  useEffect(() => {
    // Refetch the currentResponse unless one is passed in - use the response in props
    // when we know the CRQ has been submitted i.e. beyond the create stage. This is to
    // ensure when users refresh the form they always see up to date data.

    if (get(response, 'id')) {
      meta.updateForm(response.data.meta);
      toggle.updateForm({ submitted: response.status !== 'initial' });
      setId(get(response, 'id'));
      questions.dispatch({
        type: 'reinitialize',
        data: response.data.questions,
      });
    }

    if (get(props, 'location.state.response')) {
      // Form is definitely ready since we're using one that's been passed via props:
      toggle.updateForm({ responseLoading: false });
    } else if (currentResponse.loading === false) {
      // Haven't been passed a response, wait until finished loading before ready:
      toggle.updateForm({ responseLoading: false });
    }
  }, [currentResponse]);

  useEffect(() => {
    return () => {
      dispatch({ type: CURRENT_RESPONSE_RESET_STORE });
      dispatch({ type: RESPONSES_CREATE_RESET_STORE });
      dispatch({ type: RESPONSES_ROLLBACK_STATUS_RESET_STORE });
    };
  }, []);

  const loadId = () => {
    if (status.created) {
      setId(get(status.data, 'id'));
    }
  };

  const checkErrors = () => {
    toggle.updateForm({ submittedLate: false });
    if (get(status.errors, 'length')) {
      toggle.updateForm({ submitted: false });
    }
    if (invoke(status.errorFields, 'includes', 'meta')) {
      toggle.updateForm({ meta: false });
    }
  };

  useEffect(loadId, [status.created, status.data, id]);
  useEffect(checkErrors, [status.errors, status.errorFields, status.errorData]);

  const [lastSave, setLastSave] = useState()

  const save = (auto) => {
    const data = {
      meta: meta.form,
      questions: questions.data,
      submitted: toggle.form.submitted,
      userId
    }
    const comparableData = JSON.stringify(data)
    if (auto && lastSave == comparableData) {
      return
    }
    setLastSave(comparableData)
    apiSubmit({ id, data });
  };
  const unlock = () => {
    dispatch({ type: RESPONSES_CREATE_RESET_STORE });
    toggle.updateForm({ submitted: false, submittedLate: false });
    const pathEnd = `${id}/rollback_status`;
    Api({ request: RESPONSES_ROLLBACK_STATUS, id: pathEnd });
  };

  const finalise = () => {
    toggle.updateForm({ confirmSubmit: false, submitted: true, submittedLate }); // this is async
    setHideStatus(false);
    questions.dispatch({
      type: 'confirmAll',
    });
    apiSubmit({ id, data: { meta: meta.form, questions: questions.data, submitted: true, userId } });
  };

  const resetMeta = () => {
    meta.updateForm(INITIAL_META);
    questions.dispatch({
      type: 'reset',
    });
    toggle.updateForm({ meta: false, reset: false });
  };

  const toggleMeta = () => toggle.updateForm({ meta: !toggle.form.meta });

  const invertHideStatus = () => {
    setHideStatus(!hideStatus);
  };

  const actions = { save, finalise, invertHideStatus, resetMeta, toggleMeta }; // finalise is labeled 'submit' :/

  return {
    meta,
    toggle,
    questions,
    hideStatus,
    actions,
    copiedFrom: get(response, 'copiedFrom', ''),
    unlock,
    status,
    id,
    paperStatus,
    userEcn,
    admin,
    isYozu: String(email).match(/@yozu.co.uk$/)
  };
};

const apiSubmit = ({ id, data }) => {
  if (id) {
    Api({ request: RESPONSES_UPDATE, id, data });
  } else {
    Api({ request: RESPONSES_CREATE, data });
  }
};

const ResponseStatus = ({ submitted, hideStatus, invertHideStatus }) => {
  const { errors, loading, created, updated, unlocked } = useSelector(
    r => r.responses.responseStatus
  )

  const successMessage = () => {
    if (unlocked) return 'CRQ has been unlocked.';

    return `CRQ/AGS have been ${submitted ? 'submitted' : 'saved'}.`;
  };

  return (
    <ClosableStatus isError={errors} hideStatus={hideStatus} invertHideStatus={invertHideStatus}>
      <StatusHandler loading={loading} errors={errors} action={created || updated} onSuccess={successMessage()} />
    </ClosableStatus>
  );
};
ResponseStatus.propTypes = {
  submitted: propTypes.bool,
};

const ResponseCreateWrapper = (props) => {
  return (
    <QuestionProvider>
      <ResponseCreateBody {...props}/>
    </QuestionProvider>
  )
}

function ResponseCreateBody(props) {

  const questions = useContext(QuestionsContext)

  const {
    meta,
    toggle,
    hideStatus,
    actions,
    copiedFrom,
    unlock,
    status,
    id,
    paperStatus,
    userEcn,
    admin,
    isYozu,
  } = useResponseManager(props, questions);

  const { openModal, closeModal, modalIsOpen } = useModal();

  const autosave = () => {
    if (!toggle.form.responseLoading && !toggle.form.submitted && !admin) {
      const interval = setInterval(() => {
        actions.save(true)
      }, 30000);
      return () => {
        clearInterval(interval);
      };
    }
  };

  useEffect(autosave, [toggle.form.submitted, toggle.form.responseLoading, meta, questions]);

  useEffect(() => {
    if (toggle.form.submittedLate && !modalIsOpen) {
      openModal();
    }
    if (status.errors && modalIsOpen) {
      closeModal();
    }
  }, [status.errors, toggle.form.submitted]);

  const Buttons = () => {
    if (toggle.form.reset)
      return (
        <ConfirmationButtons
          label="reset"
          cancel={() => toggle.updateForm({ reset: false })}
          confirm={actions.resetMeta}
          disabled={toggle.form.submitted}
        />
      );
    if (toggle.form.confirmSubmit)
      return (
        <ConfirmationButtons
          label="submit"
          cancel={() => toggle.updateForm({ confirmSubmit: false })}
          confirm={actions.finalise}
          disabled={toggle.form.submitted}
        />
      );
    return (
      <DefaultButtons
        save={actions.save}
        reset={() => toggle.updateForm({ reset: true })}
        confirmSubmit={() => toggle.updateForm({ confirmSubmit: true })}
        disabled={toggle.form.submitted || toggle.form.responseLoading}
        admin={admin || false}
      />
    );
  };

  const spork = () => {
    meta.updateForm({ topic: 'Save', syllabus: ['fingers'], aim: 'repetive' })
    toggle.updateForm({ confirmSubmit: true })
    questions.dispatch({ type: 'spork' })
  }

  return (
    <Pane theme="response create">
      <Conditional if={!admin}>
        <ActionModal
          openModal={openModal}
          closeModal={closeModal}
          modalIsOpen={modalIsOpen}
          customStyle={LateSubmissionStyle}
          model="LATE_SUBMISSION" />
      </Conditional>
      <ResponseStatus
        hideStatus={hideStatus}
        invertHideStatus={actions.invertHideStatus}
        submitted={toggle.form.submitted}
      />
      <Conditional if={!toggle.form.submitted && !questions.isQuestionActive}>
        <ButtonRow theme="right controls">
          <Buttons />
          <Conditional if={isYozu}>
            <button onClick={spork}>Spork!</button>
          </Conditional>
        </ButtonRow>
      </Conditional>
      <Conditional if={toggle.form.submitted && admin && paperInProgress(paperStatus)}>
        <ButtonRow theme="right controls">
          <CopiedFrom copiedFrom={copiedFrom} />
          <SimpleButton text="Unlock" onClick={unlock} />
        </ButtonRow>
      </Conditional>
      <TabGroup labels={['CRQ', 'AGS']} disabled={questions.isQuestionActive}>
        <TabContent className="custom-response">
          <h2>Constructed Response Question</h2>
          <div className="field createdby">Created By: ECN-{userEcn}</div>
          <ResponseMeta
            {...meta}
            confirmed={toggle.form.meta}
            finalised={toggle.form.submitted}
            onClick={actions.toggleMeta}
          />
          <QuestionIndex
            submitted={toggle.form.submitted}
            toggle={toggle}
          />
          <Picker questions={questions} toggle={toggle} />
        </TabContent>
        <TabContent className="custom-response">
          <AnswerGuidance submitted={toggle.form.submitted}>
            <ResponseMeta {...meta} finalised />
          </AnswerGuidance>
        </TabContent>
      </TabGroup>
      <ButtonRow theme="right controls">
        <Conditional if={toggle.form.submitted}>
          <Download type="crq" id={id}>
            <SimpleButton text="Export as PDF" />
          </Download>
        </Conditional>
        <Conditional if={!toggle.form.submitted && !questions.isQuestionActive}>
          <Buttons />
        </Conditional>
      </ButtonRow>
    </Pane>
  );
}

const CopiedFrom = ({ copiedFrom }) => {
  if (copiedFrom) {
    return <div>Substituted from {copiedFrom}</div>;
  }
  return null;
};

ResponseCreateBody.propTypes = {
  response: propTypes.shape({
    loading: propTypes.bool,
    errors: propTypes.array,
    created: propTypes.bool,
  }),
  item: propTypes.shape({
    name: propTypes.string,
  }),
  idx: propTypes.number,
};

export default ResponseCreateWrapper;

import { useEffect } from 'react';
import { CURRICULUM_CODES, CURRICULUM_CODES_CREATE } from '../../root/action-types';
import { useSelector } from 'react-redux';
import Api from '../../api/call';

export const useCurriculumCodes = () => {
  const { data, loading } = useSelector((r) => r.curriculumCodes.index);
  useEffect(() => Api({ request: CURRICULUM_CODES }), []);

  const create = (code) => {
    Api({ request: CURRICULUM_CODES_CREATE, data: { code } });
  };

  return {
    options: data.map((d) => `${d.code} [${d.tag}]`),
    create,
    loading
  }
};

export default useCurriculumCodes;

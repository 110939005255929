import { useEffect } from 'react';
import { ROUNDS_SHOW } from '../../root/action-types';
import { useSelector } from 'react-redux';
import Api from '../../api/call';

const DEFAULT_PARAMS = { include_responses: true, include_status: false };

export const useCurrentRound = (params = DEFAULT_PARAMS) => {
  const { loading, ...data } = useSelector((store) => store.rounds.show);

  useEffect(() => {
    Api({ request: ROUNDS_SHOW, id: 'current', params: params });
  }, []);

  // Refetch the round if a paper is created or destroyed
  const papersStatus = useSelector((store) => store.papers.response);

  useEffect(() => {
    if (papersStatus.created || papersStatus.destroyed) {
      Api({ request: ROUNDS_SHOW, id: 'current', params: params });
    }
  }, [papersStatus]);

  return { round: data, loading };
};

import React from 'react';
import Layout from '../layout/Layout';
import Routes from './routes';

const App = () => (
  <Layout>
    <Routes />
  </Layout>
);

export default App;

import { useReducer } from 'react';
import { get } from 'lodash';

const init = (groupUsers, allUsers) => {
  let accordionRows = [];
  const maxPos = parseInt(get(groupUsers.slice(-1)[0], 'number', 0)); // backend returns in sorted order
  for (let posCount = 1; posCount <= maxPos; ++posCount) {
    let u = groupUsers.find((u) => u.number == posCount);
    if (u) {
      const label = [u.firstName, u.lastName].join(' ');
      accordionRows.push({ position: u.number, user: u, label });
    } else {
      accordionRows.push({ position: posCount, user: null, label: 'Click to add' });
    }
  }
  accordionRows.push({
    position: 'last',
    label: 'Click to add',
    user: null,
  });

  const availableUsers = allUsers.filter((u) => groupUsers.every((uig) => uig.id !== u.id));
  return { accordionRows, open: null, availableUsers };
};

const accordionReducer = (state, action) => {
  const searchString = (u) => [u.firstName, u.lastName, 'ECN', u.eClubNumber].join(' ').toLowerCase();

  switch (action.type) {
    case 'search': {
      const search = action.value.toLowerCase();
      const users = [...action.allUsers]
        .filter((u) => state.accordionRows.every((uig) => get(uig, 'user.id') !== u.id))
        .filter((u) => {
          if (search === '') return u;
          if (searchString(u).includes(search)) return u;
          return null;
        });
      return { ...state, availableUsers: users };
    }
    case 'add': {
      const accordionRows = [...state.accordionRows];
      let availableUsers = [...state.availableUsers];
      const label = [action.user.firstName, action.user.lastName].join(' ');
      const i = accordionRows.findIndex((row) => row.position === action.position);

      if (accordionRows[i].user !== null) {
        availableUsers.push(accordionRows[i].user);
      }
      availableUsers = availableUsers.filter((au) => au !== action.user);
      availableUsers.sort((a, b) => a.id > b.id);
      accordionRows[i] = { position: action.position, user: action.user, label };

      return { ...state, accordionRows, availableUsers, open: null };
    }
    case 'addLast': {
      const accordionRows = [...state.accordionRows];
      const label = [action.user.firstName, action.user.lastName].join(' ');
      const i = accordionRows.findIndex((row) => row.position === action.position);
      accordionRows[i + 1] = accordionRows.pop();
      accordionRows[i] = {
        position: String(accordionRows.length - 1),
        label,
        user: action.user,
      };

      let availableUsers = [...state.availableUsers];
      availableUsers = availableUsers.filter((au) => au !== action.user);

      return { ...state, accordionRows, availableUsers, open: null };
    }
    case 'remove': {
      const accordionRows = [...state.accordionRows];
      let availableUsers = [...state.availableUsers];
      const i = accordionRows.findIndex((row) => row.position === action.position);

      if (accordionRows[i].user !== null) {
        availableUsers.push(accordionRows[i].user);
        availableUsers.sort((a, b) => a.id > b.id);
      }
      accordionRows[i] = { ...accordionRows[i], user: null, label: 'Click to add' };

      return { ...state, accordionRows, availableUsers, open: null };
    }
    case 'removeLast': {
      const accordionRows = [...state.accordionRows];
      let availableUsers = [...state.availableUsers];
      let i = accordionRows.findIndex((row) => row.position === action.position);

      availableUsers.push(accordionRows[i].user);
      availableUsers.sort((a, b) => a.id > b.id);
      accordionRows[i] = accordionRows.pop();

      // Delete all trailing accordionRows with no user in them
      while (i - 1 >= 0 && accordionRows[i - 1].user === null) {
        accordionRows[i - 1] = accordionRows.pop();
        --i;
      }

      return { ...state, accordionRows, availableUsers, open: null };
    }
    case 'toggleOpen': {
      const close = state.open === action.position;
      const open = close ? null : action.position;
      // This could be refactored, but it resets the
      // search whenever accordion is opened or closed:
      const availableUsers = action.allUsers.filter((u) =>
        state.accordionRows.every((uig) => get(uig, 'user.id') !== u.id)
      );
      return { ...state, open, availableUsers };
    }
    case 'reset':
      return init(action.groupUsers, action.allUsers);
    default:
      throw new Error();
  }
};

const BLANK_ACCORDION = {
  accordionRows: [],
  open: null,
  availableUsers: [],
};

export const useAccordion = () => {
  const [accordionState, dispatch] = useReducer(accordionReducer, BLANK_ACCORDION);

  return { accordionState, dispatch };
};

export default useAccordion;

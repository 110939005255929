import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Api from '../../api/call';
import { USERS, USERS_CREATE, USERS_CREATE_RESET_STORE } from '../../root/action-types';
import './UsersNew.scss';
import propTypes from 'prop-types';
// Components:
import Status from '../../common/Form/Status';
import StatusHandler from '../../common/StatusHandler/StatusHandler';
import InputField from '../../common/InputField/InputField';
import InputCheckBox from '../../common/InputCheckBox/InputCheckBox';
import SimpleButton from '../../common/SimpleButton/SimpleButton';
import ButtonRow from '../../common/ButtonRow/ButtonRow';

class UsersNew extends Component {
  constructor(props) {
    super(props);
    this.state = { firstName: '', lastName: '', email: '', collegeReferenceNumber: '', eClubNumber: '', admin: false };
  }

  componentWillUnmount() {
    this.props.dispatch({ type: USERS_CREATE_RESET_STORE });
    Api({ request: USERS });
  }

  setField = (e) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value });
  };

  setAdmin = (e) => {
    const { name, checked } = e.target;
    this.setState(() => ({ [name]: checked }));
  };

  sendCreate = () => {
    Api({ request: USERS_CREATE, data: this.state });
  };

  renderStatusMessage = () => {
    const { loading, errors, created } = this.props.user;

    if (!loading && created) {
      this.props.closeModal();
      return null;
    }

    return (
      <Status isError={!!get(this.props.user, 'errors')}>
        <StatusHandler loading={loading} errors={errors} action={created} />
      </Status>
    );
  };

  render() {
    return (
      <div className="new-user-section">
        <this.renderStatusMessage />
        <InputField
          label="First Name"
          name="firstName"
          theme="thin"
          value={this.state.firstName}
          onChange={this.setField}
          type="text"
          placeholder="Users' First Name"
        />
        <InputField
          label="Last Name"
          name="lastName"
          theme="thin"
          value={this.state.lastName}
          onChange={this.setField}
          type="text"
          placeholder="Users' Last Name"
        />
        <InputField
          label="College Ref No."
          name="collegeReferenceNumber"
          theme="thin"
          value={this.state.collegeReferenceNumber}
          onChange={this.setField}
          type="text"
          placeholder="College Ref No."
        />
        <InputField
          label="Email Address"
          name="email"
          type="email"
          theme="thin"
          onChange={this.setField}
          value={this.state.email}
          placeholder="Enter the users' email adress"
        />
        <InputField
          label="ECN"
          name="eClubNumber"
          theme="thin"
          value={this.state.eClubNumber}
          onChange={this.setField}
          type="text"
          placeholder="ECN"
        />
        <InputCheckBox label="Admin" name="admin" theme="thin" value={this.state.admin} onChange={this.setAdmin} />
        <ButtonRow theme="right pad">
          <SimpleButton theme="muted" text="CANCEL" onClick={this.props.closeModal} />
          <SimpleButton theme="primary-button" text="ADD" onClick={this.sendCreate} />
        </ButtonRow>
      </div>
    );
  }
}

const mapStateToProps = ({ users: { user } }) => ({
  user: user || {},
});

export default connect(mapStateToProps)(UsersNew);

UsersNew.propTypes = {
  user: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
  closeModal: propTypes.func,
};

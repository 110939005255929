import React from 'react';
import propTypes from 'prop-types';

// Used to contain the contents of a single tab. Not required, but Looks Nice™.
function TabContent(props) {
  return (
    <div className="panel-body">
      <div className="tab-content">{props.children}</div>
    </div>
  );
}

export default TabContent;

TabContent.propTypes = {
  children: propTypes.node,
};

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import propTypes from 'prop-types';

import Api from '../api/call';
import { NOTIFICATIONS } from '../root/action-types';
import { Loading } from '../common/';

import './Notifications.scss';

const Entry = ({ createdAt, message, id }) => (
  <div className="entry" key={id}>
    <span>{moment(createdAt).format('HH:mm')}</span>
    <span>{message}</span>
  </div>
);
Entry.propTypes = {
  createdAt: propTypes.string,
  message: propTypes.string,
  id: propTypes.string,
};

const Group = ({ ago, entries }) => (
  <div className="day">
    <h4>{ago}</h4>
    {entries.map(Entry)}
  </div>
);
Group.propTypes = {
  ago: propTypes.string,
  entries: propTypes.array,
};

const useNotifications = () => {
  const { data, loading } = useSelector((r) => r.notifications.index);
  useEffect(() => Api({ request: NOTIFICATIONS }), []);

  const notifications = {};
  data.forEach((n) => {
    const time = moment(n.createdAt);
    const group = time.calendar().split(' ')[0];
    notifications[group] = notifications[group] || [];
    notifications[group].push(n);
  });
  return { notifications, loading };
};

const Content = () => {
  const { notifications, loading } = useNotifications();

  if (loading) {
    return <Loading />;
  }

  return Object.keys(notifications).map((ago) => <Group key={ago} ago={ago} entries={notifications[ago]} />);
};

const Container = () => (
  <div className="notifications-container">
    <div className="notifications-sub-title">Notifications</div>
    <div className="notifications-section">
      <Content />
    </div>
  </div>
);

export const test = { Content };

export default Container;

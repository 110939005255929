import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateTimePicker.scss';
import propTypes from 'prop-types';

export const DateTimePicker = ({ onChange, selected, ...rest }) => {
  return (
    <DatePicker selected={selected} onChange={onChange} showPopperArrow={false} dateFormat="dd/MM/yyyy" {...rest} />
  );
};

export default DateTimePicker;

DateTimePicker.propTypes = {
  onChange: propTypes.func,
  selected: propTypes.instanceOf(Date),
};

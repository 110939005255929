import React, { useState } from 'react';
import './roundAccordion.scss';
import { Link } from 'react-router-dom';
import { Conditional } from '../../common';
import './roundAccordion.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

const getTo = (response) => {
  const pathname = `/crq/empty/${response.responseId}`;
  return {
    pathname,
    state: {
      ecn: response.ecn,
    },
  };
};

const ResponseLine = ({ response, index }) => {
  const to = getTo(response);
  return (
    <div className="roundSegment" key={index}>
      <div className="roundTitle">{response.ecn} - {response.firstName} {response.lastName} - {response.articleTitle}</div>
      <Link className="roundButton" to={to}>
        View
      </Link>
    </div>
  );
};

export const SearchBarWithList = ({ isVisible, responses }) => {
  const [search, setSearch] = useState('');
  if (!isVisible || !responses) return null;

  const searchBar = (
    <input
      placeholder="search..."
      value={search}
      onChange={(e) => {
        setSearch(e.target.value);
      }}
    />
  );

  const filteredResponses = responses.map((response, i) => {
    if (!search) return <ResponseLine response={response} key={i} />;
    const ecn = response.ecn || '';
    const name = response.firstName.concat(response.lastName)
    const email = response.email
    const search_by = ecn.concat(name, email).toLowerCase()
    if (search_by.includes(search.toLowerCase())) {
      return <ResponseLine response={response} key={i} />;
    }
    return null;
  });

  return (
    <div>
      {searchBar}
      {filteredResponses}
    </div>
  );
};

const PaperSelector = ({ papers, activePaper, setActivePaper }) => {
  return (
    <div className="paperSelector">
      {papers.map((paper, index) => (
        <div
          className={`paperSelector-paper ${index === activePaper ? 'selected' : 'paperRadio'}`}
          onClick={(event) => {
            event.stopPropagation();
            setActivePaper(index);
          }}
          key={index}
        >
          {paper.title}
        </div>
      ))}
    </div>
  );
};

export const RoundSelectorWithPapers = ({
  month,
  year,
  isVisible,
  invertVisible,
  papers,
  activePaper,
  setActivePaper,
}) => {
  const monthText = new Date(new Date().setMonth(month)).toLocaleString('default', { month: 'long' });

  return (
    <div onClick={invertVisible} className={`accordionHeader`}>
      <h2>{`${monthText}, ${year}`} Round</h2>
      <Conditional if={isVisible}>
        <PaperSelector papers={papers} activePaper={activePaper} setActivePaper={setActivePaper} />
      </Conditional>

      <FontAwesomeIcon icon={isVisible ? faAngleUp : faAngleDown } color="black" />
    </div>
  );
};

import React from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import './roundAccordion.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

const getTo = (response) => {
  const pathname = `/crq/marked/${response.responseId}`;
  return {
    pathname,
    state: {},
  };
};

const ResponseLine = ({ response, index, title }) => {
  const to = getTo(response);
  return (
    <div className="roundSegment" key={index}>
      <div className="roundTitle">{`${title} - Q${response.articleNumber} - ${response.articleTitle}`}</div>
      <Link className="roundButton" to={to}>
        View
      </Link>
    </div>
  );
};

export const ResponseList = ({ isVisible, papers }) => {
  if (!isVisible || !papers) return null;

  const lines = papers
    .sort((a, b) => {
      return b.id - a.id;
    })
    .map((paper) => {
      const responses = get(paper, `responses`, []);
      return responses.map((response, i) => {
        return <ResponseLine title={paper.title} response={response} key={i} />;
      });
    });

  return <div>{lines}</div>;
};

export const RoundSelectorWithoutPapers = ({ month, year, isVisible, invertVisible }) => {
  const monthText = new Date(new Date().setMonth(month)).toLocaleString('default', { month: 'long' });

  return (
    <div className={`accordionHeader`} onClick={invertVisible}>
      <h2>{`${monthText}, ${year}`} Round</h2>
      <FontAwesomeIcon icon={isVisible ? faAngleDown : faAngleUp} color="black" />
    </div>
  );
};

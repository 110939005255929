import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import propTypes from 'prop-types';

import { TabContent, useUsers, usePapers, Conditional } from '../../common';
import { TopicBar, AdminTopicBar } from './ResponseDashboard';
import Api from '../../api/call';
import { PAPERS_UPDATE } from '../../root/action-types';

function ResponseMarkTab(props) {
  const { setPapers, paperForStatus } = usePapers();
  const { users } = useUsers();
  const markResponse = useSelector((store) => store.responses.markResponse);
  const responses = useSelector((store) => store.responses.forGroup);
  const currentPaper = props.admin ? props.currentPaper : paperForStatus('mark_crq');
  const submittedLate = new Date() > new Date(get(currentPaper, 'markDeadline'));
  const [releaseTo, setReleaseTo] = useState({});
  const [update, setUpdate] = useState(false);
  const groupUsers = get(props, 'users', []);

  const handleChange = (userId) => {
    setUpdate(true);
    setReleaseTo((prev) => ({ ...prev, [userId]: !prev[userId] }));
  };

  const releasingTo = () => {
    const data = users.reduce((obj, u) => {
      obj[u.id] = !get(currentPaper, 'withheldFrom', []).includes(u.id);
      return obj;
    }, {});
    setReleaseTo(data);
  };

  useEffect(releasingTo, [currentPaper, users]);

  const onUpdate = () => {
    if (props.admin && update) {
      setUpdate(false);
      const data = Object.keys(releaseTo).reduce((arr, u) => {
        if (!releaseTo[u]) arr.push(parseInt(u));
        return arr;
      }, []);
      const withheldFrom = JSON.stringify(data);
      Api({
        request: PAPERS_UPDATE,
        id: get(currentPaper, 'id'),
        data: { withheldFrom },
      });
    }
  };

  useEffect(onUpdate, [releaseTo, update]);

  const getUnmarkedUsers = () => {
    let unmarked = [];
    groupUsers.forEach((u, i) => {
      const response = markResponse.data[0];
      const responseData = response.data;
      const ecn = get(props, 'currentUser.eClubNumber', '----');
      const userMarkedAlready = responseData.markedFor.includes(u.id);

      if (!userMarkedAlready) {
        unmarked.push({
          pathname: '/crq/mark',
          state: {
            responseId: get(response, 'id'),
            uid: u.id,
            ecn,
            submittedLate,
          },
        });
      }
    });
    return unmarked;
  };

  const getUserData = (u, unmarked) => {
    const response = markResponse.data[0];
    const responseData = response.data;
    const theirECN = get(u, 'eClubNumber', '----');
    const ecn = get(props, 'currentUser.eClubNumber', '----');
    const fakeAnswered = get(responseData, `questions[0].userAnswers.${u.id}.fakeAnswered`, false)

    return {
      to: {
        pathname: '/crq/mark',
        state: {
          responseId: get(response, 'id'),
          uid: u.id,
          ecn,
          unmarked: unmarked,
          submittedLate: submittedLate,
        },
      },
      title: markResponse.data[1],
      topicClass: 'userCRQ',
      btnClass: responseData.markedFor.includes(u.id) ? 'stage-completed-CRQ' : 'userCRQ',
      btnText: responseData.markedFor.includes(u.id) ? 'Complete' : 'Mark',
      nextToBtnText: fakeAnswered ? "Not answered": "",
      prefix: `ECN - ${theirECN}`,
    };
  };

  const CreateUserTopics = () => {
    if (get(markResponse, ['data', 'details'])) {
      return get(markResponse, ['data', 'details']);
    }
    if (get(markResponse, ['data'])) {
      const topics = [];

      const unmarked = getUnmarkedUsers();
      groupUsers.forEach((u, i) => {
        const data = getUserData(u, unmarked);
        if (u.id !== props.currentUser.id) {
          topics.push(<TopicBar {...data} key={i} />);
        }
      });
      return topics;
    }
    return '';
  };

  const getAdminUnmarkedUsers = (user) => {
    const ecn = get(user, 'eClubNumber', '----');
    const unmarked = [];

    groupUsers.filter(u => u != user).forEach(otherUser => {
      const response =
        responses.count > 0
          ? responses.data.find((r) => r.articleId === get(props.articles, [user.number, 'id']))
          : (props.responses || []).find((r) => r.articleId === get(props.articles, [user.number, 'id']))

      const markedFor = response ? get(response, 'data').markedFor : [];
      const answeredBy = response ? get(response, 'data').answeredBy : [];

      const marked = markedFor.includes(otherUser.id)
      const answered = answeredBy.includes(otherUser.id)

      if (answered && !marked) {
        unmarked.push({
          pathname: '/crq/mark',
          state: {
            responseId: get(response, 'id'),
            uid: otherUser.id,
            submittedLate: submittedLate,
            paperStatus: currentPaper.status,
            ecn,
          },
        });
      }
    })
    return unmarked;
  };

  const getAdminData = (user, unmarked) => {
    const ecn = get(user, 'eClubNumber', '----');
    const questions = [];

    groupUsers.filter(u => u != user).forEach(otherUser => {
      const response =
        responses.count > 0
          ? responses.data.find((r) => r.articleId === get(props.articles, [user.number, 'id']))
          : (props.responses || []).find((r) => r.articleId === get(props.articles, [user.number, 'id']))

      const markedFor = response ? get(response, 'data').markedFor : [];
      const answeredBy = response ? get(response, 'data').answeredBy : [];

      const marked = markedFor.includes(otherUser.id);
      const answered = answeredBy.includes(otherUser.id);

      const data = get(response, 'data', '{}');

      const started = get(data, 'questions', []).some((q) => {
        if (get(data, 'finalComment', {})[otherUser.id]) return true;
        if (q.hasOwnProperty('userAnswers')) {
          const ua = q.userAnswers[otherUser.id];
          return (
            get(ua, 'comment') ||
            get(ua, 'awarded', []).some((a) => ![-1, null, undefined].includes(a.value) && a.value >= 0)
          );
        }
      });

      const topicClass = marked ? 'stage-completed-CRQ' : started ? 'stage-in-progress-CRQ' : 'stage-unstarted-CRQ';

      questions.push({
        to:
          marked || answered
            ? {
                pathname: '/crq/mark',
                state: {
                  responseId: get(response, 'id'),
                  uid: otherUser.id,
                  unmarked: unmarked,
                  submittedLate: submittedLate,
                  paperStatus: currentPaper.status,
                  ecn,
                },
              }
            : props.match.url,
        topicClass,
        btnClass: topicClass,
        btnText: marked || answered ? `User #${otherUser.number}` : '',
      });
    })

    return {
      title: user ? `${user.eClubNumber} - ${user.firstName} ${user.lastName}` : '',
      questions,
    };
  };

  const CreateAdminTopics = () => (
    groupUsers.map(user => {
      const unmarked = getAdminUnmarkedUsers(user)
      const data = getAdminData(user, unmarked)
      return (
        <AdminTopicBar {...data} key={user.id} prefix={user.number}>
          <Conditional if={user.id}>
            <input
              className="check-box release-papers-box"
              type="checkbox"
              value={releaseTo[user.id]}
              defaultChecked={releaseTo[user.id]}
              onChange={() => handleChange(user.id)}
            />
          </Conditional>
        </AdminTopicBar>
      )
    })
  )

  const checkVisibility = () => {
    const paperStatus = get(currentPaper, 'status', 'initial');
    return ['mark_crq', 'complete'].includes(paperStatus);
  };

  const content = () => {
    if (props.blocked) {
      return <h4>You do not have permission to view this tab.</h4>
    }
    if (!checkVisibility()) {
      return 'This stage has not been initiated.'
    }
    if (props.admin) {
      return <CreateAdminTopics />
    }
    return <CreateUserTopics />
  }

  return (
    <TabContent className="response-pane">
      <div className="mark-tab-headers">
        <h2>{get(currentPaper, 'title')}</h2>
        {checkVisibility() && props.admin ? <h6>Release papers?</h6> : null}
      </div>
      {content()}
    </TabContent>
  );
}

export default ResponseMarkTab;

ResponseMarkTab.propTypes = {
  currentUser: propTypes.shape({
    id: propTypes.number,
  }),
  users: propTypes.array,
  articles: propTypes.object,
  match: propTypes.shape({
    url: propTypes.string,
  }),
  admin: propTypes.bool,
  paperStatus: propTypes.object,
  currentPaper: propTypes.object,
  blocked: propTypes.bool,
};

import { combineReducers } from 'redux';
import { standardForCollections, standardModelCreateUpdateDestroy, standardShow } from '../api/standardReducers';
import { USERS, USERS_SHOW, USERS_SUBSTITUTE } from '../root/action-types';

// Explicit exports for testing standard-reducers.
export const user = standardModelCreateUpdateDestroy('user', USERS);
export const index = standardForCollections(USERS);
export const substitutable = standardForCollections(USERS_SUBSTITUTE);
export const show = standardShow(USERS_SHOW);

export default combineReducers({
  user,
  index,
  substitutable,
  show,
});

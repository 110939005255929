import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import './Timer.scss';
// Components:
import TimeOutModal from './TimeOutModal';
// Hooks
import { useModal } from '../../../common/';
import useTimer from './useTimer';

const Time = ({ time }) => {
  const pad = (time) => time.toString().padStart(2, '0');
  const minutes = pad(time.minutes);
  const seconds = pad(time.seconds);

  return (
    <>
      <div className="text">Time left</div>
      <div className="time">{`${minutes} : ${seconds}`}</div>
    </>
  );
};

export const Timer = ({ disabled, setTimeIsUp }) => {
  const { admin } = useSelector((store) => store.session.current);
  const { openModal, closeModal, modalIsOpen } = useModal();
  const { time, over } = useTimer(disabled);

  const timeUp = () => openModal();
  useEffect(timeUp, [over]);

  const Modal = () => <TimeOutModal closeModal={closeModal} modalIsOpen={modalIsOpen} submit={() => setTimeIsUp(true)} />;
  const Timer = () => (
    <div className={'timer ' + (admin ? 'admin' : '')}>
      <div className="contents">
        {over ? <div className="timeup">Time is up!</div> : <Time time={time} />}
        {over ? <Modal /> : null}
      </div>
    </div>
  );

  return disabled ? null : <Timer />;
};

export default Timer;

Timer.propTypes = {
  submit: propTypes.func,
  disabled: propTypes.bool,
};

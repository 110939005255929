import React from 'react';
import propTypes from 'prop-types';
import './TabGroup.scss';

// A container element for various tabs.

const TabLink = ({ disabled, active, onClick, children, ...props }) => (
  <a
    className={`tab ${active ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
    href={disabled ? null : "#top"}
    onClick={disabled ? null : onClick}
    {...props}
  >
    {children}
  </a>
)

class TabGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: 0 };
  }

  componentDidMount() {
    if (this.props.initial) {
      this.setState({ active: this.props.initial });
    }
  }

  handleClick = (event, index) => {
    event.preventDefault();
    this.setState({ active: index });
  };

  render() {
    const children = React.Children.toArray(this.props.children);

    const tabs = children.map((_, i) => (
      <TabLink
        key={i}
        active={i == this.state.active}
        disabled={this.props.disabled}
        onClick={(e) => this.handleClick(e, i)}
      >
        {(this.props.labels || [])[i] || i + 1}
      </TabLink>
    ));

    return (
      <div className="tab-group">
        <div className="tabs">{tabs}</div>
        <div className="body">{children[this.state.active]}</div>
      </div>
    );
  }
}

export default TabGroup;

TabGroup.propTypes = {
  labels: propTypes.array,
  initial: propTypes.number,
  children: propTypes.oneOfType([propTypes.node, propTypes.element]),
};

import React from 'react';
import './SimpleRow.scss';
import propTypes from 'prop-types';

// A simple "row" container.
function SimpleRow(props) {
  return <div className="simple-row">{props.children}</div>;
}

export default SimpleRow;

SimpleRow.propTypes = {
  children: propTypes.node.isRequired,
};

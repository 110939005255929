import React, { useState } from 'react';
import propTypes from 'prop-types';

// Components:
import ReactQuill from 'react-quill';
import { EditButtons, ConfirmButtons } from './QuestionButtons';
import { formats, toolbarModules, buildEvent } from '../../common/RichText/Constants';
import 'react-quill/dist/quill.snow.css';

function ScenarioQuestion({ dispatch, ...props }) {
  const [state, setState] = useState(props.item);

  const setField = (e) =>
    setState({
      ...state,
      [e.currentTarget.name]: e.currentTarget.value,
    });

  const ActionButtons = () => {
    if (props.hideActions) {
      return null;
    }
    return props.item.confirmed ? (
      <EditButtons idx={props.idx} submitted={props.submitted} />
    ) : (
      <ConfirmButtons submitted={props.submitted} idx={props.idx} question={state} dispatch={dispatch} />
    );
  };

  const buildClassName = () =>
    `scenario-container
      ${!props.item.confirmed ? 'active' : ''}
      ${props.submitted ? 'crq-submitted' : ''}
      ${props.error ? 'error' : ''}`;

  const onChangeEvent = (name, editor) => {
    const event = buildEvent(name, editor);
    setField(event);
  };
  return (
    <div className={buildClassName()}>
      {props.displayOnly ? (
        <div className="scenario-static">
          <ReactQuill theme="snow" modules={{ toolbar: false }} value={state.field} readOnly={true} />
        </div>
      ) : (
        <ReactQuill
          theme="snow"
          modules={toolbarModules(props.item.confirmed)}
          value={state.field}
          formats={formats}
          onChange={(content, delta, source, editor) => onChangeEvent('field', editor)}
          placeholder="Add a scenario"
          readOnly={props.item.confirmed}
        />
      )}
      <ActionButtons />
    </div>
  );
}

export default ScenarioQuestion;
export const MemoizedScenario = React.memo(ScenarioQuestion);

ScenarioQuestion.propTypes = {
  item: propTypes.object,
  idx: propTypes.number,
  actions: propTypes.object,
  hideActions: propTypes.bool,
  submitted: propTypes.bool,
  displayOnly: propTypes.bool,
  error: propTypes.bool,
};

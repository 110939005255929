import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CURRENT_MARK_RESPONSES } from '../../root/action-types';
import { get } from 'lodash';
import Api from '../../api/call';

export const useMarkResponse = () => {
  const markResponse = useSelector((s) => s.responses.markResponse);

  const getResponse = () => {
    Api({ request: CURRENT_MARK_RESPONSES });
  };
  useEffect(getResponse, []);

  function isActive() {
    return status === 'answered';
  }

  function isComplete() {
    return status === 'marked';
  }

  const load = get(markResponse, 'data');
  let status = '';

  if (load && !load.details) {
    status = markResponse.data[0].status;
  }

  const active = isActive();
  const complete = isComplete();

  return { active, complete };
};

export default useMarkResponse;

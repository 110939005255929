import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Api from '../../api/call';
import { GROUPS, GROUPS_CREATE, GROUPS_CREATE_RESET_STORE } from '../../root/action-types';
import propTypes from 'prop-types';
// Components:
import { Pane, Status, StatusHandler, InputField, SimpleButton, ButtonRow, useUsers } from '../../common';
import { UserAccordion, useAccordion } from '../UserAccordion/';
// Utils

export const GroupsNew = (props) => {
  const [title, setTitle] = useState('');
  const { users } = useUsers();
  const { loading, errors, created } = useSelector((r) => r.groups.group);

  const accordionReducer = useAccordion();

  useEffect(() => {
    accordionReducer.dispatch({ type: 'reset', groupUsers: [], allUsers: users });
  }, [users]);

  const dispatch = useDispatch();
  const afterSuccess = () => {
    if (!loading && created) {
      props.closeModal();
      dispatch({ type: GROUPS_CREATE_RESET_STORE });
      Api({ request: GROUPS });
    }
  };
  useEffect(afterSuccess, [created]);

  const createGroup = () => {
    const rows = accordionReducer.accordionState.accordionRows.filter((row) => row.user !== null);

    const users = rows.map((row) => ({ id: row.user.id, newNumber: row.position }));

    Api({
      request: GROUPS_CREATE,
      data: {
        title: title,
        users,
      },
    });
  };

  const RenderStatusMessage = () => (
    <Status isError={errors}>
      <StatusHandler loading={loading} errors={errors} action={created} onSuccess="Your details have been saved." />
    </Status>
  );

  return (
    <Pane theme="new-group">
      <RenderStatusMessage />
      <div className="new">
        <InputField
          label="Group Name"
          name="title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
          placeholder="Enter the group name"
          theme="thin"
        />
        <div>Add Users</div>
        <UserAccordion
          users={users}
          accordionState={accordionReducer.accordionState}
          dispatch={accordionReducer.dispatch}
        />
        <ButtonRow theme="right pad">
          <SimpleButton theme="muted" text="CANCEL" onClick={props.closeModal} />
          <SimpleButton theme="primary-button" text="ADD" onClick={createGroup} />
        </ButtonRow>
      </div>
    </Pane>
  );
};

export default GroupsNew;

GroupsNew.propTypes = {
  closeModal: propTypes.func,
};

import { combineReducers } from 'redux';

import { RESOURCES, RESOURCES_SHOW } from '../root/action-types';
import { standardForCollections, standardModelCreateUpdateDestroy, standardShow } from '../api/standardReducers';

export default combineReducers({
  index: standardForCollections(RESOURCES),
  show: standardShow(RESOURCES_SHOW),
  resource: standardModelCreateUpdateDestroy('resource', RESOURCES),
});

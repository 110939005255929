export function letter(count) {
  return String.fromCharCode(64 + count).toLowerCase();
}

export function paperInProgress(paperStatus) {
  if (paperStatus === 'initial') return true;
  if (paperStatus === 'create_crq') return true;

  return false;
}

export default letter;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RESPONSES, USERS } from '../../root/action-types';
import Api from '../../api/call';

export const useAllResponses = () => {
  const responses = useSelector((store) => store.responses.index.data);

  useEffect(() => {
    Api({ request: RESPONSES });
    Api({ request: USERS, params: { scope: 'current_round' } });
  }, []);

  return { responses };
};

export default useAllResponses;

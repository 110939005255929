import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SubstituteCRQTable from './SubstituteCRQTable';
import './SubstituteCRQ.scss';
import { NavLink } from 'react-router-dom';
import SimpleRow from '../../common/SimpleRow/SimpleRow';
import ReturnButton from '../../common/ReturnButton/ReturnButton.jsx';
import SimpleButton from '../../common/SimpleButton/SimpleButton';
import { get } from 'lodash';
import {
  RESPONSES_CREATE_RESET_STORE,
  RESPONSES_ROLLBACK_STATUS_RESET_STORE,
  CURRENT_RESPONSE_RESET_STORE,
  USERS_SHOW,
  RESPONSES_CREATE,
  RESPONSES_UPDATE,
  USERS_SUBSTITUTE,
} from '../../root/action-types.js';
import { useSelector, useDispatch } from 'react-redux';
import { Conditional } from '../../common';
import Api from '../../api/call';

const SubstitutePage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { response, articleTitle, paperStatus } = get(props, 'location.state', {});

  // substitutee is the user receiving the CRQ
  const substitutee = useSelector((store) => store.users.show.data);
  const to = substitutee ? `/groups/${substitutee.groupId}` : '/groups';
  const substituteeId = props.match.params.userId;
  const substituteeName = getSubstituteeName(substitutee);

  // availableUsers are the ones whose CRQs we can copy
  const availableUsers = useSelector((store) => store.users.substitutable);

  const responseStatus = useSelector((store) => store.responses.responseStatus);
  const successfullySubstituted = get(responseStatus, 'success', false);

  useEffect(() => {
    //get the substitutee
    Api({ request: USERS_SHOW, id: substituteeId });
    //get availableUsers
    Api({
      request: USERS_SUBSTITUTE,
      params: { scope: 'substitutable_scope', substituteeUserId: substituteeId, paperStatus: paperStatus },
    });
  }, []);

  useEffect(() => {
    // once server successfully saves the substituted CRQ it is safe to bring the user back to groups.
    if (successfullySubstituted) {
      history.push(to);
    }

    return () => {
      dispatch({ type: CURRENT_RESPONSE_RESET_STORE });
      dispatch({ type: RESPONSES_CREATE_RESET_STORE });
      dispatch({ type: RESPONSES_ROLLBACK_STATUS_RESET_STORE });
    };
  }, [successfullySubstituted]);

  return (
    <div className="background">
      <ReturnButton className="back-button" to={to} text="Back to CRQ/AGS" />
      <div className="visible-background">
        <h3>Substitute CRQ</h3>
        <SubstituteCRQ
          response={response}
          articleTitle={articleTitle}
          availableUsers={availableUsers}
          to={to}
          substituteeUserId={substituteeId}
          substituteeName={substituteeName}
        />
      </div>
    </div>
  );
};

const SubstituteCRQ = ({ substituteeUserId, to, response, availableUsers, articleTitle, substituteeName }) => {
  const [substituteCRQ, setSubstituteCRQ] = useState(false);
  const [substituteWith, setSubstituteWith] = useState({ eClubNumber: -1 });
  const availableLength = get(availableUsers, 'data.length', 0);

  const substitute = (user) => {
    const meta = user.response.data.meta;
    const questions = user.response.data.questions;
    const copiedFrom = user.groupTitle;
    questions.userAnswers = [];
    const responseId = get(response, 'id', 0);
    apiSubmit({
      id: responseId,
      data: { meta, copied_from: copiedFrom, questions, submitted: true, userId: substituteeUserId },
    });
    reverseSubstitute(substituteWith);
  };

  const apiSubmit = ({ id, data }) => {
    if (id) {
      Api({ request: RESPONSES_UPDATE, id, data });
    } else {
      Api({ request: RESPONSES_CREATE, data });
    }
  };

  const reverseSubstitute = (substituteWithNew) => {
    if (substituteWith.eClubNumber === substituteWithNew.eClubNumber) {
      setSubstituteCRQ(false);
      setSubstituteWith({ id: -1 });
    } else {
      setSubstituteCRQ(true);
      setSubstituteWith(substituteWithNew);
    }
  };

  const RenderOKButton = () => {
    if (substituteCRQ) {
      return <SimpleButton onClick={() => substitute(substituteWith)} theme="green" text="OK" />;
    }
    return <SimpleButton text="OK" />;
  };

  return (
    <div className="inner-background">
      <Conditional if={availableLength === 0}>
        <div className="no-substitution">
          <h4>There are no suitable candidates for substitution for {substituteeName}.</h4>
        </div>
      </Conditional>
      <Conditional if={availableLength !== 0}>
        <SimpleRow>
          <div className="name-row">
            <p className="number-line"># 1</p>
            <p className="name-line">{substituteeName}</p>
          </div>
        </SimpleRow>
        <SimpleRow className="">
          <div className="article-row">
            <h4>CRQ available for:</h4>
            <p>{articleTitle}</p>
          </div>
        </SimpleRow>
        <SubstituteCRQTable
          data={get(availableUsers, 'data', [])}
          substituteClick={reverseSubstitute}
          activated={substituteWith.eClubNumber}
        />
        <SimpleRow className="bottom-row">
          <li className="bottom-button-row">
            <RenderOKButton />
            <NavLink className="cancel" to={to}>
              Cancel
            </NavLink>
          </li>
        </SimpleRow>
      </Conditional>
    </div>
  );
};

const getSubstituteeName = (substitutee) => {
  if (substitutee) {
    return substitutee.firstName + ' ' + substitutee.lastName;
  }
  return 'this user';
};

export default SubstitutePage;

import moment from 'moment';
import 'moment/locale/en-gb';

moment.locale('en-gb');

// Format Time and Date
class MomentFormat {
  static longDate(time) {
    return moment(time).format('Do MMMM YYYY');
  }

  static shortDate(time) {
    return moment(time).format('MMMM YYYY');
  }

  static longDateTime(time) {
    return moment(time).format('Do MMMM YYYY h:mmA');
  }
}

export default MomentFormat;

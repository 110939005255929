import React from 'react';
import { modalDefaultStyle } from './ModalDefaultStyle';
import propTypes from 'prop-types';
import './LateSubmissionModal.scss';

export const LateSubmissionActions = ({ actions }) => {
  const { closeModal } = actions;

  return (
    <div className="submission-background">
      <p className="medium-header">You have submitted after the deadline. This has been marked as a late submission.</p>
      <p className="submission-description">Faculty may be in touch to issue a Penalty Point.</p>
      <div className="empty-divider"></div>
      <button className="close" onClick={closeModal}>
        Close
      </button>
    </div>
  );
};

export const LateSubmissionStyle = {
  ...modalDefaultStyle,
  content: {
    ...modalDefaultStyle.content,
    height: '350px',
    width: '425px',
  },
};

LateSubmissionActions.propTypes = {
  data: propTypes.object,
  actions: propTypes.object,
};

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import propTypes from 'prop-types';

import Api, { isAuthenticated } from '../../api/call';
import { SESSION_LOGIN, SESSION_LOGIN_RESET_STORE, PASSWORD_RESET, PASSWORD_CHANGE } from '../action-types';
// Components:
import { Field, useForm, Status, Loading, Conditional } from '../../common/';
// Images:
import Logo from '../../root/assets/colour-logo.svg';

import './LogIn.scss';

const LoginManager = () => {
  const { loading, reset, ...details } = useSelector((r) => r.session.details);
  const error = details.error && details.error.userAuthentication;
  const dispatch = useDispatch();
  const [mode, setMode] = useState('LOGIN');

  const forgotPassword = () => {
    dispatch({ type: SESSION_LOGIN_RESET_STORE });
    setMode('FORGOT');
  };
  const login = () => setMode('LOGIN');
  const emailReset = (data) => {
    Api({ request: PASSWORD_RESET, data });
    setMode('EMAILED');
  };

  if (isAuthenticated()) {
    return <Redirect to={'/'} />;
  }

  if (mode === 'FORGOT') {
    return <Forgot login={login} emailReset={emailReset} />;
  }
  if (mode === 'EMAILED') {
    return <Emailed login={login} loading={loading} reset={reset} error={error} />;
  }

  return <Login error={error} forgotPassword={forgotPassword} />;
};

const BLANK_LOGIN = { email: '', password: '' };

export const Login = ({ error, forgotPassword }) => {
  const { form, inputOnChange } = useForm(BLANK_LOGIN);

  const sessionLogin = () => {
    Api({ request: SESSION_LOGIN, data: form });
  };
  const onKeyPress = ({ key }) => {
    key === 'Enter' && form.password && sessionLogin();
  };

  return (
    <Wrapper error={error}>
      <div className="login fields">
        <EmailField error={error} onChange={inputOnChange} value={form.email} />
        <PasswordField error={error} onChange={inputOnChange} value={form.password} onKeyPress={onKeyPress} />
      </div>

      <button className="action-button" onClick={sessionLogin}>
        Login
      </button>
      <div className="change">
        <span onClick={forgotPassword}>Forgot your password?</span>
      </div>
    </Wrapper>
  );
};
Login.propTypes = {
  error: propTypes.string,
  forgotPassword: propTypes.func,
};

const Wrapper = ({ error, children }) => (
  <div className="login-container">
    <div className={`login-form-container ${error ? 'error' : ''}`}>
      <Status isError={!!error}>{error}</Status>
      <div className="login-image-container">
        <img className="login-image-logo" src={Logo} alt="MSA logo" />
      </div>
      {children}
    </div>
  </div>
);
Wrapper.propTypes = {
  error: propTypes.string,
  children: propTypes.node,
};

const EmailField = ({ error, ...props }) => (
  <Field className="block" error={error}>
    <input
      type="email"
      name="email"
      className="underline"
      placeholder="Email Address"
      spellCheck="false"
      autoComplete="off"
      {...props}
    />
  </Field>
);
EmailField.propTypes = {
  error: propTypes.string,
};

const PasswordField = ({ error, ...props }) => (
  <Field className="block" error={error}>
    <input type="password" name="password" className="underline" placeholder="Password" {...props} />
  </Field>
);
PasswordField.propTypes = {
  error: propTypes.string,
};

export const Forgot = ({ login, emailReset }) => {
  const { form, inputOnChange } = useForm({ email: '' });
  const [error, setError] = useState('');

  const email = () => {
    if (form.email.match(/@/)) {
      emailReset(form);
    } else {
      setError('Please enter a valid email address');
    }
  };

  return (
    <Wrapper error={error}>
      <div className="fields">
        <h4>Enter your email address to receive a password reset link</h4>
        <EmailField error={error} onChange={inputOnChange} value={form.email} />
      </div>
      <button className="action-button" onClick={email}>
        Send reset link
      </button>
      <div className="change">
        <span onClick={login}>Back to login</span>
      </div>
    </Wrapper>
  );
};
Forgot.propTypes = {
  login: propTypes.func,
  emailReset: propTypes.func,
};

export const Emailed = ({ login, loading, reset, error }) => (
  <Wrapper error={error}>
    <div className="fields emailed">
      <Conditional if={reset}>
        <div className="success">
          Reset instructions have been emailed to the address provided, please allow a few minutes for delivery.
        </div>
      </Conditional>
      <Conditional if={loading}>
        <Loading />
      </Conditional>
    </div>
    <div className="change">
      <span onClick={login}>Back to login</span>
    </div>
  </Wrapper>
);
Emailed.propTypes = {
  error: propTypes.string,
  login: propTypes.func,
  loading: propTypes.bool,
  reset: propTypes.bool,
};

const Success = ({ message }) => (
  <Wrapper>
    <div className="success">
      {message}&nbsp;
      <Link to="/login">click here to login.</Link>
    </div>
  </Wrapper>
);
Success.propTypes = {
  message: propTypes.string,
};

export const ChangePassword = ({ match: { params: verification } }) => (
  <SetPassword
    verification={verification}
    title="Enter a new password for your account"
    actionName="Reset Password"
    successMessage="Password successfully changed,"
  />
);
ChangePassword.propTypes = {
  match: propTypes.object, // from react router
  params: propTypes.object,
};

export const Welcome = ({ match: { params: verification } }) => (
  <SetPassword
    verification={verification}
    title="Create a password for your account"
    actionName="Create Account"
    successMessage="Account successfully created,"
  />
);
Welcome.propTypes = {
  match: propTypes.object, // from react router
  params: propTypes.object,
};

const SetPassword = ({ verification, title, actionName, successMessage }) => {
  const { loading, success, errors } = useSelector((r) => r.session.change);
  const error = errors && errors.join(', ');
  const { form, inputOnChange } = useForm({ password: '', password_confirmation: '' });

  const action = () => {
    Api({ request: PASSWORD_CHANGE, data: { ...form, ...verification } });
  };
  const onKeyPress = ({ key }) => {
    key === 'Enter' && action();
  };

  if (success) {
    return <Success message={successMessage} />;
  }

  return (
    <Wrapper error={error}>
      <div className="fields change-password">
        <h4>{title}</h4>
        <Conditional if={loading}>
          <Loading />
        </Conditional>
        <PasswordField
          error={error}
          onChange={inputOnChange}
          name="password"
          value={form.password}
          placeholder="Enter your password"
          disabled={loading}
        />
        <PasswordField
          error={error}
          onChange={inputOnChange}
          name="password_confirmation"
          value={form.password_confirmation}
          placeholder="Confirm password"
          disabled={loading}
          onKeyPress={onKeyPress}
        />
      </div>
      <button className="action-button" onClick={action}>
        {actionName}
      </button>
      <div className="change">
        <Link to="/login">Back to login</Link>
      </div>
    </Wrapper>
  );
};
SetPassword.propTypes = {
  verification: propTypes.object,
  title: propTypes.string,
  actionName: propTypes.string,
  successMessage: propTypes.string,
};

export default LoginManager;

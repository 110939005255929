export const options = [
  { label: '1 Mark', value: 1 },
  { label: '2 Marks', value: 2 },
  { label: '3 Marks', value: 3 },
  { label: '4 Marks', value: 4 },
  { label: '5 Marks', value: 5 },
  { label: '6 Marks', value: 6 },
  { label: '7 Marks', value: 7 },
  { label: '8 Marks', value: 8 },
  { label: '9 Marks', value: 9 },
  { label: '10 Marks', value: 10 },
];

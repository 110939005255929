import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CURRENT_ANSWER_RESPONSES } from '../../root/action-types';
import { useGroupUsers } from '../../common/';
import { get } from 'lodash';
import Api from '../../api/call';

export const useAnswerResponses = () => {
  const answerResponses = useSelector((s) => s.responses.answerResponses);
  const user = useSelector((s) => s.session.current);
  const { groupUsers } = useGroupUsers(user.groupId);

  const getResponse = () => {
    Api({ request: CURRENT_ANSWER_RESPONSES });
  };
  useEffect(getResponse, []);

  function answered(answered) {
    return answered === true;
  }

  function isActive() {
    return load && userCount === available;
  }

  function isComplete() {
    if (answeredBy.length < 1) return false;
    return answeredBy.every(answered);
  }

  const load = get(answerResponses, 'data');
  const userCount = get(groupUsers, 'length');

  let available = 0;
  let answeredBy = [];

  if (load) {
    const filtered = answerResponses.data.filter((r) => r.userId !== user.id);
    answeredBy = filtered && filtered.map((r) => r.data.answeredBy);
    answeredBy = answeredBy.map((r) => r.includes(user.id));
    available = answerResponses.count;
  }

  const active = isActive();
  const complete = isComplete();

  return { active, complete };
};

export default useAnswerResponses;

import React from 'react';
import propTypes from 'prop-types';

import { CRQ_DOWNLOAD } from '../root/action-types';
import Api from './call';

const Download = ({ children, ...params }) => (
  <span onClick={() => Api({ request: CRQ_DOWNLOAD, params })}>{children}</span>
);
Download.propTypes = {
  children: propTypes.node,
};

export default Download;

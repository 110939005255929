import { useState, useEffect } from 'react';

export const useTimer = (disabled) => {
  const minutes = 15;
  const seconds = 0;

  const [over, setOver] = useState(false);

  const [time, setTime] = useState({
    minutes: parseInt(minutes, 10),
    seconds: parseInt(seconds, 10),
  });

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const condition = {
    over: time.minutes === 0 && time.seconds === 0,
    minuteElapsed: time.seconds === 0,
  };

  const minuteTick = () => setTime({ minutes: time.minutes - 1, seconds: 59 });

  const secondTick = () => setTime({ minutes: time.minutes, seconds: time.seconds - 1 });

  const tick = () => {
    if (over || disabled) return;
    if (condition.over) {
      setOver(true);
    } else if (condition.minuteElapsed) {
      minuteTick();
    } else {
      secondTick();
    }
  };

  return { time, over };
};

export default useTimer;

export const TOGGLES = {
  meta: false,
  reset: false,
  confirmSubmit: false,
  submitted: false,
  submittedLate: false,
  picker: { 0: false },
  responseLoading: true,
};

export const INITIAL_META = {
  topic: '',
  syllabus: [],
  aim: '',
};

import React from 'react';
import ModelIndexTable from '../../common/IndexTable/ModelIndexTable';
import { USERS } from '../../root/action-types';

const COLUMNS = ['firstName', 'lastName', 'email'];
const DEFAULT_COLUMNS = COLUMNS.slice(0, 7);

const UsersIndex = () => (
  <ModelIndexTable
    model="users"
    dataMap={(row) => ({ ...row })}
    requestAction={USERS}
    COLUMNS={COLUMNS}
    defaultColumns={DEFAULT_COLUMNS}
  />
);

export default UsersIndex;

import React from 'react';
import propTypes from 'prop-types';
import Select from 'react-select';
import { Prefix } from '../QuestionTypes/Prefix'
import './MarkBox.scss';

const buildOptions = (maxValue) => {
  const options = [];
  for (let i = 0; i < maxValue; ++i) {
    options.push({ label: `${i} marks`, value: i });
  }
  return options;
};

const MarkBox = (props) => (
  <div className="mark-box">
    <div className="prefix-row">
      <Prefix idx={props.idx} />
      <Select
        classNamePrefix="select"
        className="mark-dropdown"
        onChange={(value) =>
          props.dispatch({
            type: 'setAwarded',
            index: props.idx,
            value,
          })
        }
        options={buildOptions(props.item.marks.value + 1)}
        value={props.item.awarded}
        isDisabled={props.disabled}
      />
    </div>
    <textarea
      name="comment"
      defaultValue={props.item.comment}
      onChange={(event) =>
        props.dispatch({
          type: 'update',
          index: props.idx,
          name: event.currentTarget.name,
          value: event.currentTarget.value,
        })
      }
      placeholder="Add comment"
      disabled={props.disabled}
    />
  </div>
);

export default MarkBox;

MarkBox.propTypes = {
  setQuestion: propTypes.func,
  setAwarded: propTypes.func,
  idx: propTypes.number,
  item: propTypes.object,
  disabled: propTypes.bool,
};

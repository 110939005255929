import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';

import { Conditional, useModal, ButtonRow, SimpleButton } from '../common';
import { PAPERS_DESTROY } from '../root/action-types';
import Api from '../api/call';
import AdminModal from '../dashboard/AdminDashboard/AdminModal';

function PaperPicker({ currentPaper, setCurrentPaper, papers, closeAccordion, modify }) {
  const [index, setIndex] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const { openModal, closeModal, modalIsOpen } = useModal();

  const getClassName = (i) => {
    let className = 'dashboard-paper';
    if (index === i) className += ' selected';
    return className;
  };

  const showDelete = (p) => {
    const notLast = papers.length > 1;
    return modify && notLast && p.status === 'initial';
  };

  useEffect(() => {
    if (papers && currentPaper && currentPaper.id) {
      const i = papers.findIndex((p) => p.id === currentPaper.id);
      setIndex(i);
    }
  }, [currentPaper, papers]);

  const onClick = (paper, index) => {
    setCurrentPaper(paper);
    setIndex(index);
    closeAccordion && closeAccordion();
  };

  const destroyPaper = (id) => {
    Api({ request: PAPERS_DESTROY, id });
    setDeleteId(null);
    closeModal();
  };

  const closePaperModal = () => {
    setDeleteId(null);
    closeModal();
  };

  useEffect(() => {
    if (deleteId) openModal();
  }, [deleteId, openModal]);

  const Modal = () => (
    <AdminModal closeModal={closePaperModal} modalIsOpen={modalIsOpen}>
      <h3>Are you sure you want to delete this paper?</h3>
      <ButtonRow>
        <SimpleButton onClick={() => destroyPaper(deleteId)} text="YES" />
        <SimpleButton onClick={closePaperModal} theme="muted" text="CANCEL" />
      </ButtonRow>
    </AdminModal>
  );

  return (
    <>
      {(papers || []).map((p, i) => (
        <div key={i} className={getClassName(i)}>
          <div className="dashboard-paper-inner" onClick={() => onClick(papers[i], i)}>
            {p.title}
          </div>
          <Conditional if={showDelete(p)}>
            <div className="dashboard-paper-delete" title="Delete" onClick={() => setDeleteId(papers[i].id)}></div>
          </Conditional>
        </div>
      ))}
      {Modal()}
    </>
  );
}
PaperPicker.propTypes = {
  currentPaper: propTypes.object,
  setCurrentPaper: propTypes.func,
  papers: propTypes.array,
  closeAccordion: propTypes.func,
  modify: propTypes.bool,
};

export default PaperPicker;

import React, { Component } from 'react';
import './Field.scss';
import propTypes from 'prop-types';

export class Field extends Component {
  state = { hasBeenTouched: false };

  touched = () => this.setState({ hasBeenTouched: true });

  displayError = () => {
    const { error, forceError } = this.props;
    return (forceError && error) || (error && this.state.hasBeenTouched);
  };

  buildFieldClassName = () => {
    return `field ${this.props.className || ''}` + ` ${this.displayError() ? 'field-error' : ''} `;
  };

  buildLabelClassName = () => {
    return `field-label ${this.props.className || ''}`;
  };

  render = () => (
    <div className={this.buildFieldClassName()} onBlur={this.touched}>
      <label className={this.buildLabelClassName()}>{this.props.label}</label>
      {this.props.children}
    </div>
  );
}

Field.propTypes = {
  error: propTypes.any,
  forceError: propTypes.bool,
  className: propTypes.string,
  label: propTypes.string,
  children: propTypes.element,
};

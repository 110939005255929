import React, { useState } from 'react';
import './Resources.scss';
// Components:
import Pane from '../common/Pane/Pane';
// Hook
import useResources from '../common/Hooks/useResources';
// Utilities:
import ResourceTable from './ResourceTable.jsx';

function Resources() {
  const [search, setSearch] = useState('');
  const { data } = useResources();

  const filterList = (data) => {
    return data.filter((item) => {
      if (item['.tag'] != 'file') {
        return null;
      } // ignore folders
      if (search === null) return item;
      if (item.name.toLowerCase().includes(search.toLowerCase())) return item;
      return null;
    }).sort((a, b) =>
      String(b.client_modified).localeCompare(String(a.client_modified))
    )
  };

  return (
    <Pane theme="background">
      <h2>RESOURCES</h2>
      <div className="inner-background">
        <input placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
        <ResourceTable data={filterList(data.files)} />
      </div>
    </Pane>
  );
}

export default Resources;

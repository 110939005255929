import React from 'react';
import propTypes from 'prop-types';
// Utilites:
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccordionSection = (props) => {
  const onClick = () => {
    props.onClick(props.label);
  };

  const openSection = () => (props.isOpen ? <div className="accordion-section">{props.children}</div> : null);

  const buildClassName = () => `accordion ${props.isOpen ? 'open' : 'closed'}`;

  const Icon = () => <FontAwesomeIcon icon={props.icon} />;

  const Index = () => <div className="index">{props.index}</div>;

  const Prefix = () => (props.icon ? <Icon /> : <Index />);

  return (
    <div className={buildClassName()} disabled={props.sectionDisabled}>
      <div className="label" onClick={onClick}>
        <Prefix />
        <div className="text">{props.label}</div>
        <div className="cancel">Cancel</div>
        <div className="clear" title="Clear" onClick={(e) => (e.stopPropagation(), props.cancel())}></div>
      </div>
      {openSection()}
    </div>
  );
};

export default AccordionSection;

AccordionSection.propTypes = {
  onClick: propTypes.func,
  label: propTypes.string,
  isOpen: propTypes.bool,
  children: propTypes.node,
  cancel: propTypes.func,
  icon: propTypes.object,
  index: propTypes.number,
  sectionDisabled: propTypes.bool,
};

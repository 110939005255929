import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
// Utils:
import { isAuthenticated } from '../api/call';

export function AdminRoute(props) {
  const { loading, admin } = useSelector((r) => r.session.current);

  if (loading === false && !admin) return <Redirect to="/" />;

  return <ProtectedRoute {...props} />;
}

function ProtectedRoute(props) {
  const { component: Component, ...rest } = props;

  return (
    <Route {...rest} render={(props) => (isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />)} />
  );
}

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  component: propTypes.oneOfType([propTypes.func, propTypes.object]),
};

AdminRoute.propTypes = ProtectedRoute.propTypes;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import Api from '../../api/call';
import { MISSED_DEADLINES, ADMIN_NOTIFICATIONS } from '../../root/action-types';
import { TabGroup, TabContent } from '../../common/';
import './MissedDeadlines.scss';

const MissedDeadlines = () => {
  const outstanding = useSelector((s) => s.rounds.missedDeadlines);
  const notifications = useSelector((s) => s.notifications.adminIndex);

  useEffect(() => Api({ request: MISSED_DEADLINES }), []);
  useEffect(() => Api({ request: ADMIN_NOTIFICATIONS }), []);

  const Outstanding = (item, index) => (
    <div className="missed-deadline" key={index}>
      <NavLink to={`/groups/${item.groupId}`}>
        <span>{item.groupTitle} </span>
        <span>has outstanding </span>
        <span>{item.type} actions </span>
        <span>for </span>
        <span>{item.title}.</span>
      </NavLink>
    </div>
  );

  const Notification = (item, index) => (
    <div className="notification" key={index}>
      <span>{moment(item.createdAt).format('HH:mm')}</span>
      <span>{item.message}.</span>
    </div>
  );

  const OutstandingIndex = () => get(outstanding, 'data', []).map((i, index) => Outstanding(i, index));

  const NotificationsIndex = () => get(notifications, 'data', []).map((i, index) => Notification(i, index));

  return (
    <div className="dashboard-deadlines">
      <div className="deadlines-sub-title">Missed Deadlines List</div>
      <TabGroup labels={['outstanding', 'missed log']}>
        <TabContent>
          <div className="deadline-index">
            <OutstandingIndex />
          </div>
        </TabContent>
        <TabContent>
          <div className="deadline-index">
            <NotificationsIndex />
          </div>
        </TabContent>
      </TabGroup>
    </div>
  );
};

export default MissedDeadlines;

MissedDeadlines.propTypes = {
  papers: propTypes.array,
  paperForStatus: propTypes.func,
};

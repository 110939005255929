import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import Api from '../../api/call';
import { GROUPS, GROUPS_DESTROY, GROUPS_DESTROY_RESET_STORE } from '../../root/action-types';
import { Link } from 'react-router-dom';
import { Conditional, Status, StatusHandler } from '../';

export const GroupActions = ({ data, actions }) => {
  const { confirm, setConfirm } = actions;
  const { destroyed, loading, errors } = useSelector((s) => s.groups.group);
  const dispatch = useDispatch();

  const onDismount = () => {
    return () => {
      dispatch({ type: GROUPS_DESTROY_RESET_STORE });
      Api({ request: GROUPS });
    };
  };

  useEffect(onDismount, []);

  const RenderStatus = () => {
    return (
      <Status isError={errors}>
        <StatusHandler loading={loading} errors={errors} action={destroyed} onSuccess="Group deleted" />
      </Status>
    );
  };

  const destroy = () => {
    Api({ request: GROUPS_DESTROY, id: data.id });
    setConfirm(false);
  };

  return (
    <div className="actions">
      <RenderStatus />
      <h1>{data.title}</h1>
      <div className="buttons">
        <Link to={`/groups/${data.id}/edit`}>Edit</Link>
        <Conditional if={!confirm}>
          <button className="delete" onClick={() => setConfirm(true)}>
            Delete
          </button>
        </Conditional>
        <Conditional if={confirm}>
          <div className="confirm">
            <div>Are you sure you want to delete?</div>
            <button className="delete" onClick={destroy}>
              DELETE
            </button>
            <button onClick={() => setConfirm(false)}>CANCEL</button>
          </div>
        </Conditional>
      </div>
    </div>
  );
};

GroupActions.propTypes = {
  data: propTypes.object,
  actions: propTypes.object,
};

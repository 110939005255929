import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { RESPONSES_MARKED, ARTICLES } from '../../root/action-types';
import Api from '../../api/call';

export const useMarkedResponses = () => {
  const { data } = useSelector((store) => store.responses.marked);
  const { papers } = useSelector((store) => store.rounds.show);
  const articles = useSelector((store) => store.articles.index.data);
  const userId = useSelector((store) => store.session.current.id);

  useEffect(() => {
    Api({ request: ARTICLES });
    Api({ request: RESPONSES_MARKED });
  }, []);

  const completePapers = papers && papers.filter((p) => p.status === 'complete');

  const ready = get(completePapers, 'length', 0) > 0 && articles.length > 0 && data;

  const releasedResponses = ready
    ? data
        .map((r) => {
          const a = articles.find((a) => a.id === r.response.articleId);
          const p = completePapers.find((cp) => cp.id === a.paperId);
          if (p === undefined) return null;

          const wh = JSON.parse(get(p, 'withheldFrom', '[]'));
          return wh.includes(userId) ? null : r;
        })
        .filter((r) => r !== null)
    : [];

  const markedResponses = data;
  return { markedResponses, releasedResponses };
};

export default useMarkedResponses;

import { CONFIG } from './action-types';

const config = (state = {}, action) => {
  switch (action.type) {
    case `${CONFIG}_START`:
      return { loading: true };
    case `${CONFIG}_SUCCESS`:
      return { loading: false, loaded: true, ...action.response.data };
    case `${CONFIG}_FAILURE`:
      return { loading: false, ...action.response.data };
    default:
      return state;
  }
};

export default config;

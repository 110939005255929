import React, { useState } from 'react';
import { EditButtons, ConfirmButtons } from '../QuestionButtons';
// Components:
import Attachment from '../../../attachment/Attachment';

export default ({ dispatch, props }) => {
  const [state, setState] = useState(props.item);

  const setField = (e) =>
    setState({
      ...state,
      [e.currentTarget.name]: e.currentTarget.value,
    });

  const updateAttachments = (value) => {
    setField({ currentTarget: { name: 'attachments', value: JSON.stringify(value) } });
  };

  const ActionButtons = () => {
    if (props.hideActions) {
      return null;
    }
    return props.item.confirmed ? (
      <EditButtons submitted={props.submitted} idx={props.idx} />
    ) : (
      <ConfirmButtons submitted={props.submitted} idx={props.idx} question={state} dispatch={dispatch} />
    );
  };

  return (
    <div>
      <Attachment paths={state.attachments} onChange={updateAttachments} readOnly={props.item.confirmed} image />
      <ActionButtons />
    </div>
  );
};

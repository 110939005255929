import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import './ResponseMeta.scss';
import propTypes from 'prop-types';
import { get } from 'lodash';

import { RESPONSES_CLEAR_ERROR } from '../../root/action-types'
import { InputField, InputTextarea, SimpleButton, ButtonRow, InputDropdown, useCurriculumCodes } from '../../common/';

const Buttons = ({ confirmed, onClick }) => (
  <ButtonRow theme="right white">
    <SimpleButton theme={confirmed ? 'muted' : ''} text={confirmed ? 'Edit' : 'Confirm'} onClick={onClick} />
  </ButtonRow>
);
Buttons.propTypes = {
  confirmed: propTypes.bool,
  onClick: propTypes.func,
};

const StaticResponseMeta = ({ topic, syllabus, aim }) => (
  <dl className="static-response-meta">
    <dt>Topic:</dt>
    <dd>{topic}</dd>
    <dt>Syllabus Codes:</dt>
    <dd>
      <div className="list">
        {syllabus?.map((a, index) => (
          <div className="top" key={index}><div className="bottom">{a}</div></div>
        ))}
      </div>
    </dd>
    <dt>Aim:</dt>
    <dd>{aim}</dd>
  </dl>
);
StaticResponseMeta.propTypes = {
  topic: propTypes.string,
  syllabus: propTypes.arrayOf(propTypes.string),
  aim: propTypes.string,
};

const ResponseMeta = ({ confirmed, finalised, onClick, form, inputOnChange, updateForm }) => {
  const curriculumCodes = useCurriculumCodes();
  const dispatch = useDispatch()
  const { errorData } = useSelector(r => r.responses.responseStatus)
  const errors = get(errorData, 'meta', [])

  if (finalised) {
    return <StaticResponseMeta {...form} />;
  }

  const errorInputOnChange = (e) => {
    const field = e.target.name
    if (errors.includes(field)) {
      dispatch({ type: RESPONSES_CLEAR_ERROR, section: 'meta', field })
    }
    inputOnChange(e)
  }

  const field = (name) => ({
    name,
    value: form[name],
    theme: confirmed ? 'meta' : 'thin',
    disabled: confirmed,
    type: 'text',
    onChange: errorInputOnChange,
    error: errors.includes(name),
  });

  return (
    <div className={`response-meta ${confirmed ? 'confirmed' : ''}`}>
      <InputField {...field('topic')} label="Topic:" placeholder="Topic" />
      <InputDropdown
        value={form.syllabus}
        isDisabled={confirmed}
        theme={confirmed && 'meta'}
        label="Syllabus Codes:"
        placeholder="Syllabus Codes"
        onChange={(v) => {
          updateForm({ syllabus: v });
        }}
        error={field('syllabus').error}
        isMulti
        {...curriculumCodes}
      />
      <InputTextarea {...field('aim')} label="Aim:" placeholder="Aim" />
      <Buttons confirmed={confirmed} onClick={onClick} />
    </div>
  );
};
ResponseMeta.propTypes = {
  form: propTypes.object,
  onClick: propTypes.func,
  inputOnChange: propTypes.func,
  finalised: propTypes.bool,
  confirmed: propTypes.bool,
};

export default ResponseMeta;

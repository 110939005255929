import React from 'react';
import Modal from 'react-modal';
import propTypes from 'prop-types';
import './TimeOutModal.scss';
// Components:
import { ButtonRow, SimpleButton } from '../../../common/';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #41489E',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '470px',
  },
};

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const TimeOutModal = ({
  closeModal,
  modalIsOpen,
  submit
}) => {
  const onSubmit = () => {
    submit();
    closeModal();
  };

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
      <h3>Your time has expired.</h3>
      <div>
        Your 15 minutes to complete this answer sheet has expired. You can choose to continue or submit your current
        progress.
      </div>
      <br />
      <div>What would you like to do?</div>
      <ButtonRow theme="spaced">
        <SimpleButton onClick={onSubmit} text="Submit Current progress" />
        <SimpleButton onClick={closeModal} theme="muted" text="Continue" />
      </ButtonRow>
    </Modal>
  );
};

export default TimeOutModal;

TimeOutModal.propTypes = {
  openModal: propTypes.func,
  closeModal: propTypes.func,
  modalIsOpen: propTypes.bool,
  submit: propTypes.func,
};

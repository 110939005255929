export function next(status) {
  switch (status) {
    case 'initial':
      return 'create_crq';
    case 'create_crq':
      return 'answer_crq';
    case 'answer_crq':
      return 'mark_crq';
    case 'mark_crq':
      return 'complete';
    default:
      return 'initial';
  }
}

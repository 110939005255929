import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropbox } from 'dropbox';
import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../api/call';
import { CONFIG } from '../root/action-types';
import { store } from '../root/store';

// See Dropbox javascript SDK documentation:
// https://dropbox.github.io/dropbox-sdk-js/Dropbox.html
//
// This aims to be a very thin wrapper for it, that lets us use redux-sagas
// like other API calls

// If you want a custom redux reducer to handle this action, for caching, etc
// then put it here. Otherwise the generic reducer will handle it.
const SPECIAL = {
  filesDownload: 'ATTACHMENT_DOWNLOAD',
  filesListFolder: 'DROPBOX_LIST',
  filesSearch: 'DROPBOX_SEARCH',
};

let dbx;

// This is a little bit clunky- we need to request the config for the
// oauth2 key before we can init dropbox- we make this request as soon
// as the user logs in, so its probably instant, unless they reload the
// page. Components using this hook can workaround this by putting their
// requests in a useEffect hook, to wait until after init, eg:
// const { dropboxRequest, ready } = useDropbox()
// useEffect(() => { ready && dropboxRequest(args) }, [ready])
export const useDropbox = () => {
  const { dropboxKey, loaded } = useSelector((r) => r.config);

  const requestConfig = () => {
    if (!dbx && !loaded) {
      Api({ request: CONFIG });
    }
  };

  useEffect(requestConfig, []);

  if (loaded && !dbx) {
    dbx = new Dropbox({ accessToken: dropboxKey, fetch });
  }

  if (!dbx) {
    return {};
  }
  return { dropboxRequest, dropboxUpload, dropboxSearch, ready: true };
};

const dropboxRequest = (request, ...args) => {
  store.dispatch({
    type: 'DROPBOX_REQUEST',
    payload: {
      request,
      action: () => dbx[request](...args),
    },
  });
};

const dropboxUpload = (args) => dbx.filesUpload(args);

const dropboxSearch = (args) => dropboxRequest(args.query ? 'filesSearch' : 'filesListFolder', args);

function* dropboxSaga({ payload: { request, action } }) {
  const request_name = SPECIAL[request] || 'DROPBOX_ACTION';
  try {
    yield put({ type: `${request_name}_START` });

    const response = yield call(action);

    yield put({ type: `${request_name}_SUCCESS`, response });
  } catch (e) {
    yield put({ type: `${request_name}_FAILURE`, response: e.response });
  }
}

export function* dropboxWatcher() {
  yield takeEvery('DROPBOX_REQUEST', dropboxSaga);
}

import React from 'react';
import './Pane.scss';
import propTypes from 'prop-types';

function Pane(props) {
  const css = ['content-pane'];
  if (props.theme) {
    css.push(props.theme);
  }

  return <div className={css.join(' ')}>{props.children}</div>;
}

export default Pane;

Pane.propTypes = {
  children: propTypes.node.isRequired,
  theme: propTypes.string,
};

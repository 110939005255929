import React from 'react';
import propTypes from 'prop-types';
// Components
import ScenarioQuestion from './ScenarioQuestion';
import ListQuestion from './ListQuestion';
import { MemoizedClinical } from './ClinicalQuestion/ClinicalQuestion';
import FillableQuestion from './FillableQuestion';
import ShortQuestion from './ShortQuestion';

const Question = ({ name, ...rest }) => {
  switch (name) {
    case 'scenario':
      return <ScenarioQuestion {...rest} />;
    case 'clinical':
      return <MemoizedClinical {...rest} />;
    case 'short':
      return <ShortQuestion {...rest} />;
    case 'list':
      return <ListQuestion {...rest} />;
    case 'fillable':
      return <FillableQuestion {...rest} />;
    default:
      return null;
  }
};

export default Question;

Question.propTypes = {
  name: propTypes.string,
  rest: propTypes.object,
};

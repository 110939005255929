import React, { useState, useEffect } from 'react';
import Api from '../../api/call';
import { GROUPS } from '../../root/action-types';
import './Groups.scss';
// Components:
import GroupsIndex from '../GroupsIndex/GroupsIndex';
import GroupsNew from '../GroupsNew/GroupsNew';
import Pane from '../../common/Pane/Pane';

function Groups() {
  const [toggle, setToggle] = useState(false);

  useEffect(() => Api({ request: GROUPS }), []);

  const toggleModal = () => setToggle(!toggle);
  const closeModal = () => setToggle(false);

  return (
    <Pane>
      <div className="group-pane-container">
        <h2>GROUPS</h2>
        <div className="add-section" onClick={toggleModal}>
          <div className="icon">{toggle ? '-' : '+'}</div>
          <div>Create New Group</div>
        </div>
        {toggle ? <GroupsNew closeModal={closeModal} /> : null}
        <GroupsIndex />
      </div>
    </Pane>
  );
}

export default Groups;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Api from '../../api/call';
import { GROUPS_UPDATE, GROUPS_UPDATE_RESET_STORE, GROUPS_SHOW_RESET_STORE } from '../../root/action-types';
import propTypes from 'prop-types';
import { get } from 'lodash';
// Components:
import {
  Status,
  ReturnButton,
  InputField,
  StatusHandler,
  Pane,
  ButtonRow,
  SimpleButton,
  useUsers,
  useGroupUsers,
} from '../../common/';
import { UserAccordion, useAccordion } from '../UserAccordion/';
// Utils

function GroupsUpdate(props) {
  const groupId = props.match.params.id;
  const [title, setTitle] = useState('');
  const { group, show } = useSelector((store) => store.groups);
  const dispatch = useDispatch();

  const accordionReducer = useAccordion();

  const { users: userList } = useUsers();
  const { groupUsers } = useGroupUsers(groupId);

  useEffect(() => {
    if (groupUsers) {
      accordionReducer.dispatch({ type: 'reset', groupUsers, allUsers: userList });
    }
  }, [userList, groupUsers]);

  const getState = () => {
    if (!get(show, 'loading') && show.data) {
      setTitle(show.data.title);
    }
  };
  useEffect(getState, [show.loading]);

  const onDismount = () => {
    return () => {
      dispatch({ type: GROUPS_SHOW_RESET_STORE });
      dispatch({ type: GROUPS_UPDATE_RESET_STORE });
    };
  };
  useEffect(onDismount, []);

  const updateGroup = () => {
    const rows = accordionReducer.accordionState.accordionRows.filter((row) => row.user !== null);

    const users = rows.map((row) => ({ id: row.user.id, number: row.user.number, newNumber: row.position }));

    Api({
      request: GROUPS_UPDATE,
      id: groupId,
      data: {
        title: title,
        users,
      },
    });
  };

  const RenderStatusMessage = () => {
    const { errors, loading, updated } = group;
    return (
      <Status isError={!!errors}>
        <StatusHandler loading={loading} errors={errors} action={updated} onSuccess="Your details have been saved." />
      </Status>
    );
  };

  return (
    <Pane theme="edit-group">
      <ReturnButton to="/groups/" text="Return to Groups" />
      <div className="edit">
        <RenderStatusMessage />
        <InputField
          label="Group name"
          name="title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
          placeholder="Enter the group name"
          theme="thin"
        />
        <div>Change Users</div>
        <UserAccordion
          users={userList}
          accordionState={accordionReducer.accordionState}
          dispatch={accordionReducer.dispatch}
        />
        <ButtonRow>
          <SimpleButton theme="primary-button" text="UPDATE GROUP" onClick={updateGroup} />
          <SimpleButton
            theme="muted"
            text="Reset"
            onClick={() =>
              accordionReducer.dispatch({
                type: 'reset',
                allUsers: userList,
                groupUsers: groupUsers,
              })
            }
          />
        </ButtonRow>
      </div>
    </Pane>
  );
}

export default GroupsUpdate;

GroupsUpdate.propTypes = {
  group: propTypes.object,
  match: propTypes.object,
};

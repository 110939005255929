import React from 'react';
import './InputCheckBox.scss';
import propTypes from 'prop-types';
// Components:
import { Field } from '../../common/Form/Field';

// A standard Input Check Box.
function InputCheckBox(props) {
  const css = ['simple checkbox'];

  if (props.theme) {
    css.push(props.theme);
  }

  return (
    <Field label={props.label} className={css.join(' ')}>
      <input
        className="check-box"
        type="checkbox"
        name={props.name}
        checked={props.value}
        defaultChecked={props.defaultChecked}
        onChange={props.onChange}
        placeholder={props.placeholder}
        autoComplete="off"
        disabled={props.disabled}
      />
    </Field>
  );
}

export default InputCheckBox;

InputCheckBox.propTypes = {
  name: propTypes.string,
  label: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  placeholder: propTypes.string,
  defaultChecked: propTypes.bool,
  theme: propTypes.string,
};

import React from 'react';
import propTypes from 'prop-types';
import './Accordion.scss';
// Components:
import AccordionSection from './AccordionSection';

const Accordion = ({ disabled, children, ...props }) => (
  <div className="accordion-container" disabled={disabled}>
    {children.map((child, index) => (
      <Section key={index} child={child} index={index + 1} {...props} />
    ))}
  </div>
);

Accordion.propTypes = {
  children: propTypes.node,
  disabled: propTypes.bool,
};

const Section = ({ child, index, ...props }) => {
  const { isOpen, icon, onClick, cancel } = props;
  const { label, children, position, disabled } = child.props;

  return (
    <AccordionSection
      isOpen={position === isOpen}
      label={label}
      icon={icon}
      onClick={() => onClick(position)}
      cancel={() => cancel(position)}
      index={index}
      sectionDisabled={disabled}
    >
      {children}
    </AccordionSection>
  );
};

Section.propTypes = {
  child: propTypes.object,
  index: propTypes.number,
  isOpen: propTypes.oneOfType([propTypes.string, propTypes.number]),
  icon: propTypes.object,
  onClick: propTypes.func,
  label: propTypes.string,
  cancel: propTypes.func,
};

export default Accordion;

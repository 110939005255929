import { combineReducers } from 'redux';

import { NOTIFICATIONS, ADMIN_NOTIFICATIONS } from '../root/action-types';
import { standardForCollections, standardModelCreateUpdateDestroy } from '../api/standardReducers';

export default combineReducers({
  index: standardForCollections(NOTIFICATIONS),
  adminIndex: standardForCollections(ADMIN_NOTIFICATIONS),
  current: standardModelCreateUpdateDestroy('notification', NOTIFICATIONS),
});

import React from 'react';
// Components:
import NavItem from './NavItem.jsx';

function AdminNavBar() {
  return (
    <React.Fragment>
      <NavItem text="Dashboard" path="/admin" />
      <NavItem text="Members" path="/users" />
      <NavItem text="Groups" path="/groups" />
      <NavItem text="Current Round" path="/currentRound" />
      <NavItem text="Previous Rounds" path="/previousRounds" />
      <NavItem text="Resources" path="/resources" />
      <NavItem text="Email Logs" path="/emailLogs" />
      {/* <NavItem text='Article Bank' path="/articles" /> */}
      <NavItem text="Settings" path="/users/current" />
    </React.Fragment>
  );
}

export default AdminNavBar;

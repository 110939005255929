import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { hideBars, SideBarVisibility } from '../layout/SideBarVisibility';
import propTypes from 'prop-types';
import './Layout.scss';
// Components:
import NavBar from './NavBar/NavBar';
import TopBar from './TopBar/TopBar';

function Layout(props) {
  return (
    <Fragment>
      <SideBarVisibility>
        <TopBar />
      </SideBarVisibility>

      <div className={`layout-under-topbar ${hideBars() ? 'layout-barless-page' : 'layout-content-pane'}`}>
        <SideBarVisibility>
          <NavBar />
        </SideBarVisibility>
        {props.children}
      </div>
    </Fragment>
  );
}

export default withRouter(Layout);

Layout.propTypes = {
  children: propTypes.node.isRequired,
};

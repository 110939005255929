import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux'
import Api from '../../api/call';
import { USERS } from '../../root/action-types';
import './Users.scss';
// Components:
import UsersNew from '../UsersNew/UsersNew';
import UsersIndex from '../UsersIndex/UsersIndex';
import Pane from '../../common/Pane/Pane';

function Users() {
  const [toggle, setToggle] = useState(false);
  const toggleModal = () => setToggle(!toggle);
  const closeModal = () => setToggle(false);

  useEffect(() => {
    Api({ request: USERS });
  }, []);

  return (
    <Pane>
      <h2>MEMBERS</h2>
      <div className="user-pane-container">
        <div className="add-section" onClick={toggleModal}>
          <div className="icon">{toggle ? '-' : '+'}</div>
          <div>Add New User</div>
        </div>
        {toggle ? <UsersNew closeModal={closeModal} /> : null}
        <UsersIndex />
      </div>
    </Pane>
  );
}

export default Users;

import { useEffect } from 'react'
import { GROUPS_SHOW } from '../../root/action-types'
import { useSelector } from 'react-redux'
import Api from '../../api/call'

export const useGroupUsers = (groupId) => {
  const { data, loading } = useSelector((store) => store.groups.show)

  useEffect(() => {
    Api({
      request: GROUPS_SHOW,
      id: groupId,
      params: { includes: 'users' },
    })
  }, [groupId])

  const { users, firstPaper } = (!loading && data) || {}
  return { groupUsers: users, firstPaper }
}

export default useGroupUsers

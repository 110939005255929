import React, { Component } from 'react';
import propTypes from 'prop-types';

class Columns extends Component {
  renderColumn = (column) => (
    <span className="index-table-column-header" key={column.accessor + 'Header'}>
      {column.Header || column.accessor}
    </span>
  );

  renderColumns = () => this.props.columns.map((column) => this.renderColumn(column));

  render = () => <div className="index-table-header">{this.renderColumns()}</div>;
}

export default Columns;

Columns.propTypes = {
  columns: propTypes.array.isRequired,
};

import React from 'react';
import { get } from 'lodash';
import propTypes from 'prop-types';
// Components:
import SimpleButton from '../SimpleButton/SimpleButton';

function PagingControl(props) {
  const checkForData = (data, page) => {
    if (data.length > 0 || page === 1) return;
    backPage();
  };

  const backPage = () => {
    if (props.page === 1) return;
    props.handlePageChange(props.page - 1);
  };

  const forwardPage = () => {
    const { page, data, count } = props;
    const pageSize = get(props.pageSize, 'value', null);
    const start = page * pageSize - pageSize + 1;
    const end = start + data.length - 1;
    if (end >= count) return;
    props.handlePageChange(page + 1);
  };

  const goToPage = (toPage) => {
    if (toPage === props.page) return;
    props.handlePageChange(toPage);
  };

  const RenderPagingButtons = () => {
    const { page, count } = props;
    const pageSize = get(props.pageSize, 'value', null);
    const total = Math.ceil(count / pageSize);
    const start = Math.max(1, Math.min(page - 2, total - 4));
    const end = Math.min(total, Math.max(page + 2, 5));
    const pages = [];
    const buttons = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    if (start > 1) buttons.push(<SimpleButton theme="page" text="..." onClick={backPage} />);

    buttons.push(
      ...pages.map((p) => (
        <SimpleButton
          theme={p === page ? 'page-focus' : 'page'}
          onClick={() => {
            goToPage(p);
          }}
          key={p}
          text={p.toString()}
        />
      ))
    );

    if (end < total) buttons.push(
      <SimpleButton theme="page" text="..." onClick={forwardPage} key={total} />
    )

    return buttons;
  };

  const { data, page, count } = props;
  checkForData(data, page);
  if (count === 0) return null;
  return (
    <span className="index-table-control">
      <SimpleButton theme="default" text="◄" onClick={backPage} />
      <RenderPagingButtons />
      <SimpleButton theme="default" text="►" onClick={forwardPage} />
    </span>
  );
}

export default PagingControl;

PagingControl.propTypes = {
  data: propTypes.array.isRequired,
  page: propTypes.number.isRequired,
  count: propTypes.number.isRequired,
  pageSize: propTypes.object.isRequired,
  handlePageChange: propTypes.func.isRequired,
};

import React from 'react';

function StatusHandler(props) {
  if (props.loading) {
    return 'Loading...';
  } else if (props.errors && props.errors.length) {
    return <div className="message">{props.errors.join(', ')}</div>;
  } else if (props.action) {
    return props.onSuccess;
  } else {
    return '';
  }
}

export default StatusHandler;

import React from 'react';
import './SimpleButton.scss';
import propTypes from 'prop-types';

// A standard button.
function SimpleButton({ expand, theme, text, children, ...props }) {
  const css = ['simple-button'];

  if (expand) {
    css.push('expand');
  }
  if (theme) {
    css.push(theme);
  }

  return (
    <button className={css.join(' ')} {...props}>
      {children}
      {text}
    </button>
  );
}

export default SimpleButton;

SimpleButton.propTypes = {
  theme: propTypes.string,
  expand: propTypes.bool,
  text: propTypes.string,
  children: propTypes.oneOfType([propTypes.element, propTypes.node]),
};

import React from 'react';
import { useSelector } from 'react-redux';

//Components
import { Pane, usePreviousRounds, RoundAccordion } from '../common';

const PreviousRounds = () => {
  const currentUser = useSelector((store) => store.session.current);
  const admin = currentUser.admin;
  const { rounds } = usePreviousRounds({ admin: admin });

  return (
    <Pane>
      {rounds.length === 0 ? <h2>NO PREVIOUS ROUNDS EXIST</h2> : <h2>{admin ? 'BLANK CRQ/AGS' : 'PREVIOUS ROUNDS'}</h2>}
      <RoundAccordion data={rounds} admin={admin} />
    </Pane>
  );
};

export default PreviousRounds;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { attachmentIcon, uploadIcon } from '../root/assets/';
import { isArray, isString } from 'lodash';

import { Loading, Conditional } from '../common/';
import { useDropbox } from './dropbox';

import './attachment.scss';

const useDropboxFiles = (paths) => {
  const { dropboxRequest, ready } = useDropbox();
  const { files } = useSelector((r) => r.attachment);

  useEffect(() => {
    ready && paths.forEach((path) => files[path] || dropboxRequest('filesDownload', { path }));
  }, [paths, ready, files, dropboxRequest]);

  return { files };
};

const Attachment = ({ paths, onChange, image, readOnly }) => {
  const { files } = useDropboxFiles(paths);
  const add = (path) => onChange([...paths, path]);
  const remove = (path) => onChange(paths.filter((p) => p !== path));

  if (readOnly) {
    return <ReadOnly files={files} paths={paths} />;
  }

  return (
    <div className="attachment">
      <UploadDropzone add={add} image={image}>
        {paths.map((p, i) => (
          <Element key={i} file={files[p]} remove={remove} />
        ))}
      </UploadDropzone>
    </div>
  );
};

// Only loads the file on demand
export const AttachmentLink = ({ path, children }) => {
  const { files } = useSelector((r) => r.attachment);
  const [trigger, setTrigger] = useState(false);
  const file = files[path];
  const { dropboxRequest, ready } = useDropbox();
  const child = children ? children : <img src={attachmentIcon} alt="View Article" title="View Article" />;

  const onTrigger = () => {
    if (trigger) {
      setTrigger(false);
      show();
    }
  };

  useEffect(onTrigger, [file]);

  const link = useRef(null);
  const data = file && URL.createObjectURL(file.fileBlob);
  const filename = file && file.name;

  const show = () => {
    if (file) {
      link.current.click();
    } else if (!trigger) {
      setTrigger(true);
      dropboxRequest('filesDownload', { path });
    }
  };

  if (!ready) {
    return <Loading />;
  }

  return (
    <span className="attachment-link" onClick={show}>
      <a ref={link} href={data} download={filename}>
        {child}
      </a>
    </span>
  );
};

const ReadOnly = ({ files, paths }) => (
  <div className="attachment read-only">
    {paths.map((p, i) => (
      <Element key={i} file={files[p]} />
    ))}
  </div>
);

const sanitizePaths = (paths) => {
  if (!paths) {
    return [];
  }
  if (isString(paths)) {
    return JSON.parse(paths);
  }
  if (isArray(paths)) {
    return paths;
  }
  throw new Error(`Unsupported data passed to attachment: ${paths}`);
};

const Sanitizer = ({ paths, ...props }) => <Attachment paths={sanitizePaths(paths)} {...props} />;

const Element = (props) => (props.file ? <Upload {...props} /> : <Uploading />);

const Uploading = () => (
  <div className="element uploading">
    <Loading />
  </div>
);

const Upload = ({ file, remove }) => {
  const onClick = (e) => {
    remove(file.id);
    e.stopPropagation();
  };
  const imgUrl = URL.createObjectURL(file.fileBlob);
  return (
    <div className="element upload-complete">
      <img src={imgUrl} alt="" />
      <Conditional if={remove}>
        <div className="remove-upload" onClick={onClick}>
          <FontAwesomeIcon icon={faTimesCircle} size="2x" />
        </div>
      </Conditional>
    </div>
  );
};

const DROP_TEXT = {
  active: 'Drop your image here...',
  inactive: 'Drag & drop your image here, or click to select from your computer (.PNG or .JPG Only)',
};

const UploadDropzone = ({ add, children, image }) => {
  const { dropboxUpload, ready } = useDropbox();
  const [uploading, setUploading] = useState(0);

  const onDrop = useCallback(
    (files) => {
      if (files.length > 1) {
        return alert('Please select a single file to upload');
      }
      files.forEach((f) => {
        if (image && !validateImage(f)) {
          return;
        }
        setUploading((u) => u + 1);
        dropboxUpload({
          contents: f,
          path: `/uploads/${f.path}`,
        })
          .then((response) => {
            setUploading((u) => u - 1);
            add(response.id);
          })
          .catch((error) => {
            if (error.status === 409) {
              alert('Filename is already taken, please rename your file and try again.');
            }
          });
      });
    },
    [add, dropboxUpload, image]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (!ready) {
    return <Loading />;
  }
  return (
    <div className="upload-dropzone" {...getRootProps()}>
      {children}
      <div className="element dropper">
        <input {...getInputProps()} />
        {uploading > 0 ? <Loading /> : <img src={uploadIcon} alt="View Article" title="View Article" />}
        <p>{DROP_TEXT[isDragActive ? 'active' : 'inactive']}</p>
      </div>
    </div>
  );
};

const TWO_MEGABYTES = 2097152;

// Match the end of the string for one of the extension option, case insensitive
const IMAGES = /(png|jpg|jpeg|svg)$/i;

const isImageFile = (name) => {
  try {
    return !!name.match(IMAGES);
  } catch (e) {
    return false;
  }
};

const validateImage = (file) => {
  if (file.size > TWO_MEGABYTES) {
    alert('Image size is limited to 2MB');
  } else if (!isImageFile(file.name)) {
    alert('Only images in following format are permitted: JPG, JPEG, PNG, SVG');
  } else {
    return true;
  }
  return false;
};

Sanitizer.propTypes = {
  paths: propTypes.any,
};
Attachment.propTypes = {
  paths: propTypes.array,
  onChange: propTypes.func,
  image: propTypes.bool,
  readOnly: propTypes.bool,
};
AttachmentLink.propTypes = {
  path: propTypes.string,
  children: propTypes.node,
};
ReadOnly.propTypes = {
  paths: propTypes.array,
  files: propTypes.oneOfType([propTypes.object, propTypes.array]),
};
Element.propTypes = {
  file: propTypes.object,
};
Upload.propTypes = {
  file: propTypes.object,
  remove: propTypes.func,
};
UploadDropzone.propTypes = {
  add: propTypes.func,
  children: propTypes.node,
  image: propTypes.bool,
};

export default Sanitizer;

import React, { Component } from 'react';
import { pick } from 'lodash';
import Api from '../../api/call';
import propTypes from 'prop-types';
// Components:
import IndexTable from './IndexTable';

class PagedIndexTable extends Component {
  state = { page: 1, pageSize: { value: 20, label: 20 }, search: '' };

  componentDidMount() {
    this.updateTable();
  }

  handlePageChange = (page) => {
    this.setState({ page }, this.updateTable);
  };

  handleSizeChange = (pageSize) => {
    this.setState({ pageSize }, this.updateTable);
  };

  queueSearchUpdate = () => {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => this.updateTable(), 0);
  };

  handleSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search }, this.queueSearchUpdate);
    e.preventDefault();
  };

  requestDataAction = (params) => Api({ request: this.props.requestDataAction, params });

  searchTimeout = null;

  updateTable = () => {
    const { page, search, pageSize } = this.state;
    const limit = pageSize.value;
    const offset = page * limit - limit;
    const params = { limit, offset, search };

    this.requestDataAction(params);
  };

  getPageSizeOptions = () => [
    { value: 5, label: 5 },
    { value: 20, label: 20 },
    { value: 50, label: 50 },
  ];

  pickedProps = () => ({
    ...pick(this.props, ['data', 'columns', 'defaultColumns', 'count', 'total', 'noShow'])
  });

  pickedState = () => ({ ...pick(this.state, ['page', 'pageSize', 'search']) });

  render = () => (
    <IndexTable
      model={this.props.model.toUpperCase()}
      pageSizeOptions={this.getPageSizeOptions()}
      handlePageChange={this.handlePageChange}
      handleSizeChange={this.handleSizeChange}
      handleSearchChange={this.handleSearchChange}
      {...this.pickedProps()}
      {...this.pickedState()}
    />
  );
}

export default PagedIndexTable;

PagedIndexTable.propTypes = {
  requestDataAction: propTypes.string.isRequired,
  model: propTypes.string.isRequired,
};

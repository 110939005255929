import React from 'react';
import { EditButtons, ConfirmButtons } from './QuestionButtons';
import { formats, toolbarModules } from '../../common/RichText/Constants';
import ReactQuill from 'react-quill';
import { useQuestionsContext } from '../ResponseCreate/question-manager';
import 'react-quill/dist/quill.snow.css';
import { Prefix } from './Prefix'
import propTypes from 'prop-types';
import Select from 'react-select';
import { options } from '../dropdownOptions.js';
import './FillableQuestion.scss';

// Components:
import { InputTable } from '../../common/';

const RichText = ({ readOnly, value, updateField }) => {
  const textChange = ( content ) => {
    if(value != content){
      updateField(content);
    }
  };

  const modules = toolbarModules(readOnly)
  return (
    <ReactQuill
      theme="snow"
      className={"ql-show-toolbar"}
      value={value}
      modules={modules}
      onChange={textChange}
      placeholder="Question here"
      readOnly={readOnly}
      formats={formats}
    />
  );
}

function FillableQuestion({ idx, hideActions, ...props }) {
  const { dispatch, item } = useQuestionsContext(idx)

  const updateField = (field) => {
    dispatch({
      type: 'update',
      index: idx,
      name: "field",
      value: field
    });
  }

  const updateMarks = (marks) => {
    dispatch({
      type: 'update',
      index: idx,
      name: "marks",
      value: marks
    });
  }

  const updateTable = (data) => {
    dispatch({
      type: 'update',
      index: idx,
      name: "table",
      value: data
    });
  };

  const ActionButtons = () => {
    if (hideActions) {
      return null;
    }
    return item.confirmed ? (
      <EditButtons submitted={props.submitted} idx={idx} name={item.name} dispatch={dispatch} />
    ) : (
      <ConfirmButtons submitted={props.submitted} idx={idx} question={item} dispatch={dispatch} />
    );
  };

  const MarkSelector = () => (
    <Select
      className="mark-dropdown"
      classNamePrefix="select"
      onChange={updateMarks}
      value={item.marks}
      options={options}
    />
  );

  const buildClassName = () =>
    `fillable-container
    ${!item.confirmed ? 'active' : ''}
    ${props.submitted ? 'crq-submitted' : ''}
    ${props.error ? 'error' : ''}`;

  return (
    <div className={buildClassName()}>
      <div className="input-mark-container">
        <Prefix idx={idx} />
        <RichText
          className="react-quill-text-area"
          readOnly={item.confirmed}
          value={item.field}
          updateField={updateField}
        />
        {item.confirmed ? <div className="marks">{item.marks.label}</div> : <MarkSelector />}
      </div>
      <InputTable
        originalTable={item.originalTable}
        table={item.table}
        onChange={updateTable}
        readOnly={item.confirmed && props.disableAnswer}
        answer={!props.disableAnswer}
        noToolbar={!props.disableAnswer}
      />
      <ActionButtons />
    </div>
  );
}

export default FillableQuestion;

FillableQuestion.propTypes = {
  idx: propTypes.number,
  actions: propTypes.object,
  setMarks: propTypes.func,
  hideActions: propTypes.bool,
  submitted: propTypes.bool,
  disableAnswer: propTypes.bool,
  error: propTypes.bool,
};

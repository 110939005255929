import { useState } from 'react';

// NOTE: To merge changes into the form object, you must use the CB version
// of setState, because it runs async.
// Otherwise multiple calls with _different_ form keys can confuse each other!
export const useForm = (initial) => {
  const [form, setForm] = useState(initial || {});
  const updateForm = (change) => setForm((prev) => ({ ...prev, ...change }));
  const inputOnChange = ({ currentTarget: { name, value } }) => {
    updateForm({ [name]: value });
  };

  return { form, updateForm, inputOnChange };
};

import React from 'react';
import Question from '../QuestionTypes/Question';
import propTypes from 'prop-types';

export const QuestionItem = (props) => {
  const questionProps = { ...props, hideActions:true, confirmed: true, disableAnswer: true };

  return <Question name={props.item.name} {...questionProps} />;
};

QuestionItem.propTypes = {
  item: propTypes.object,
};

export const QuestionIndex = ({ questions, submitted }) => {
  return questions.map((item, idx) => {
    item.confirmed = true;
    const itemProps = { item, idx, submitted };

    return (
      <div key={idx}>
        <QuestionItem {...itemProps} />
      </div>
    );
  });
};

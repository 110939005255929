import React from 'react';
import AccordionSegment from './roundAccordionSegment';
import './roundAccordion.scss';

export const RoundAccordion = ({ data, admin }) => {
  const segments = data.map((item) => {
    const date = {
      year: new Date(item.createdAt).getFullYear(),
      month: new Date(item.createdAt).getMonth(),
      papers: [],
    };
    return { ...date, papers: [...item.papers] };
  });

  return (
    <div className="accordion">
      {segments.map((item, index) => (
        <AccordionSegment className="accordionSegment" admin={admin} key={index} period={item} />
      ))}
    </div>
  );
};

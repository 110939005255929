import React from 'react';
import ModelIndexTable from '../../common/IndexTable/ModelIndexTable';
import { GROUPS } from '../../root/action-types';

const COLUMNS = ['title', 'status'];
const DEFAULT_COLUMNS = COLUMNS.slice(0, 7);

const GroupsIndex = () => (
  <ModelIndexTable
    model="groups"
    dataMap={(row) => ({ ...row })}
    requestAction={GROUPS}
    COLUMNS={COLUMNS}
    defaultColumns={DEFAULT_COLUMNS}
  />
);

export default GroupsIndex;

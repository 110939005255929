import React from 'react';
// Images:
import ImageSVG from '../../../root/assets/image.svg';
import TableSVG from '../../../root/assets/table.svg';

export default ({ setQuestionType, submitted }) => {
  return (
    <div className="clinical-options">
      <button className="button-container" onClick={() => setQuestionType('IMAGE')} disabled={submitted}>
        <img src={ImageSVG} alt="add" />
        <div>Insert Image</div>
      </button>
      <button className="button-container" onClick={() => setQuestionType('TABLE')} disabled={submitted}>
        <img src={TableSVG} alt="add-table" />
        <div>Insert Table</div>
      </button>
    </div>
  );
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pick } from 'lodash';
import propTypes from 'prop-types';

import Api from '../../api/call';
import { USERS_UPDATE, USERS_SHOW, USERS_SHOW_RESET_STORE, USERS_UPDATE_RESET_STORE } from '../../root/action-types';
import {
  useForm,
  Status,
  Pane,
  ReturnButton,
  InputCheckBox,
  StatusHandler,
  ButtonRow,
  SimpleButton,
  Conditional,
  InputField,
} from '../../common';
import MomentFormat from '../../root/MomentFormat';

const BLANK = {
  firstName: '',
  lastName: '',
  collegeReferenceNumber: '',
  email: '',
  eClubNumber: '',
  admin: false,
  password_confirmation: '', // If left undefined, it bypasses validation
};

export const UsersUpdate = ({
  match: {
    params: { id },
  },
}) => <UserForm id={id} />;
UsersUpdate.propTypes = {
  match: propTypes.object.isRequired,
};

export const CurrentUserUpdate = () => {
  const { id } = useSelector((r) => r.session.current);
  return <UserForm id={id} isCurrent />;
};

const UserForm = ({ id, isCurrent }) => {
  const { form, updateForm, inputOnChange } = useForm(BLANK);
  const { data, loading } = useSelector((r) => r.users.show);
  const dispatch = useDispatch();
  const current = useSelector((r) => r.session.current);
  const isAdmin = !!current.admin;

  useEffect(() => {
    Api({ request: USERS_SHOW, id });

    return () => {
      dispatch({ type: USERS_SHOW_RESET_STORE });
      dispatch({ type: USERS_UPDATE_RESET_STORE });
    };
  }, [id]);

  // Finished loading some user data, so populate the form fields with it:
  useEffect(() => {
    if (data && !loading) {
      updateForm(pick(data, Object.keys(BLANK)));
    }
  }, [data, loading]);

  const sendUpdate = () => {
    Api({ request: USERS_UPDATE, id, data: form });
  };

  const inputProps = (name) => ({
    name: name,
    value: form[name],
    onChange: inputOnChange,
    disabled: loading,
  });

  return (
    <Pane>
      <Conditional if={!isCurrent}>
        <ReturnButton to="/users/" text="Return to Users" />
      </Conditional>
      <Conditional if={isCurrent}>
        <h2>SETTINGS</h2>
      </Conditional>
      <div className="edit-user-options">
        <UserStatus />
        <InputField label="First Name" {...inputProps('firstName')} />
        <InputField label="Last Name" {...inputProps('lastName')} />
        <Conditional if={isAdmin}>
          <InputField label="Email Address" {...inputProps('email')} />
        </Conditional>
        <Conditional if={isCurrent}>
          <InputField
            label="Password"
            {...inputProps('password')}
            className="large"
            type="password"
            placeholder="Enter a new password"
            theme="large"
          />
          <InputField
            label="Confirm Password"
            {...inputProps('password_confirmation')}
            className="large"
            type="password"
            placeholder="Confirm your password"
            theme="large"
          />
        </Conditional>
        <Conditional if={!isCurrent || !isAdmin}>
          <InputField
            label="College Ref No"
            {...inputProps('collegeReferenceNumber')}
            disabled={loading || !isAdmin}
            theme={isAdmin ? '' : 'large static'}
          />
          <InputField
            label="ECN"
            {...inputProps('eClubNumber')}
            disabled={loading || !isAdmin}
            theme={isAdmin ? '' : 'large static'}
          />
        </Conditional>
        <Conditional if={isCurrent && !isAdmin}>
          <InputField
            label="Join Date"
            name="join_date"
            value={MomentFormat.longDate(form.created_at)}
            type="text"
            theme="large static"
            disabled
          />
        </Conditional>
        <Conditional if={!isCurrent}>
          <InputCheckBox
            label="Admin"
            name="admin"
            value={form.admin}
            onChange={() => updateForm({ admin: !form.admin })}
            disabled={loading}
          />
        </Conditional>
      </div>
      <ButtonRow>
        <SimpleButton theme="simple-button" onClick={sendUpdate} text="UPDATE USER" disabled={loading} />
      </ButtonRow>
    </Pane>
  );
};
UserForm.propTypes = {
  id: propTypes.string,
  isCurrent: propTypes.bool,
};

const UserStatus = () => {
  const { loading, errors, updated } = useSelector((r) => r.users.user);
  return (
    <Status isError={errors && errors.length > 0}>
      <StatusHandler loading={loading} errors={errors} action={updated} onSuccess="Your details have been saved." />
    </Status>
  );
};

export default UsersUpdate;

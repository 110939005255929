import endpoints from '../api/endpoints';

const ActionTypes = {
  API_REQUEST: 'API_REQUEST',
  LOGOUT: 'LOGOUT',
  RESPONSES_CLEAR_ERROR: 'RESPONSES_CLEAR_ERROR',
}

const apiActions = Object.keys(endpoints)
  .map((e) => [e, `${e}_START`, `${e}_SUCCESS`, `${e}_FAILURE`, `${e}_RESET_STORE`])
  .flat();

apiActions.forEach((a) => {
  ActionTypes[a] = a;
});

export const {
  API_REQUEST,
  ARTICLES,
  ARTICLES_ASSIGNMENT,
  ARTICLES_ASSIGNMENT_START,
  ARTICLES_ASSIGNMENT_SUCCESS,
  ARTICLES_ASSIGNMENT_FAILURE,
  ARTICLES_CREATE,
  ARTICLES_CREATE_FAILURE,
  ARTICLES_CREATE_RESET_STORE,
  ARTICLES_CREATE_START,
  ARTICLES_CREATE_SUCCESS,
  ARTICLES_DESTROY,
  ARTICLES_DESTROY_FAILURE,
  ARTICLES_DESTROY_RESET_STORE,
  ARTICLES_DESTROY_START,
  ARTICLES_DESTROY_SUCCESS,
  ARTICLES_FAILURE,
  ARTICLES_RESET_STORE,
  ARTICLES_SHOW,
  ARTICLES_SHOW_FAILURE,
  ARTICLES_SHOW_RESET_STORE,
  ARTICLES_SHOW_START,
  ARTICLES_SHOW_SUCCESS,
  ARTICLES_START,
  ARTICLES_SUCCESS,
  ARTICLES_UPDATE,
  ARTICLES_UPDATE_FAILURE,
  ARTICLES_UPDATE_RESET_STORE,
  ARTICLES_UPDATE_START,
  ARTICLES_UPDATE_SUCCESS,
  CONFIG,
  CURRENT_ANSWER_RESPONSES,
  CURRENT_MARK_RESPONSES,
  CURRENT_RESPONSE,
  CURRENT_RESPONSE_FAILURE,
  CURRENT_RESPONSE_RESET_STORE,
  CURRENT_RESPONSE_START,
  CURRENT_RESPONSE_SUCCESS,
  CURRENT_USER,
  CURRENT_USER_FAILURE,
  CURRENT_USER_RESET_STORE,
  CURRENT_USER_START,
  CURRENT_USER_SUCCESS,
  DROPBOX_LIST,
  DROPBOX_SEARCH,
  CRQ_DOWNLOAD,
  EMAIL_LOGS,
  GROUPS,
  GROUPS_CREATE,
  GROUPS_CREATE_FAILURE,
  GROUPS_CREATE_RESET_STORE,
  GROUPS_CREATE_START,
  GROUPS_CREATE_SUCCESS,
  GROUPS_DESTROY,
  GROUPS_DESTROY_FAILURE,
  GROUPS_DESTROY_RESET_STORE,
  GROUPS_DESTROY_START,
  GROUPS_DESTROY_SUCCESS,
  GROUPS_FAILURE,
  GROUPS_RESET_STORE,
  GROUPS_SHOW,
  GROUPS_SHOW_FAILURE,
  GROUPS_SHOW_RESET_STORE,
  GROUPS_SHOW_START,
  GROUPS_SHOW_SUCCESS,
  GROUPS_START,
  GROUPS_SUCCESS,
  GROUPS_UPDATE,
  GROUPS_UPDATE_FAILURE,
  GROUPS_UPDATE_RESET_STORE,
  GROUPS_UPDATE_START,
  GROUPS_UPDATE_SUCCESS,
  LOGOUT,
  MISSED_DEADLINES,
  NOTIFICATIONS,
  NOTIFICATIONS_START,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_FAILURE,
  NOTIFICATIONS_CREATE_RESET_STORE,
  NOTIFICATIONS_CREATE,
  NOTIFICATIONS_CREATE_START,
  NOTIFICATIONS_CREATE_SUCCESS,
  NOTIFICATIONS_CREATE_FAILURE,
  ADMIN_NOTIFICATIONS,
  ADMIN_NOTIFICATIONS_START,
  ADMIN_NOTIFICATIONS_SUCCESS,
  ADMIN_NOTIFICATIONS_FAILURE,
  ADMIN_NOTIFICATIONS_CREATE_RESET_STORE,
  ADMIN_NOTIFICATIONS_CREATE,
  ADMIN_NOTIFICATIONS_CREATE_START,
  ADMIN_NOTIFICATIONS_CREATE_SUCCESS,
  ADMIN_NOTIFICATIONS_CREATE_FAILURE,
  PAPERS,
  PAPERS_CREATE,
  PAPERS_CREATE_FAILURE,
  PAPERS_CREATE_RESET_STORE,
  PAPERS_CREATE_START,
  PAPERS_CREATE_SUCCESS,
  PAPERS_DESTROY,
  PAPERS_DESTROY_FAILURE,
  PAPERS_DESTROY_RESET_STORE,
  PAPERS_DESTROY_START,
  PAPERS_DESTROY_SUCCESS,
  PAPERS_FAILURE,
  PAPERS_RESET_STORE,
  PAPERS_SHOW,
  PAPERS_SHOW_FAILURE,
  PAPERS_SHOW_RESET_STORE,
  PAPERS_SHOW_START,
  PAPERS_SHOW_SUCCESS,
  PAPERS_START,
  PAPERS_SUCCESS,
  PAPERS_UPDATE,
  PAPERS_UPDATE_FAILURE,
  PAPERS_UPDATE_RESET_STORE,
  PAPERS_UPDATE_START,
  PAPERS_UPDATE_SUCCESS,
  PASSWORD_RESET,
  PASSWORD_RESET_START,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
  PASSWORD_CHANGE,
  PROGRESS_ROUND,
  PROGRESS_ROUND_FAILURE,
  PROGRESS_ROUND_RESET_STORE,
  PROGRESS_ROUND_START,
  PROGRESS_ROUND_SUCCESS,
  RESET_CURRENT_RESPONSE,
  RESOURCES,
  RESOURCES_CREATE,
  RESOURCES_CREATE_FAILURE,
  RESOURCES_CREATE_RESET_STORE,
  RESOURCES_CREATE_START,
  RESOURCES_CREATE_SUCCESS,
  RESOURCES_DESTROY,
  RESOURCES_DESTROY_FAILURE,
  RESOURCES_DESTROY_RESET_STORE,
  RESOURCES_DESTROY_START,
  RESOURCES_DESTROY_SUCCESS,
  RESOURCES_FAILURE,
  RESOURCES_RESET_STORE,
  RESOURCES_SHOW,
  RESOURCES_SHOW_FAILURE,
  RESOURCES_SHOW_RESET_STORE,
  RESOURCES_SHOW_START,
  RESOURCES_SHOW_SUCCESS,
  RESOURCES_START,
  RESOURCES_SUCCESS,
  RESOURCES_UPDATE,
  RESOURCES_UPDATE_FAILURE,
  RESOURCES_UPDATE_RESET_STORE,
  RESOURCES_UPDATE_START,
  RESOURCES_UPDATE_SUCCESS,
  RESPONSES,
  RESPONSES_CLEAR_ERROR,
  RESPONSES_CREATE,
  RESPONSES_CREATE_FAILURE,
  RESPONSES_CREATE_RESET_STORE,
  RESPONSES_CREATE_START,
  RESPONSES_CREATE_SUCCESS,
  RESPONSES_DESTROY,
  RESPONSES_DESTROY_FAILURE,
  RESPONSES_DESTROY_RESET_STORE,
  RESPONSES_DESTROY_START,
  RESPONSES_DESTROY_SUCCESS,
  RESPONSES_FAILURE,
  RESPONSES_FOR_GROUP,
  RESPONSES_FOR_GROUP_START,
  RESPONSES_FOR_GROUP_SUCCESS,
  RESPONSES_FOR_GROUP_FAILURE,
  RESPONSES_MARKED,
  RESPONSES_MARKED_FAILURE,
  RESPONSES_MARKED_RESET_STORE,
  RESPONSES_MARKED_START,
  RESPONSES_MARKED_SUCCESS,
  RESPONSES_RESET_STORE,
  RESPONSES_ROLLBACK_STATUS,
  RESPONSES_ROLLBACK_STATUS_FAILURE,
  RESPONSES_ROLLBACK_STATUS_RESET_STORE,
  RESPONSES_ROLLBACK_STATUS_START,
  RESPONSES_ROLLBACK_STATUS_SUCCESS,
  RESPONSES_SHOW,
  RESPONSES_SHOW_FAILURE,
  RESPONSES_SHOW_RESET_STORE,
  RESPONSES_SHOW_START,
  RESPONSES_SHOW_SUCCESS,
  RESPONSES_START,
  RESPONSES_SUCCESS,
  RESPONSES_UPDATE,
  RESPONSES_UPDATE_ANSWERS,
  RESPONSES_UPDATE_ANSWERS_START,
  RESPONSES_UPDATE_ANSWERS_SUCCESS,
  RESPONSES_UPDATE_ANSWERS_FAILURE,
  RESPONSES_UPDATE_ANSWERS_RESET_STORE,
  RESPONSES_UPDATE_MARKS,
  RESPONSES_UPDATE_MARKS_START,
  RESPONSES_UPDATE_MARKS_SUCCESS,
  RESPONSES_UPDATE_MARKS_FAILURE,
  RESPONSES_UPDATE_MARKS_RESET_STORE,
  RESPONSES_UPDATE_FAILURE,
  RESPONSES_UPDATE_RESET_STORE,
  RESPONSES_UPDATE_START,
  RESPONSES_UPDATE_SUCCESS,
  ROUNDS,
  ROUNDS_CREATE,
  ROUNDS_CREATE_FAILURE,
  ROUNDS_CREATE_RESET_STORE,
  ROUNDS_CREATE_START,
  ROUNDS_CREATE_SUCCESS,
  ROUNDS_DESTROY,
  ROUNDS_DESTROY_FAILURE,
  ROUNDS_DESTROY_RESET_STORE,
  ROUNDS_DESTROY_START,
  ROUNDS_DESTROY_SUCCESS,
  ROUNDS_FAILURE,
  ROUNDS_RESET_STORE,
  ROUNDS_SHOW,
  ROUNDS_SHOW_FAILURE,
  ROUNDS_SHOW_RESET_STORE,
  ROUNDS_SHOW_START,
  ROUNDS_SHOW_SUCCESS,
  ROUNDS_START,
  ROUNDS_SUCCESS,
  ROUNDS_UPDATE,
  ROUNDS_UPDATE_FAILURE,
  ROUNDS_UPDATE_RESET_STORE,
  ROUNDS_UPDATE_START,
  ROUNDS_UPDATE_SUCCESS,
  SESSION_LOGIN,
  SESSION_LOGIN_FAILURE,
  SESSION_LOGIN_START,
  SESSION_LOGIN_SUCCESS,
  SESSION_LOGIN_RESET_STORE,
  USERS,
  USERS_CREATE,
  USERS_CREATE_FAILURE,
  USERS_CREATE_RESET_STORE,
  USERS_CREATE_START,
  USERS_CREATE_SUCCESS,
  USERS_SUBSTITUTE,
  USERS_SUBSTITUTE_SUCCESS,
  USERS_SUBSTITUTE_FAILURE,
  USERS_SUBSTITUTE_START,
  USERS_DESTROY,
  USERS_DESTROY_FAILURE,
  USERS_DESTROY_RESET_STORE,
  USERS_DESTROY_START,
  USERS_DESTROY_SUCCESS,
  USERS_FAILURE,
  USERS_RESET_STORE,
  USERS_SHOW,
  USERS_SHOW_FAILURE,
  USERS_SHOW_RESET_STORE,
  USERS_SHOW_START,
  USERS_SHOW_SUCCESS,
  USERS_START,
  USERS_SUCCESS,
  USERS_UPDATE,
  USERS_UPDATE_FAILURE,
  USERS_UPDATE_RESET_STORE,
  USERS_UPDATE_START,
  USERS_UPDATE_SUCCESS,
  CURRICULUM_CODES,
  CURRICULUM_CODES_START,
  CURRICULUM_CODES_SUCCESS,
  CURRICULUM_CODES_FAILURE,
  CURRICULUM_CODES_RESET_STORE,
  CURRICULUM_CODES_CREATE,
  CURRICULUM_CODES_CREATE_START,
  CURRICULUM_CODES_CREATE_SUCCESS,
  CURRICULUM_CODES_CREATE_FAILURE,
  CURRICULUM_CODES_CREATE_RESET_STORE,
} = ActionTypes;
export default ActionTypes;

import React from 'react';
import PropTypes from 'prop-types';
import './IndexTable.scss';
// Components:
import ControlRow from './ControlRow';
import Columns from './Columns';
import Contents from './Contents';
import PagingRow from './PagingRow';

function IndexTable(props) {
  return (
    <div className="index-table">
      <ControlRow {...props} />
      <Columns columns={props.columns} />
      <Contents {...props} />
      <PagingRow {...props} />
    </div>
  );
}

IndexTable.propTypes = {
  model: PropTypes.string,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  search: PropTypes.string,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.object.isRequired,
  pageSizeOptions: PropTypes.array.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleSizeChange: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func,
};

export default IndexTable;

/* eslint-disable no-console */

import { call, put, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';
import { LOGOUT } from '../root/action-types';

function* apiRequest({ payload: { request, action } }) {
  try {
    yield put({ type: `${request}_START` });

    const response = yield call(action);
    yield put({ type: `${request}_SUCCESS`, response });
  } catch (e) {
    const { response } = e;
    const status = get(response, 'status');

    if (status === 401) {
      if (request === 'SESSION_LOGIN') {
        yield put({ type: 'SESSION_LOGIN_FAILURE', response });
      } else {
        yield put({ type: LOGOUT, response });
      }
    } else if (status === 422) {
      yield put({ type: `${request}_FAILURE`, response });
    } else if (status === 404) {
      console.log('404! Oh no!');
    } else if (status === 500) {
      console.log('500!');
    } else {
      throw e;
    }
  }
}

export function* apiWatcher() {
  yield takeEvery('API_REQUEST', apiRequest);
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SimpleButton } from '../../common/';
import SimpleRow from '../../common/SimpleRow/SimpleRow';
import { get } from 'lodash';
import propTypes from 'prop-types';
import Moment from 'moment';
import './ResponseDashboard.scss';
import { TabContent, Conditional, usePapers, useArticles } from '../../common';
import { TopicBar } from './ResponseDashboard';

const INITIAL_SHOWN = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
};

function ResponseCreateTab(props) {
  const { paperForStatus } = usePapers();
  const currentResponse = useSelector((store) => store.responses.current);
  const currentUser = useSelector((store) => store.session.current);
  const { responses } = props;

  const [shownArticles, setShownArticles] = useState(props.shownArticles || INITIAL_SHOWN);
  const [substituteCRQ, setSubstituteCRQ] = useState(false);

  const currentPaper = props.admin ? props.currentPaper : paperForStatus('create_crq');
  const submittedLate = () => {
    if (currentPaper !== 'undefined') {
      return new Date() > new Date(currentPaper.createDeadline);
    }
    return false;
  };
  const { selected } = useArticles(get(currentPaper, 'id'));
  const articles = props.admin ? props.articles : selected;

  const buttonText = () => {
    if (currentResponse.loading) return '...'
    if (!get(currentResponse.data, 'id')) return 'Create'
    if (currentResponse.data.status == 'initial') return 'Continue'
    return 'View'
  }

  const buttonClass = () => {
    const payloadData = get(currentResponse.data, 'data');
    if (currentResponse.loading) return '...';
    if (!payloadData) return 'userCRQ';

    const statuses = ['created', 'answered', 'marked'];
    if (payloadData && statuses.includes(currentResponse.data.status)) return 'stage-completed-CRQ';
  };

  const toggleShown = (article) => setShownArticles((prev) => ({ ...prev, [article]: !prev[article] }));

  const getUserData = (user) => {
    const assigned = user.number == Number(get(articles[currentUser.number], 'number'))
    const userId = get(currentUser, 'id');
    const userEcn = get(currentUser, 'eClubNumber');

    return {
      to: assigned
        ? {
            pathname: '/crq/create',
            state: {
              fromDashboard: true,
              cameFrom: props.match.url,
              submittedLate: submittedLate(),
              userId,
              userEcn,
            },
          }
        : props.match.url,
      title: assigned || shownArticles[user.number] ? get(articles[user.number], 'title', 'show topic') : 'show topic',
      attachment: get(articles[user.number], 'attachment'),
      topicClass: assigned ? 'userCRQ' : '',
      btnClass: assigned ? buttonClass() : '',
      btnText: assigned ? buttonText() : '',
      onClick: !assigned && !shownArticles[user.number] ? () => toggleShown(user.number) : null,
    };
  };

  const adminButtonDetails = (response) => {
    if (!response && substituteCRQ) return ['Substitute CRQ', 'stage-substitute-CRQ'];
    if (!response) return ['Create', 'stage-unstarted-CRQ'];

    const statusesForViewing = ['created', 'answered', 'marked'];
    const statusesForSubstituingCRQ = ['created', 'initial'];

    if (statusesForSubstituingCRQ.includes(get(response, 'status')) && substituteCRQ)
      return ['Substitute CRQ', 'stage-substitute-CRQ'];
    if (statusesForViewing.includes(get(response, 'status'))) return ['View', 'stage-completed-CRQ'];
    if (get(response, 'status') === 'initial') return ['Started', 'stage-in-progress-CRQ'];
  };

  const updatedAtCheck = (response) => {
    let dateFormatted = '-';
    if (get(response, 'savedAt')) {
      dateFormatted = `${Moment(get(response, 'savedAt', ' ')).format('MMMM Do, h:mm:ss a')}`;
    }

    return dateFormatted;
  };

  const getAdminData = (user) => {
    const articleTitle = get(articles, [user.number, 'title'])
    const response = responses.find((r) => r.articleId === get(articles, [user.number, 'id']))
    const updatedAt = `Saved: ${updatedAtCheck(response)}`
    const title = `${user.eClubNumber} - ${user.firstName} ${user.lastName} - ${updatedAt}`

    // This will be true if the user was added to the round after the creation stage for `currentPaper`.
    // If this is the case we don't want to allow them to create CRQs from this row since it does not
    // work with the current implementation of ResponseCreate.
    if (!response && currentPaper.status !== 'create_crq') return { to: '#', title };

    let propResponse = get(response, 'status') !== 'initial' ? response : undefined;
    const [btnText, btnClass] = user ? adminButtonDetails(response) : ['', ''];
    let pathname = `/crq/create/${user.id}`;
    if (btnClass === 'stage-substitute-CRQ') {
      pathname = `/crq/substitute/${user.id}`;
      propResponse = response;
    }
    const to = user
      ? {
          pathname,
          state: {
            response: propResponse,
            articleTitle,
            userId: user.id,
            fromDashboard: true,
            cameFrom: props.match.url,
            submittedLate: submittedLate(),
            paperStatus: currentPaper.status,
            userEcn: user.eClubNumber,
          },
        }
      : props.match.url;

    return {
      to,
      title,
      topicClass: 'userCRQ',
      btnClass,
      btnText,
    };
  };

  const CreateTopics = () => {
    const paperStatus = get(currentPaper, 'status', 'initial');
    const visible = ['create_crq', 'answer_crq', 'mark_crq', 'complete'].includes(paperStatus);

    if (!visible) {
      return 'This stage has not been initiated.';
    }

    const dataFunction = props.admin ? getAdminData : getUserData
    return get(props, 'users', []).map(u =>
      <TopicBar {...dataFunction(u)} key={u.number} prefix={u.number} />
    )
  }

  const substituteText = () => {
    if (substituteCRQ) {
      return 'Cancel';
    }
    return 'Substitute';
  };
  const invertSubtitute = () => setSubstituteCRQ(!substituteCRQ);

  return (
    <TabContent className="response-pane">
      <Conditional if={props.blocked}>
        <h4>You do not have permission to view this tab.</h4>
      </Conditional>
      <Conditional if={!props.blocked}>
        <SimpleRow>
          <h2>{get(currentPaper, 'title')}</h2>
          <div className="empty-divider"></div>
          <Conditional if={props.admin && get(currentPaper, 'status') === 'create_crq'}>
            <SimpleButton className="substitute-button" onClick={invertSubtitute}>
              {substituteText()}
            </SimpleButton>
          </Conditional>
        </SimpleRow>
      </Conditional>

      <CreateTopics />

      <Conditional if={!props.admin && !props.blocked}>
        <div className="crq-guidance">
          When drafting your Constructive Response Question (CRQ), you must remember that your Questions must be:
          <ol>
            <li>
              <b>Very Clear</b> what it is that is being asked.
            </li>
            <li>
              <b>Very Clear</b> what it is that is not being asked.
            </li>
            <li>Reasonable to expect the candidate to complete a satisfactory answer in the 15 minutes allowed.</li>
          </ol>
          At the same time you will also draft an Answer Guidance Sheet (AGS) to which you will be referring to when you
          mark that Question.
        </div>
      </Conditional>
    </TabContent>
  );
}

export default ResponseCreateTab;

ResponseCreateTab.propTypes = {
  responses: propTypes.array,
  shownArticles: propTypes.object,
  articles: propTypes.object,
  match: propTypes.shape({
    url: propTypes.string,
  }),
  users: propTypes.array,
  admin: propTypes.bool,
  blocked: propTypes.bool,
  currentPaper: propTypes.object,
};

import { CRQ_DOWNLOAD } from '../root/action-types';

const INITIAL = {};

const openPDF = (pdf) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = `data:application/pdf;base64,${pdf}`;
  a.target = '_blank';
  a.download = 'CRQ';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const download = (state = INITIAL, action) => {
  switch (action.type) {
    case `${CRQ_DOWNLOAD}_SUCCESS`: {
      openPDF(action.response.data.pdf);
      return state;
    }
    default:
      return state;
  }
};

export default download;

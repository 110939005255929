import React from 'react';
import './SubstituteCRQTable.scss';

const SubstituteCRQTable = ({ data, substituteClick, activated }) => {
  const buttonTheme = (row_id) => {
    return activated === row_id ? 'selected' : 'unselected';
  };

  return (
    <table className="table-background">
      <thead>
        <tr>
          <th>Group</th>
          <th>ECN</th>
          <th>Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr className="default-row" key={row.eClubNumber}>
            <td>{row.groupTitle}</td>
            <td>{row.eClubNumber}</td>
            <td>
              {row.firstName} {row.lastName}
            </td>
            <td className="button-cell">
              <div onClick={() => substituteClick(row)} className={buttonTheme(row.eClubNumber)}>
                Substitute
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  //
};
export default SubstituteCRQTable;

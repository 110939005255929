import React, { Component } from 'react';
import Select from 'react-select';
import propTypes from 'prop-types';

class ControlRow extends Component {
  renderSearch = () => {
    const { handleSearchChange, search } = this.props;
    if (!handleSearchChange) return null;
    return (
      <div className="index-table-search">
        <input type="text" placeholder="search..." value={search} onChange={handleSearchChange} />
      </div>
    );
  };

  renderPageSize = () => {
    const { pageSize, handleSizeChange, pageSizeOptions } = this.props;
    return (
      <div className="pageSize">
        <Select
          classNamePrefix="select"
          className="control-row-page-select"
          value={pageSize}
          onChange={handleSizeChange}
          options={pageSizeOptions}
        />
      </div>
    );
  };

  render = () => (
    <div className="index-table-control-row">
      <div className="control-row-left">{this.renderSearch()}</div>
      <div className="control-row-right">{this.renderPageSize()}</div>
    </div>
  );
}

export default ControlRow;

ControlRow.propTypes = {
  pageSize: propTypes.object.isRequired,
  handleSizeChange: propTypes.func.isRequired,
  pageSizeOptions: propTypes.array.isRequired,
  handleSearchChange: propTypes.func,
  search: propTypes.string,
};

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { uniq, isEqual } from 'lodash';

export const usePapers = () => {
  const currentRound = useSelector((store) => store.rounds.show)
  const { data } = useSelector(s => s.groups.show)
  const firstPaper = parseFloat(data && data.firstPaper) || 0

  const papers = currentRound.papers && currentRound.papers.filter(
    p => p.number >= firstPaper
  )

  const paperForStatus = (status) => {
    if (papers) {
      return papers.find((p) => p.status === status);
    }
  }

  return { papers, paperForStatus }
};

export const useCurrentPaper = () => {
  const { papers } = usePapers();
  const [currentPaperId, setCurrentPaperId] = useState(null)
  const setCurrentPaper = (p) => setCurrentPaperId(p && p.id)
  const currentPaper = papers && papers.find(p => p.id == currentPaperId)

  const getDefaultPaper = (ps = papers) => {
    if (ps) {
      const statuses = uniq(ps.map((p) => p.status));
      let paper = {};
      if (isEqual(statuses, ['initial'])) {
        paper = ps[0];
      } else if (isEqual(statuses, ['complete'])) {
        paper = ps[ps.length - 1];
      } else {
        paper = ps.find((p) => p.status !== 'complete');
      }
      return paper;
    }
  };

  const getDefault = () => {
    setCurrentPaper(getDefaultPaper(papers))
  }

  useEffect(getDefault, [papers && papers.length]);

  return { currentPaper, setCurrentPaper, getDefaultPaper, papers };
};

export default useCurrentPaper;

import { useEffect } from 'react';
import Api from '../../api/call';
import { useSelector } from 'react-redux';
import { GROUPS } from '../../root/action-types';


const useGroups = () => {

  const groups = useSelector((store) => store.groups.index.data);
  const ids = groups.map(item => item.id)

  useEffect(() => {
    if (groups.length === 0) {
      Api({ request: GROUPS });
    }
  }, [groups]);

  return { groups, ids }
}
export default useGroups

import React from 'react';
import Modal from 'react-modal';
import propTypes from 'prop-types';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #41489E',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '470px',
  },
};

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const AdminModal = ({ closeModal, modalIsOpen, children }) => (
  <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
    {children}
  </Modal>
);

export default AdminModal;

AdminModal.propTypes = {
  closeModal: propTypes.func,
  modalIsOpen: propTypes.bool,
  submit: propTypes.func,
  children: propTypes.node,
};

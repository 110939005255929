import { useState, useEffect } from 'react';
import { ROUNDS } from '../../root/action-types';
import { useSelector } from 'react-redux';
import Api from '../../api/call';

export const usePreviousRounds = ({ admin }) => {
  const [rounds, setRounds] = useState([]);
  const { loading, data } = useSelector((store) => store.rounds.index);
  const scope = admin ? 'past_rounds_admin' : 'past_rounds_user';

  useEffect(() => {
    Api({
      request: ROUNDS,
      params: {
        scope: scope,
      },
    });
  }, []);

  useEffect(() => {
    if (loading) return;
    const sorted = data
      .sort((a, b) => {
        return new Date(a.createdAt) < new Date(b.createdAt);
      })
      .map((r) => {
        const papers = r.papers.sort((a, b) => {
          return new Date(a.createdAt) > new Date(b.createdAt);
        });

        return { ...r, papers };
      });

    setRounds(sorted);
  }, [loading, data]);

  return { rounds };
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import Select from 'react-select';

import { useForm, SimpleButton, Status, StatusHandler, Conditional } from '../common/';
import Api from '../api/call';
import { NOTIFICATIONS_CREATE, NOTIFICATIONS_CREATE_RESET_STORE, GROUPS, USERS } from '../root/action-types';

const BLANK = {
  message: '',
  userId: '',
  groupId: '',
  group: false,
  user: false,
  everyone: true,
};

const Create = () => {
  const form = useForm(BLANK);
  const status = useSelector((r) => r.notifications.current);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: NOTIFICATIONS_CREATE_RESET_STORE });
  }, [dispatch]);

  const reset = () => {
    dispatch({ type: NOTIFICATIONS_CREATE_RESET_STORE });
    form.updateForm(BLANK);
  };

  if (status.loading || status.created) {
    return <FormStatus reset={reset} {...status} />;
  }

  return <Form {...form} />;
};

const FormStatus = ({ loading, created, errors, reset }) => (
  <div className="success">
    <Status>
      <StatusHandler loading={loading} errors={errors} action={created} onSuccess="Notification has been sent!" />
    </Status>
    <div className="controls">
      <SimpleButton text="Reset" onClick={reset} />
    </div>
  </div>
);
FormStatus.propTypes = {
  loading: propTypes.bool,
  created: propTypes.bool,
  errors: propTypes.array,
  reset: propTypes.func,
};

const Form = ({ form, updateForm, inputOnChange }) => {
  const toggle = (change) => {
    if (form[change]) {
      return;
    } // do nothing if already checked
    updateForm({
      group: false,
      user: false,
      everyone: false,
      groupId: '',
      userId: '',
      [change]: true,
    });
  };

  function SubmitDisabled() {
    if (!form.message.trim()) return true;
    if (form.group && !form.groupId) return true;
    if (form.user && !form.userId) return true;

    return false;
  }

  const submit = () => {
    Api({ request: NOTIFICATIONS_CREATE, data: form });
  };
  return (
    <div className="notification-form">
      <div className="message">
        <h4>Message</h4>
        <textarea name="message" value={form.message} onChange={inputOnChange} />
      </div>
      <Conditional if={form.group}>
        <GroupSelect updateForm={updateForm} groupId={form.groupId.toString()} />
      </Conditional>
      <Conditional if={form.user}>
        <UserSelect updateForm={updateForm} userId={form.userId.toString()} />
      </Conditional>
      <div className="notification-controls">
        <div className="send-to">
          <h4>Send to</h4>
          <Checkbox label="Everyone" value={form.everyone} onChange={() => toggle('everyone')} />
          <Checkbox label="Group" value={form.group} onChange={() => toggle('group')} />
          <Checkbox label="User" value={form.user} onChange={() => toggle('user')} />
        </div>
        <div className="controls">
          <SimpleButton text="Send" onClick={submit} disabled={SubmitDisabled()} />
        </div>
      </div>
    </div>
  );
};
Form.propTypes = {
  form: propTypes.object,
  updateForm: propTypes.func,
  inputOnChange: propTypes.func,
};

const GroupSelect = ({ groupId, updateForm }) => {
  const { data } = useSelector((r) => r.groups.index);
  const options = data.map((d) => ({ value: d.id, label: d.title }));
  const value = options.find((o) => o.value === groupId);
  const onChange = (option) => updateForm({ groupId: option && option.value });

  useEffect(() => Api({ request: GROUPS }), []);

  return (
    <div className="selector">
      <h4>Group</h4>
      <Select {...{ options, value, onChange }} />
    </div>
  );
};
GroupSelect.propTypes = {
  groupId: propTypes.string,
  updateForm: propTypes.func,
};

const UserSelect = ({ userId, updateForm }) => {
  const { data } = useSelector((r) => r.users.index);
  const options = data.map((d) => ({ value: d.id, label: `${d.firstName} ${d.lastName}` }));
  const value = options.find((o) => o.value === userId);
  const onChange = (option) => updateForm({ userId: option && option.value });

  useEffect(() => Api({ request: USERS, params: { scope: 'all' } }), []);

  return (
    <div className="selector">
      <h4>User</h4>
      <Select {...{ options, value, onChange }} />
    </div>
  );
};
UserSelect.propTypes = {
  userId: propTypes.string,
  updateForm: propTypes.func,
};

const Checkbox = ({ label, ...props }) => (
  <div className="checkbox">
    <input type="checkbox" checked={!!props.value} {...props} />
    <label>{label}</label>
  </div>
);
Checkbox.propTypes = {
  label: propTypes.string,
  value: propTypes.any,
};

const Wrapper = () => (
  <div className="notifications-container">
    <div className="notifications-sub-title">Notification</div>
    <div className="notifications-section">
      <Create />
    </div>
  </div>
);

export default Wrapper;

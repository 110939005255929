const INITIAL_STATE = {
  files: {},
};

export const attachment = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ATTACHMENT_DOWNLOAD_START':
      return { ...state, loading: true };
    case 'ATTACHMENT_DOWNLOAD_SUCCESS':
      return {
        ...state,
        loading: false,
        files: { ...state.files, [action.response.id]: action.response },
      };
    case 'ATTACHMENT_DOWNLOAD_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.response,
      };
    default:
      return state;
  }
};

const INITIAL_SEARCH = { files: [] };

export const dropboxSearch = (state = INITIAL_SEARCH, action) => {
  switch (action.type) {
    case 'DROPBOX_SEARCH_START':
    case 'DROPBOX_LIST_START':
      return { ...INITIAL_SEARCH, loading: true };
    case 'DROPBOX_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        files: action.response.entries,
        more: action.response.has_more,
      };
    case 'DROPBOX_SEARCH_SUCCESS':
      return {
        ...state,
        loading: false,
        files: action.response.matches,
        more: action.response.more,
      };
    case 'DROPBOX_LIST_FAILURE':
    case 'DROPBOX_SEARCH_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.response,
      };
    default:
      return state;
  }
};

const INITIAL_DROPBOX = {};

export const dropbox = (state = {}, action) => {
  switch (action.type) {
    case 'DROPBOX_REQUEST_START':
      return { ...INITIAL_DROPBOX, loading: true };
    case 'DROPBOX_REQUEST_SUCCESS':
      return { ...state, loading: false, response: action.response };
    case 'DROPBOX_REQUEST_FAILURE':
      return { ...state, loading: false, error: action.response };
    default:
      return state;
  }
};

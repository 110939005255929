import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Api from '../api/call';
import { ARTICLES } from '../root/action-types';
import './Articles.scss';
// Components
import Pane from '../common/Pane/Pane';
import SimpleRow from '../common/SimpleRow/SimpleRow';

function Articles() {
  useEffect(() => Api({ request: ARTICLES }), []);

  const Article = (item) => (
    <SimpleRow>
      <li className="simple-item">
        <div>FileIcon</div>
        <div className="article-title">{item.title}.pdf</div>
      </li>
      <button>
        <div>...</div>
      </button>
    </SimpleRow>
  );

  const { data } = useSelector((store) => store.articles.index);
  const Index = () => {
    const index = data.map((item) => <Article {...item} key={item.id} />);
    return <ul className="simple-index">{index}</ul>;
  };

  return (
    <Pane>
      <h2>ARTICLE BANK</h2>
      <div className="add-section">
        <div className="icon">+</div>
        <div>Upload Articles</div>
      </div>
      <input className="search" placeholder="search..."></input>
      <Index />
    </Pane>
  );
}

export default Articles;

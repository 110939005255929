import React from 'react';
import SimpleRow from '../common/SimpleRow/SimpleRow';
import './ResourceTable.scss';
import { AttachmentLink } from '../attachment/Attachment';

const ResourceTable = ({ data }) => {
  return (
    <div className="resources-table">
      {data.map((row) => (
        <SimpleRow key={row.id}>
          <div className="row">
            <span>{row.name}</span>
            <span className="timestamp hidden">{new Date(row.client_modified).toUTCString()}</span>
          </div>
          <AttachmentLink path={row.id}>Download</AttachmentLink>
        </SimpleRow>
      ))}
    </div>
  );
};
export default ResourceTable;

import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMarkedResponses } from '../../common';

import './MarksIndex.scss';

const EmptyIndex = () => <h4>You don&apos;t currently have any marked papers available to view.</h4>;

const MarksAchieved = (props) => {
  const { questions } = props.item.data;
  const { id } = useSelector((r) => r.session.current);

  const fakeMarked = get(props.item, `data.questions[0].userAnswers[${id}].fakeMarked`, false);
  let awarded = 0;
  let total = 0;

  questions.forEach((item) => {
    if (item.name === 'scenario' || item.name === 'clinical') return null;

    awarded += get(item, `userAnswers[${id}].awarded[0].value`, 0);
    total += get(item, 'marks.value', 0);
  });

  if(fakeMarked){
    return <div className="total-marks">Marking incomplete</div>;
  }
  return <div className="total-marks">{`${awarded} / ${total} marks`}</div>;
};

const ListItem = (item) => {
  return (
    <Link className="list-item" key={item.response.id} to={{ pathname: `/crq/marked/${item.response.id}` }}>
      <div className="title">{`${item.paperTitle} - Question ${item.userNumber} - ${item.crqTopic}`}</div>
      <MarksAchieved item={item.response} />
    </Link>
  );
};

const MarksIndex = () => {
  const { releasedResponses } = useMarkedResponses();
  const articles = useSelector((store) => store.articles.index.data);
  const { papers } = useSelector((store) => store.rounds.show);

  if (get(releasedResponses, 'length', 0) < 1) return <EmptyIndex />;

  return (
    <div className="marks-index">
      <h2>Marked Papers</h2>
      {releasedResponses
        .sort(({ response: aResp }, { response: bResp }) => {
          const aArt = articles.find((a) => a.id === aResp.articleId);
          const bArt = articles.find((b) => b.id === bResp.articleId);

          const aPaper = papers.find((a) => a.id === aArt.paperId);
          const bPaper = papers.find((b) => b.id === bArt.paperId);

          if (new Date(aPaper.createdAt) > new Date(bPaper.createdAt)) return -1;
          if (new Date(aResp.createdAt) > new Date(bResp.createdAt)) return 1;
        })
        .reverse().map((item, index) => ListItem(item, index))}
    </div>
  );
};

export default MarksIndex;

MarksAchieved.propTypes = {
  item: propTypes.object,
};

import React, { useContext } from 'react'
import { QuestionsContext } from '../ResponseCreate/question-manager'
import { letter } from '../utils'

export const Prefix = ({ idx }) => {
  const { data } = useContext(QuestionsContext)

  const prefix = letter(
    data.slice(0, idx + 1).filter(d => !d.name.match(/clinical|scenario/)).length
  )

  return <div className="prefix">{prefix}</div>
}

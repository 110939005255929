import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import Api from '../../api/call';
import { USERS, USERS_DESTROY, USERS_UPDATE, USERS_DESTROY_RESET_STORE } from '../../root/action-types';
import { Link } from 'react-router-dom';
import { Conditional, Status, StatusHandler } from '../';
// Assets:
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

export const UserActions = ({ data, actions }) => {
  const { confirm, setConfirm } = actions;
  const { destroyed, loading, errors } = useSelector((s) => s.users.user);
  const dispatch = useDispatch();
  const [penalties, setPenalties] = useState(data.penalties);
  const [blocked, setBlocked] = useState(data.blocked);
  const [locked, setLocked] = useState(true);
  const [penaltyMessage, setPenaltyMessage] = useState(data.penaltyMessage);
  const [confirmBlock, setConfirmBlock] = useState(false);

  const handlePenaltiesClick = (v) => {
    const x = v - 1;
    if(penalties === x) return setPenalties(v);
    setPenalties(x);
  };

  const lock = () => {
    setLocked(true);
    setPenalties(data.penalties);
  };

  const block = () => {
    Api({
      request: USERS_UPDATE,
      id: data.id,
      data: { blocked: !blocked },
    });
    setConfirmBlock(false);
    setBlocked(!blocked);
  };

  const confirmPenalty = () => {
    Api({
      request: USERS_UPDATE,
      id: data.id,
      data: { penalties, penaltyMessage },
    });
    setLocked(true);
  };

  const onDismount = () => {
    return () => {
      dispatch({ type: USERS_DESTROY_RESET_STORE });
      Api({ request: USERS });
    };
  };

  useEffect(onDismount, []);

  const RenderStatus = () => {
    return (
      <Status isError={errors}>
        <StatusHandler loading={loading} errors={errors} action={destroyed} onSuccess="User deleted" />
      </Status>
    );
  };

  const destroy = () => {
    Api({ request: USERS_DESTROY, id: data.id });
    setConfirm(false);
  };

  const handleMessageChange = (event) => {
    setPenaltyMessage(event.target.value);
  };

  const blockText = () => (blocked ? 'Unblock' : 'Block');

  return (
    <div className="actions">
      <RenderStatus />
      <h1>{`${data.firstName} ${data.lastName}`}</h1>
      <div className="buttons">
        <Link to={`/users/${data.id}/edit`}>Edit</Link>
        <Conditional if={!confirmBlock}>
          <button className="delete" onClick={() => setConfirmBlock(true)}>
            {blockText()}
          </button>
        </Conditional>
        <Conditional if={confirmBlock}>
          <div className="confirm">
            <div>{`Are you sure you want to ${blockText()} this user?`}</div>
            <button className="delete" onClick={block}>
              {blockText().toUpperCase()}
            </button>
            <button onClick={() => setConfirmBlock(false)}>CANCEL</button>
          </div>
        </Conditional>
        <Conditional if={!confirm}>
          <button className="delete" onClick={() => setConfirm(true)}>
            Delete
          </button>
        </Conditional>
        <Conditional if={confirm}>
          <div className="confirm">
            <div>Are you sure you want to delete?</div>
            <button className="delete" onClick={destroy}>
              DELETE
            </button>
            <button onClick={() => setConfirm(false)}>CANCEL</button>
          </div>
        </Conditional>
      </div>
      <div className="penalties">
        <div>Penalty Points</div>
        <input type="checkbox" onClick={()=> handlePenaltiesClick(1)} checked={penalties > 0} disabled={penalties > 1 || locked} />
        <input type="checkbox" onClick={()=> handlePenaltiesClick(2)} checked={penalties > 1} disabled={penalties == 0 || penalties == 3 || locked} />
        <input type="checkbox" onClick={()=> handlePenaltiesClick(3)} checked={penalties > 2} disabled={penalties < 2 || locked } />
        {locked ? (
          <FontAwesomeIcon icon={faLock} onClick={() => setLocked(false)} />
        ) : (
          <FontAwesomeIcon icon={faUnlock} onClick={lock} />
        )}
      </div>
      {locked ? null : (
        <textarea
          className="penaltyMessage"
          type="text"
          rows="3"
          name="name"
          onChange={handleMessageChange}
          placeholder="Message for Member"
          defaultValue={data.penaltyMessage}
        />
      )}
      {locked ? null : (
        <button className="penalize simple-button primary-button" onClick={confirmPenalty}>
          Save Points
        </button>
      )}
    </div>
  );
};

UserActions.propTypes = {
  data: propTypes.object,
  actions: propTypes.object,
};

import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash';
import propTypes from 'prop-types';
import { QuestionsContext } from '../../response/ResponseCreate/question-manager';

// Components:
import { Pane } from '../../common/';
import ReactQuill from 'react-quill';
import { formats, toolbarModules, buildEvent } from '../../common/RichText/Constants';
import { Prefix } from '../QuestionTypes/Prefix'
import { RESPONSES_CLEAR_ERROR } from '../../root/action-types'
// Styles:
import 'react-quill/dist/quill.snow.css';
import './AnswerGuidance.scss';
import '../../common/RichText/RichText.scss';

function AnswerGuidance(props) {
  const { dispatch, data } = React.useContext(QuestionsContext)
  const reduxDispatch = useDispatch()
  const { errorData } = useSelector(r => r.responses.responseStatus)
  const errors = get(errorData, 'ags', [])
  const clearError = (field) => {
    reduxDispatch({ type: RESPONSES_CLEAR_ERROR, section: 'ags', field })
  }

  return (
    <Pane>
      <div className="answer-guidance-header">
        <h2>Answer Guidance Sheet</h2>
        {props.children}
      </div>
      {data.map((d, idx) =>
        <Item
          {...props}
          item={d}
          idx={idx}
          key={idx}
          error={errors.includes(idx)}
          dispatch={dispatch}
          clearError={clearError}
        />
      )}
    </Pane>
  )
}

const Item = ({ item, idx, ...props }) => {
  if (item.name === 'scenario') {
    return <Scenario key={idx} item={item} />;
  }
  if (item.name === 'clinical') {
    return null;
  }

  return (
    <AnswerBox
      dispatch={props.dispatch}
      clearError={props.clearError}
      error={props.error}
      key={idx}
      idx={idx}
      item={item}
      disabled={props.disabled}
      submitted={props.submitted}
    />
  )
}

const Scenario = (props) => (
  <div className={props.item.confirmed ? 'scenario-container ags' : 'scenario-container active'}>
    <ReactQuill theme="snow" modules={{ toolbar: false }} value={props.item.field} readOnly={true} />
  </div>
);

const AnswerBox = ({ dispatch, ...props }) => {
  const buildGuidanceStyles = () => {
    if (props.submitted || props.disabled) return 'read-only';
    return 'edit-text';
  };

  const buildAnswerStyles = () => {
    if (props.submitted || props.disabled) return 'read-only';
    if (props.error) return 'errors';

    return 'edit-text';
  };

  const onChangeEvent = (name, editor) => {
    if (!dispatch) return; // is the case in ResponseEmpty
    const event = buildEvent(name, editor);
    dispatch({
      type: 'update',
      index: props.idx,
      name: event.currentTarget.name,
      value: event.currentTarget.value,
    });
    props.error && props.clearError(props.idx)
  };

  const answerStyles = buildAnswerStyles();
  const guidanceStyles = buildGuidanceStyles();

  return (
    <div className="answer-box">
      <div className="question-title">
        <div className="title">
          <Prefix idx={props.idx} />
          <span className="question">
            <ReactQuill theme="snow" modules={{ toolbar: false }} value={props.item.field} readOnly={true} />
          </span>
        </div>
        <div className="marks">{props.item.marks.label}</div>
      </div>
      <table cellSpacing="0">
        <thead>
          <tr>
            <th>Answer</th>
            <th>Guidance</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ReactQuill
                className={answerStyles}
                theme="snow"
                modules={toolbarModules(props.submitted || props.disabled)}
                formats={formats}
                value={props.item.answer || ''}
                placeholder="Answer your question here"
                onChange={(content, delta, source, editor) => onChangeEvent('answer', editor)}
                readOnly={props.submitted || props.disabled}
              />
            </td>
            <td>
              <ReactQuill
                className={guidanceStyles}
                theme="snow"
                modules={toolbarModules(props.submitted || props.disabled)}
                formats={formats}
                value={props.item.guidance || ''}
                placeholder="Guidance for your question"
                onChange={(content, delta, source, editor) => onChangeEvent('guidance', editor)}
                readOnly={props.submitted || props.disabled}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AnswerGuidance;

AnswerGuidance.propTypes = {
  children: propTypes.element,
  data: propTypes.array,
  update: propTypes.func,
  item: propTypes.shape({
    answer: propTypes.string,
    guidance: propTypes.string,
    field: propTypes.string,
    text: propTypes.string,
    confirmed: propTypes.bool,
    marks: propTypes.object,
    type: propTypes.string,
    name: propTypes.string,
    table: propTypes.any,
  }),
  idx: propTypes.number,
  disabled: propTypes.bool,
  submitted: propTypes.bool,
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { get, last } from 'lodash';

// Components:
import  useGroups from '../../common/Hooks/useGroups';
import { Pane, ReturnButton, useCurrentPaper, useCurrentRound } from '../../common/';
import ResponseDashboard from '../../response/ResponseDashboard/ResponseDashboard';
import PaperPicker from '../../paper/PaperPicker';

function GroupsShow(props) {
  // Urggh - useCurrentRound makes a necessary API call :/
  useCurrentRound({ include_status: false })
  const { ids } = useGroups();
  const [ groupId, setGroupId ] = useState(parseInt(get(props, 'match.params.id', 1)))
  const group = useSelector((store) => store.groups.show.data);
  const { currentPaper, setCurrentPaper, papers } = useCurrentPaper();

  const moveUp = () => {
    const targetGroupId = ids[ids.indexOf(groupId) - 1]
    if(targetGroupId === undefined){
      setGroupId(last(ids))
    } else {
      setGroupId(targetGroupId)
    }
  }

  const moveDown = () => {
    const targetGroupId = ids[ids.indexOf(groupId) + 1]
    if(targetGroupId === undefined){
      setGroupId(ids[0])
    } else {
      setGroupId(targetGroupId)
    }
  }

  return (
    <Pane theme="show-group">
      <ReturnButton to="/groups" text="Return to Groups" />
      <div className="dashboard-papers">
        <div className="round-date">
          <div className="title-with-arrows">
            <div className="title">
              {get(group, 'title')}
            </div>
            <div className="arrows">
              <FontAwesomeIcon onClick={moveUp} icon={faChevronUp} />
              <FontAwesomeIcon onClick={moveDown} icon={faChevronDown} />
            </div>
          </div>
        </div>
        <div className="papers-list papers-list--groups">
          <PaperPicker currentPaper={currentPaper} setCurrentPaper={setCurrentPaper} papers={papers} />
        </div>
      </div>
      <div className="show">
        <ResponseDashboard groupId={groupId} {...props} admin={true} currentPaper={currentPaper} />
      </div>
    </Pane>
  );
}

export default GroupsShow;

import { combineReducers } from 'redux'

import { EMAIL_LOGS } from '../root/action-types'

const DEFAULT_COLLECTIONS_STATE = { data: [], total: 0, count: 0 }

const index = (state = DEFAULT_COLLECTIONS_STATE, action) => {
  switch (action.type) {
    case `${EMAIL_LOGS}_START`:
      return {
        ...state,
        loading: true,
      }
    case `${EMAIL_LOGS}_SUCCESS`:
      return {
        ...state,
        ...action.response.data,
        loading: false,
      }
    case `${EMAIL_LOGS}_FAILURE`:
      return {
        ...state,
        errors: action.response.data.errors,
        loading: false,
      }
    case `${EMAIL_LOGS}_RESET_STORE`:
      return DEFAULT_COLLECTIONS_STATE
    default:
      return state
  }
}

export default combineReducers({
  index,
})


export const modalDefaultStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #41489E',
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
  },
};

export const buildEvent = (name, editor) => {
  const event = {
    currentTarget: {
      name: name,
      value: editor.getHTML(),
    },
  };
  // the editor returns 1 when empty instead of 0, for some reason
  if (editor.getText().length == 1) {
    event.currentTarget.value = '';
  }
  return event;
};

const toolbarOptions = [
  ['bold', 'italic', 'underline'],
  [{ script: 'sub' }, { script: 'super' }],
];

export const toolbarModules = (confirmed) => ({ toolbar: confirmed ? false : toolbarOptions });

export const formats = ['bold', 'italic', 'underline', 'script'];

import React from 'react';
import { NavLink } from 'react-router-dom';

import './CurrentRound.scss';

function TopicBarCreate({ prefix, title, btnClass, btnText, to, disabled }) {
  return (
    <div className="topic-bar cursor-default">
      <div className="topic-prefix">{prefix}</div>
      <div className="topic-title">{title}</div>
      <NavLink className={`topic-link ${btnClass} ${disabled ? 'disabled' : ''}`} to={to}>
        {btnText}
      </NavLink>
    </div>
  );
}

function TopicBarAnswerMark({ prefix, title, questions }) {
  return (
    <div className="topic-bar cursor-default">
      <div className="topic-prefix">{prefix}</div>
      <div className="topic-title">{title}</div>
      {questions.map((q, i) => (
        <NavLink key={i} className={`topic-link ${q.btnClass} ${q.disabled ? 'disabled' : ''}`} to={q.to}>
          {q.btnText}
        </NavLink>
      ))}
    </div>
  );
}

function TopicBar({ tab, ...props }) {
  switch (tab) {
    case 'create':
      return <TopicBarCreate {...props} />;
    case 'answer':
      return <TopicBarAnswerMark {...props} />;
    case 'mark':
      return <TopicBarAnswerMark {...props} />;
  }
}

function CRQTable({ tab, paperTitle, tableData = [] }) {
  const tableBody = () => {
    if (typeof tableData === 'object') {
      return tableData.map((td) => (
        <TopicBar
          tab={tab}
          key={td.name}
          prefix={td.number}
          title={[td.group, td.name].join(' - ')}
          {...td.responseInfo}
        />
      ));
    }

    return <div className="current-round-no-data">{tableData}</div>;
  };

  return (
    <div className="current-round-table-container">
      <h1>{paperTitle}</h1>
      <div className="current-round-table">{tableBody()}</div>
    </div>
  );
}

export default CRQTable;

import React from 'react';
import { get } from 'lodash';
import propTypes from 'prop-types';

import { CreateCRQ, AnswerCRQ, MarkCRQ } from '../../root/assets/';

function CRQTasks({ openModal, paperForStatus, currentStatus }) {

  const canProgress = currentStatus?.canProgress
  const canForceProgress = currentStatus?.canForceProgress
  const progressButtonDisabled = !(canProgress || canForceProgress)

  const checkStageComplete = (paperStatus) => {
    const paper = paperForStatus(paperStatus);
    if ( paper === undefined ) return false
    if (paperStatus === 'create_crq') return currentStatus?.createDone;
    if (paperStatus === 'answer_crq') return currentStatus?.answerDone;
    if (paperStatus === 'mark_crq') return currentStatus?.markDone;
  };

  const createClassName = (status) => {
    const classes = ['crq-button'];
    const paper = paperForStatus(status);
    if (paper) classes.push('active');
    if (checkStageComplete(status)) classes.push('complete');

    return classes.join(' ');
  };

  const getPaperTitle = (status) => {
    const paper = paperForStatus(status);
    return get(paper, 'title', '-');
  };

  return (
    <>
      <div className="crq-tasks">
        <div className={createClassName('create_crq')}>
          <img src={CreateCRQ} alt="create-crq" />
          <div className="crq-description">
            <div>Create CRQ/AGS</div>
            <div>
              <b>{getPaperTitle('create_crq')}</b>
            </div>
          </div>
        </div>
        <div className={createClassName('answer_crq')}>
          <img src={AnswerCRQ} alt="answer-crq" />
          <div className="crq-description">
            <div>Answer</div>
            <div>
              <b>{getPaperTitle('answer_crq')}</b>
            </div>
          </div>
        </div>
        <div className={createClassName('mark_crq')}>
          <img src={MarkCRQ} alt="mark-crq" />
          <div className="crq-description">
            <div>Mark</div>
            <div>
              <b>{getPaperTitle('mark_crq')}</b>
            </div>
          </div>
        </div>
      </div>
      <div className="progress-container">
        <button className={canForceProgress ? "forced": "crq-next-stage-btn"} onClick={openModal} disabled={progressButtonDisabled}>
          PROGRESS TO NEXT STAGE
        </button>
      </div>
    </>
  );
}

export default CRQTasks;

CRQTasks.propTypes = {
  status: propTypes.string,
  openModal: propTypes.func,
  paperForStatus: propTypes.func,
};

import React, { useState } from 'react';
import QuestionPicker from '../QuestionPicker/QuestionPicker';
import propTypes from 'prop-types';
// Assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

export const Picker = ({ toggle, questions, index }) => {
  const [open, setOpen] = useState();
  const count = questions.data.length - 1;
  const { picker, submitted } = toggle.form;
  const dividerProps = { count, index, open, setOpen };

  const addQuestion = (event) => {
    questions.dispatch({
      type: 'add',
      index: index,
      name: event.currentTarget.name,
    });
    toggle.updateForm({ picker: { [index]: false } });
    setOpen(null);
  };

  const toggler = () => toggle.updateForm({ picker: { [index]: !picker[index] } });

  const Pick = () => (
    <QuestionPicker
      setToggle={toggler}
      toggle={toggle.form.picker[index] || false}
      questions={questions}
      addQuestion={addQuestion}
    />
  );

  if (submitted || questions.isQuestionActive) return null;
  if (index === 'undefined') return <Pick />;

  return (
    <Divider {...dividerProps}>
      <Pick />
    </Divider>
  );
};
Picker.propTypes = {
  questions: propTypes.object,
  toggle: propTypes.object,
  index: propTypes.number,
};

const Divider = ({
  index,
  open,
  setOpen,
  children
}) => {
  let icon = faPlus;
  let toggle = () => setOpen(index);

  if (open === index) {
    toggle = () => setOpen(null);
    icon = faMinus;
  }

  if (typeof index === 'undefined') return children;
  return (
    <>
      <div className="divide-picker">
        <div className="dotted" />
        <button onClick={toggle}>
          <FontAwesomeIcon icon={icon} />
        </button>
        <div className="dotted" />
      </div>
      {open === index ? children : null}
    </>
  );
};

Divider.propTypes = {
  index: propTypes.number,
  count: propTypes.number,
  children: propTypes.node,
  open: propTypes.number,
  setOpen: propTypes.func,
};

import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Api from '../../api/call';
import propTypes from 'prop-types';
import { CURRENT_USER, CONFIG, CURRENT_RESPONSE, RESET_CURRENT_RESPONSE, LOGOUT } from '../../root/action-types';
import { Conditional } from '../../common/';
import './TopBar.scss';
// Images:
import Logo from '../../root/assets/white-logo.svg';

const TopBar = () => {
  const { email, blocked } = useSelector((store) => store.session.current);
  const dispatch = useDispatch();
  const logout = () => dispatch({ type: LOGOUT });

  const resetCurrentResponse = () => {
    Api({ request: RESET_CURRENT_RESPONSE });
  };

  useEffect(() => {
    Api({ request: CURRENT_USER });
    Api({ request: CONFIG });
    Api({ request: CURRENT_RESPONSE });
  }, []);

  return (
    <>
      <div className="topbar">
        <div className="container">
          <NavLink to="/">
            <img src={Logo} alt="msa" />
          </NavLink>
          <div className="user-container">
            <button className="simple-button muted" onClick={resetCurrentResponse}>
              Reset CRQ
            </button>
            <div>{email}</div>
            <button className="logout" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>
      <Conditional if={blocked}>
        <BlockBanner />
      </Conditional>
    </>
  );
};

const BlockBanner = () => (
  <div className="block-banner">
    <div>Your account is currently blocked.</div>
    <div>
      {'For more information, contact e-club@msoa.org.uk. You can still view your '}
      <NavLink
        to={{
          pathname: 'crq/dashboard',
          state: { position: 3 },
        }}
      >
        {' past Marked Papers.'}
      </NavLink>
    </div>
  </div>
);

export default TopBar;

TopBar.propTypes = {
  current: propTypes.shape({
    id: propTypes.number,
    firstName: propTypes.string,
    lastName: propTypes.string,
    email: propTypes.string,
    dispatch: propTypes.func.isRequired,
  }),
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';

import './CurrentRound.scss';
// Components:
import Pane from '../common/Pane/Pane';
import StatusSelector from './StatusSelector';
import CRQTable from './CRQTable';
import PaperPicker from '../paper/PaperPicker';
import { GROUPS, RESPONSES } from '../root/action-types';
import Api from '../api/call';

import { useCurrentRound, TabGroup, useCurrentPaper, Loading } from '../common';

function useGroupsAndResponses() {
  useEffect(() => {
    Api({ request: GROUPS });
    Api({ request: RESPONSES });
  }, []);

  const { data: groups, loading: groupsLoading } = useSelector((store) => store.groups.index);
  const { data: responses, loading: responsesLoading } = useSelector((store) => store.responses.index);

  return { groups, groupsLoading, responses, responsesLoading };
}

// FIXME: a lot of this is copied and modified from Response*Tab
function CurrentRound() {
  const [responseFilter, setResponseFilter] = useState('AllUsers');
  const [search, setSearch] = useState('');
  const { round, loading } = useCurrentRound({ include_status: false, include_responses: false });
  const { groups, groupsLoading, responses, responsesLoading } = useGroupsAndResponses();
  const { currentPaper, setCurrentPaper, papers } = useCurrentPaper();

  const [createData, setCreateData] = useState();
  const [answerData, setAnswerData] = useState();
  const [markData, setMarkData] = useState();

  const tabLables = ['CREATE CRQ/AGS', 'ANSWER', 'MARK'];

  const _onNumber = (a, b) => a.number - b.number;

  const _filterData = (users) => {
    if (typeof users == 'string') return users;
    const data = responseFilter == 'AllUsers' ? users : users.filter((u) => u.responseInfo.status == responseFilter)

    return data.length ? data : 'No matches found.';
  };

  const _searchData = (users, search = '') => {
    if (typeof users == 'string') return users;
    const matchOn = (search.match(/[\w \+ \. \@ ]/gi) || []).join('').toLowerCase();
    if (!matchOn) return users;

    const data = users.filter((u) => {
      const numberMatch = get(u, 'number', '').match(matchOn);
      const groupMatch = get(u, 'group', '').toLowerCase().match(matchOn);
      const nameMatch = get(u, 'name', '').toLowerCase().match(matchOn);
      const ecnMatch = get(u, 'ecn', '').toLowerCase().match(matchOn);
      const emailMatch = get(u, 'email', '').toLowerCase().includes(matchOn);

      return numberMatch || groupMatch || nameMatch || emailMatch || ecnMatch;
    });

    return data.length ? data : 'No matches found.';
  };

  const _findCurrentResponse = (user) => {
    const article = get(currentPaper, 'articles', []).find((a) => a.number == user.number);
    // `stubResponse` just contains top-level response data (i.e. no question data)
    const stubResponse = get(article, 'responses', []).find((r) => r.userId == user.id);
    // `response` also contains *all* the response data, within a `data` field
    const response = (responses || []).find((r) => r.id == get(stubResponse, 'id'));

    return { response, article };
  };

  const parseResponseCreate = (user) => {
    const { response, article } = _findCurrentResponse(user);
    // Disabled if there's no article assigned OR there's no response
    // and we're not at the create stage for this paper:
    const disabled = !article || (!response && get(currentPaper, 'status') != 'create_crq');

    const to = {
      pathname: `/crq/create/${user.id}`,
      state: {
        response: response,
        articleTitle: get(article, 'title'),
        userId: get(user, 'id'),
        fromDashboard: true,
        cameFrom: '/currentRound',
        submittedLate: new Date() > new Date(get(currentPaper, 'createDeadline')),
        paperStatus: get(currentPaper, 'status'),
        userEcn: get(user, 'eClubNumber'),
      },
    };

    const data = {
      to,
      btnClass: 'userCRQ',
      btnText: 'Create CRQ',
      status: 'NotStarted',
      disabled,
    };

    if (!response) return data;

    const [btnClass, btnText, status] = ['created', 'answered', 'marked', 'redistributed'].includes(response.status)
      ? ['stage-completed-CRQ', 'View CRQ', 'Submitted']
      : ['stage-in-progress-CRQ', 'In Progress', 'InProgress'];

    return { btnClass, btnText, to, status };
  };

  const parseResponseAnswer = (user, group) => {
    const questions = get(group, 'users', [])
      .filter((u) => u.id != user.id)
      .map((u) => {
        const { response } = _findCurrentResponse(u);

        const answeredBy = get(response, 'data.answeredBy', []);
        const answered = answeredBy.includes(get(user, 'id'));
        // Disabled if there's no response OR they haven't answered
        // and we're not at the answer stage for this paper:
        const disabled = !response || (!answered && get(currentPaper, 'status') != 'answer_crq');
        const btnClass = answered ? 'stage-completed-CRQ' : 'stage-unstarted-CRQ';
        const btnText = `U #${get(u, 'number')}`

        return {
          to: {
            pathname: '/crq/answer',
            state: {
              uid: get(user, 'id'),
              responseId: get(response, 'id'),
              admin: true,
              paperStatus: get(currentPaper, 'status'),
              submittedLate: new Date() > new Date(get(currentPaper, 'answerDeadline')),
            },
          },
          btnClass,
          btnText,
          disabled,
        };
      });

    // for the progression modal to work we need unanswered questions
    const questionsWithUnanswered = questions.map((q) => {
      const filteredQuestions = questions.filter( (q) => q.btnClass != 'stage-completed-CRQ' ).map((q) =>{
        return { ...q.to }
      })
      return { ...q, to: { ...q.to, state: { ...q.to.state, unanswered: filteredQuestions } } }
    })

    const status = questionsWithUnanswered.every((q) => q.btnClass == 'stage-unstarted-CRQ')
      ? 'NotStarted'
      : questionsWithUnanswered.every((q) => q.btnClass == 'stage-completed-CRQ')
      ? 'Submitted'
      : 'InProgress';

    return { questions: questionsWithUnanswered, status };
  };

  const parseResponseMark = (user, group) => {
    const { response } = _findCurrentResponse(user);
    const questions = get(group, 'users', [])
      .filter((u) => u.id != user.id)
      .map((u) => {
        const markedFor = get(response, 'data.markedFor', []);
        const answeredBy = get(response, 'data.answeredBy', []);
        const marked = markedFor.includes(get(u, 'id'));
        const answered = answeredBy.includes(get(u, 'id'));
        // Disabled if there's no response OR they haven't marked
        // and we're not at the mark stage for this paper OR there
        // are no answers to be marked:
        const disabled = !response || (!marked && get(currentPaper, 'status') != 'mark_crq') || !answered;
        const data = get(response, 'data', {});
        const started = get(data, 'questions', []).some((q) => {
          if (get(data, 'finalComment', {})[get(u, 'id')]) return true;
          if (q.hasOwnProperty('userAnswers')) {
            const ua = q.userAnswers[get(u, 'id')];
            return (
              get(ua, 'comment') ||
              get(ua, 'awarded', []).some((a) => ![-1, null, undefined].includes(a.value) && a.value >= 0)
            );
          }
        });
        const btnClass = marked ? 'stage-completed-CRQ' : started ? 'stage-in-progress-CRQ' : 'stage-unstarted-CRQ';
        const btnText = `U #${get(u, 'number')}`;

        return {
          to: {
            pathname: '/crq/mark',
            state: {
              responseId: get(response, 'id'),
              uid: get(u, 'id'),
              paperStatus: get(currentPaper, 'status'),
              ecn: get(user, 'eClubNumber', '----'),
              submittedLate: new Date() > new Date(get(currentPaper, 'markDeadline')),
            },
          },
          btnClass,
          btnText,
          disabled,
        };
      });

    // for the progression modal to work we need unmarked questions
    const questionsWithUnmarked = questions.map((q) => {
      const filteredQuestions = questions.filter( (q) => q.btnClass != 'stage-completed-CRQ' ).map((q) =>{
        return { ...q.to }
      })
      return { ...q, to: { ...q.to, state: { ...q.to.state, unmarked: filteredQuestions } } }
    })

    const status = questionsWithUnmarked.every((q) => q.btnClass == 'stage-unstarted-CRQ')
      ? 'NotStarted'
      : questionsWithUnmarked.every((q) => q.btnClass == 'stage-completed-CRQ')
      ? 'Submitted'
      : 'InProgress';

    return { questions: questionsWithUnmarked, status };
  };

  const getCreateData = () => {
    const ready = get(currentPaper, 'status') != 'initial';

    if (ready) {
      return (groups || []).flatMap((g) =>
        get(g, 'users', [])
          .sort(_onNumber)
        .map((u) => (
          {
            number: `#${u.number}`,
            group: g.title,
            name: [u.firstName, u.lastName].join(' '),
            ecn: u.eClubNumber,
            email: u.email,
            responseInfo: parseResponseCreate(u),
          }
        ))
      );
    }

    return 'This stage has not been initiated.';
  };

  const getAnswerData = () => {
    const ready = ['answer_crq', 'mark_crq', 'complete'].includes(get(currentPaper, 'status'));

    if (ready) {
      return (groups || []).flatMap((g) =>
        get(g, 'users', [])
          .sort(_onNumber)
          .map((u) => ({
            number: `#${u.number}`,
            group: g.title,
            name: [u.firstName, u.lastName].join(' '),
            ecn: u.eClubNumber,
            email: u.email,
            responseInfo: parseResponseAnswer(u, g),
          }))
      );
    }

    return 'This stage has not been initiated.';
  };

  const getMarkData = () => {
    const ready = ['mark_crq', 'complete'].includes(get(currentPaper, 'status'));

    if (ready) {
      return (groups || []).flatMap((g) =>
        get(g, 'users', [])
          .sort(_onNumber)
          .map((u) => ({
            number: `#${u.number}`,
            group: g.title,
            name: [u.firstName, u.lastName].join(' '),
            ecn: u.eClubNumber,
            email: u.email,
            responseInfo: parseResponseMark(u, g),
          }))
      );
    }

    return 'This stage has not been initiated.';
  };

  useEffect(() => {
    const create = _searchData(_filterData(getCreateData()), search);
    setCreateData(create);

    const answer = _searchData(_filterData(getAnswerData()), search);
    setAnswerData(answer);

    const mark = _searchData(_filterData(getMarkData()), search);
    setMarkData(mark);
  }, [search, responseFilter, currentPaper, loading, groupsLoading, responsesLoading]);

  return (
    <Pane>
      <h2 className="current-round-title">Round Overview</h2>
      {loading == false && groupsLoading == false && responsesLoading == false ? (
        <>
          <div className="current-round-top-section">
            <div className="current-round-user-search">
              <input
                className="accordion-search"
                placeholder="Search for User..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="current-round-papers">
              <div className="current-round-start-date">{moment(round.startDate).format('MMM yyyy')}</div>
              <div className="papers-list">
                <PaperPicker currentPaper={currentPaper} setCurrentPaper={setCurrentPaper} papers={papers} />
              </div>
            </div>
          </div>

          <StatusSelector responseFilter={responseFilter} setResponseFilter={setResponseFilter} />

          <div className="current-round-tabs-container">
            <TabGroup labels={tabLables} initial={0}>
              <CRQTable tab="create" paperTitle={get(currentPaper, 'title')} tableData={createData} />
              <CRQTable tab="answer" paperTitle={get(currentPaper, 'title')} tableData={answerData} />
              <CRQTable tab="mark" paperTitle={get(currentPaper, 'title')} tableData={markData} />
            </TabGroup>
          </div>
        </>
      ) : <div className="current-round-loading"><Loading /></div>}
    </Pane>
  );
}

export default CurrentRound;

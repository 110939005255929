import React from 'react';
import { useSelector } from 'react-redux';
import './Dashboard.scss';
import propTypes from 'prop-types';
// Components:
import MemberDashboard from '../MemberDashboard/MemberDashboard';
import AdminDashboard from '../AdminDashboard/AdminDashboard';

function Dashboard() {
  const { admin } = useSelector((store) => store.session.current);

  if (typeof admin === 'undefined') {
    return null;
  }
  return admin ? <AdminDashboard /> : <MemberDashboard />;
}

export default Dashboard;

Dashboard.propTypes = {
  admin: propTypes.bool,
};

import React, { useContext } from 'react';
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import propTypes from 'prop-types';
import { QuestionsContext } from './question-manager';
import { Picker } from './IndexPicker';
import { Conditional } from '../../common/'

import ScenarioQuestion from '../QuestionTypes/ScenarioQuestion';
import ListQuestion from '../QuestionTypes/ListQuestion';
import { MemoizedClinical } from '../QuestionTypes/ClinicalQuestion/ClinicalQuestion';
import FillableQuestion from '../QuestionTypes/FillableQuestion';
import ShortQuestion from '../QuestionTypes/ShortQuestion';

export const QuestionItem = (props) => {
  switch (props.item.name) {
    case 'scenario':
      return <ScenarioQuestion {...props} />;
    case 'clinical':
      return <MemoizedClinical {...props} />;
    case 'short':
      return <ShortQuestion {...props} />;
    case 'list':
      return <ListQuestion {...props} />
    case 'fillable':
      return <FillableQuestion {...props} />
    default:
      return null;
  }
};

QuestionItem.propTypes = {
  item: propTypes.object,
};

export const QuestionIndex = ({ submitted, toggle }) => {
  const { dispatch, data, isQuestionActive } = useContext(QuestionsContext)
  const actionProps = { dispatch, submitted };

  const { errorData } = useSelector(r => r.responses.responseStatus)
  const errors = get(errorData, 'questions', [])

  return data.map((item, idx) => {
    const itemProps = { item, idx, disableAnswer: true, ...actionProps };

    return (
      <div key={item.keyStamp || item.id}>
        <Conditional if={!isQuestionActive}>
          <Picker questions={{ dispatch, data }} toggle={toggle} index={idx} open />
        </Conditional>
        <QuestionItem
          dispatch={dispatch}
          hideActions={isQuestionActive && item.confirmed}
          {...itemProps}
          error={errors.includes(idx)}
        />
      </div>
    );
  });
};

import { combineReducers } from 'redux';
import { ROUNDS, ROUNDS_CREATE, ROUNDS_SHOW, PROGRESS_ROUND, MISSED_DEADLINES } from '../root/action-types';
import { standardForCollections, standardModelCreateUpdateDestroy, standardShow } from '../api/standardReducers';

export const roundsShow = (state = {}, action) => {
  switch (action.type) {
    case `${ROUNDS_CREATE}_START`:
    case `${PROGRESS_ROUND}_START`:
    case `${ROUNDS_SHOW}_START`:
      return { ...state, loading: true };
    case `${ROUNDS_CREATE}_SUCCESS`:
    case `${PROGRESS_ROUND}_SUCCESS`:
    case `${ROUNDS_SHOW}_SUCCESS`:
      return {
        ...state,
        ...action.response.data,
        loading: false,
      };
    case `${ROUNDS_CREATE}_FAILURE`:
    case `${PROGRESS_ROUND}_FAILURE`:
    case `${ROUNDS_SHOW}_FAILURE`:
      return {
        ...state,
        error: action.response.data.error,
        loading: false,
      };
    default: {
      return state;
    }
  }
};

export default combineReducers({
  index: standardForCollections(ROUNDS),
  show: roundsShow,
  response: standardModelCreateUpdateDestroy('response', ROUNDS),
  missedDeadlines: standardShow(MISSED_DEADLINES),
});

import React, { useState } from 'react';
import { modalDefaultStyle } from './ModalDefaultStyle';
import { ContinueAnsweringOrMarkingActions } from './ContinueAnsweringOrMarkingActions';
import Modal from 'react-modal';
import propTypes from 'prop-types';
import './ActionModal.scss';
// Components:
import { UserActions } from './UserActions';
import { GroupActions } from './GroupActions';
import { LateSubmissionActions } from './LateSubmissionActions';

const Actions = ({ model, data, actions }) => {
  switch (model) {
    case 'GROUPS':
      return <GroupActions data={data} actions={actions} />;
    case 'CONTINUE_ANSWERING_OR_MARKING':
      return <ContinueAnsweringOrMarkingActions data={data} actions={actions} />;
    case 'LATE_SUBMISSION':
      return <LateSubmissionActions data={data} actions={actions} />;
    case 'USERS':
      return <UserActions data={data} actions={actions} />;
    case 'EMAILLOGS':
      return <div dangerouslySetInnerHTML={{ __html: data.body }} />
    default:
      return <div>No actions available</div>;
  }
};
Actions.propTypes = {
  model: propTypes.string,
  data: propTypes.object,
  actions: propTypes.object,
};

const style = (model) => {
  if (model == 'EMAILLOGS') {
    return {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #41489E',
        display: 'flex',
        flexDirection: 'column',
        width: '700px',
      }
    }
  } else {
    return modalDefaultStyle
  }
}

const ActionModal = ({ 
    closeModal,
    modalIsOpen,
    model,
    data,
    customStyle
  }) => {
  const [confirm, setConfirm] = useState(false);
  const actions = { confirm, setConfirm, closeModal };

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyle || style(model)}>
      <Actions model={model} data={data} actions={actions} />
    </Modal>
  );
};

export default ActionModal;

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

ActionModal.propTypes = {
  openModal: propTypes.func,
  closeModal: propTypes.func,
  modalIsOpen: propTypes.bool,
  model: propTypes.string,
  data: propTypes.object,
};

import React from 'react';
import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import './NavItem.scss';

function NavItem(props) {
  const css = ['link'];

  if (props.theme) {
    css.push(props.theme);
  }

  return (
    <NavLink className={css.join(' ')} to={props.path}>
      {props.text}
    </NavLink>
  );
}

export default NavItem;

NavItem.propTypes = {
  path: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  theme: propTypes.string,
};

import React from 'react';
import { SimpleButton, Conditional } from '../../common';
import propTypes from 'prop-types';

export const ConfirmButtons = ({ label, disabled, cancel, confirm }) => (
  <div className="button-container">
    <span>Are you sure you want to {label}?</span>
    <SimpleButton text="Cancel" theme="muted" onClick={cancel} disabled={disabled} />
    <SimpleButton text={`Yes, ${label}`} theme="save" onClick={confirm} disabled={disabled} />
  </div>
);
ConfirmButtons.propTypes = {
  label: propTypes.string,
  disabled: propTypes.bool,
  cancel: propTypes.func,
  confirm: propTypes.func,
};

export const ConfirmationButtons = ({ label, cancel, confirm, disabled }) => (
  <ConfirmButtons label={label} cancel={cancel} confirm={confirm} disabled={disabled} />
);
ConfirmationButtons.propTypes = {
  disabled: propTypes.bool,
  cancel: propTypes.func,
  confirm: propTypes.func,
  label: propTypes.string,
};

export const DefaultButtons = ({ save, reset, confirmSubmit, disabled, admin }) => (
  <React.Fragment>
    <SimpleButton text="Save" theme="save" onClick={save} disabled={disabled} />
    <Conditional if={admin}>
      <SimpleButton text="Reset" theme="muted" onClick={reset} disabled={disabled} />
    </Conditional>
    <SimpleButton text="SUBMIT" onClick={confirmSubmit} disabled={disabled} />
  </React.Fragment>
);
DefaultButtons.propTypes = {
  save: propTypes.func,
  reset: propTypes.func,
  confirmSubmit: propTypes.func,
  disabled: propTypes.bool,
  admin: propTypes.bool,
};

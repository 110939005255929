import React from 'react';
import propTypes from 'prop-types';
import ClinicalOptions from './ClinicalOptions';
import ClinicalTable from './ClinicalTable';
import ClinicalImage from './ClinicalImage';

const ClinicalQuestion = ({ dispatch, ...props }) => {
  const QuestionTypes = () => {
    if (props.item.type === 'TABLE') {
      return <ClinicalTable dispatch={dispatch} props={props} />;
    }
    if (props.item.type === 'IMAGE') {
      return <ClinicalImage dispatch={dispatch} props={props} />;
    }
    return <ClinicalOptions setQuestionType={setQuestionType} submitted={props.submitted} />;
  };

  const setQuestionType = (value) => {
    dispatch({
      type: 'setClinicalType',
      index: props.item.id,
      questionType: value,
    });
  };

  const buildClassName = () => {
    return `clinical-container
      ${!props.item.confirmed ? 'active' : ''}
      ${props.submitted ? 'crq-submitted' : ''}`;
  };

  return (
    <div className={buildClassName()}>
      <QuestionTypes />
    </div>
  );
};
export default ClinicalQuestion;
export const MemoizedClinical = React.memo(ClinicalQuestion);

ClinicalQuestion.propTypes = {
  item: propTypes.object,
  idx: propTypes.number,
  dispatch: propTypes.func,
  disableAnswer: propTypes.bool,
  hideActions: propTypes.bool,
  submitted: propTypes.bool,
};

import React from 'react';
import propTypes from 'prop-types';

const StatusSelector = ({ responseFilter, setResponseFilter }) => {
  function isActive(i) {
    return responseFilter == i ? 'active' : 'inactive';
  }
  return (
    <div className="current-round-status-selector">
      <div
        className={`current-round-status-radio ${isActive('AllUsers')}`}
        onClick={() => {
          setResponseFilter('AllUsers');
        }}
      >
        All Users
      </div>

      <div
        className={`current-round-status-radio ${isActive('Submitted')}`}
        onClick={() => {
          setResponseFilter('Submitted');
        }}
      >
        Submitted
      </div>

      <div
        className={`current-round-status-radio ${isActive('InProgress')}`}
        onClick={() => {
          setResponseFilter('InProgress');
        }}
      >
        In Progress
      </div>

      <div
        className={`current-round-status-radio ${isActive('NotStarted')}`}
        onClick={() => {
          setResponseFilter('NotStarted');
        }}
      >
        Not Started
      </div>
    </div>
  );
};

export default StatusSelector;

StatusSelector.propTypes = {
  responseFilter: propTypes.string,
  setResponseFilter: propTypes.func,
};

import React from 'react';
import './AnswerBox.scss';
import propTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';

function buildClassName(index) {
  return index ? 'idx' : 'text';
}

function showPrefix(index) {
  return index ? <div className="prefix">{index}</div> : null;
}

const AnswerBox = ({ dispatch, ...props }) => (
  <div className="answer-field">
    {showPrefix(props.listPrefix)}
    {props.displayOnly ? (
      <div className={`${buildClassName(props.idx)} static`}>{props.value}</div>
    ) : (
      <TextareaAutosize
        className={buildClassName(props.idx)}
        name={props.name}
        onChange={(event) => {
          dispatch({
            type: 'update',
            index: props.idx,
            name: event.currentTarget.name,
            value: event.currentTarget.value,
          });
        }}
        placeholder="Answer here"
        disabled={props.disabled}
        value={props.value}
      />
    )}
  </div>
);

export default AnswerBox;

AnswerBox.propTypes = {
  idx: propTypes.number,
  name: propTypes.string,
  disabled: propTypes.bool.isRequired,
  listPrefix: propTypes.string,
  update: propTypes.func,
  value: propTypes.string,
  displayOnly: propTypes.bool,
};

showPrefix.propTypes = {
  idx: propTypes.number,
};

import React, { useState, useEffect } from 'react';
import Api from '../../api/call';
import { PAPERS_UPDATE } from '../../root/action-types';
import { DateTimePicker } from '../../common/';
import { get } from 'lodash';
import propTypes from 'prop-types';

const DeadlinePickers = ({ currentPaper }) => {
  const initCreate = get(currentPaper, 'createDeadline', new Date());
  const initAnswer = get(currentPaper, 'answerDeadline', new Date());
  const initMark = get(currentPaper, 'markDeadline', new Date());

  const [createDeadline, setCreateDeadline] = useState(initCreate);
  const [answerDeadline, setAnswerDeadline] = useState(initAnswer);
  const [markDeadline, setMarkDeadline] = useState(initMark);

  const getCreateDate = () => {
    if (get(currentPaper, 'createDeadline')) {
      setCreateDeadline(currentPaper.createDeadline);
    }
  };
  useEffect(getCreateDate, [initCreate]);

  const getAnswerDate = () => {
    if (get(currentPaper, 'answerDeadline')) {
      setAnswerDeadline(currentPaper.answerDeadline);
    }
  };
  useEffect(getAnswerDate, [initAnswer]);

  const getMarkDate = () => {
    if (get(currentPaper, 'markDeadline')) {
      setMarkDeadline(currentPaper.markDeadline);
    }
  };
  useEffect(getMarkDate, [initMark]);

  const setCreate = (date) => {
    Api({
      request: PAPERS_UPDATE,
      id: currentPaper.id,
      data: { createDeadline: date },
    });

    setCreateDeadline(date);
  };

  const setAnswer = (date) => {
    Api({
      request: PAPERS_UPDATE,
      id: currentPaper.id,
      data: { answerDeadline: date },
    });

    setAnswerDeadline(date);
  };

  const setMark = (date) => {
    Api({
      request: PAPERS_UPDATE,
      id: currentPaper.id,
      data: { markDeadline: date },
    });

    setMarkDeadline(date);
  };

  return (
    <div className="deadlines">
      <div className="deadline-picker">
        <div>Create Deadline</div>
        <DateTimePicker onChange={(date) => setCreate(date)} selected={new Date(createDeadline)} />
      </div>
      <div className="deadline-picker">
        <div>Answer Deadline</div>
        <DateTimePicker onChange={(date) => setAnswer(date)} selected={new Date(answerDeadline)} />
      </div>
      <div className="deadline-picker">
        <div>Mark Deadline</div>
        <DateTimePicker onChange={(date) => setMark(date)} selected={new Date(markDeadline)} />
      </div>
    </div>
  );
};

export default DeadlinePickers;

DeadlinePickers.propTypes = {
  currentPaper: propTypes.object,
};

import axios from 'axios';

const { get, post, put } = axios;

const CRUD_ROUTES = ({ action, path }) => ({
  [action]: { verb: get, path },
  [`${action}_SHOW`]: { verb: get, path },
  [`${action}_CREATE`]: { verb: post, path },
  [`${action}_UPDATE`]: { verb: put, path },
  [`${action}_DESTROY`]: { verb: axios.delete, path },
});

export default {
  ...CRUD_ROUTES({
    action: 'USERS',
    path: 'users',
  }),
  ...CRUD_ROUTES({
    action: 'RESOURCES',
    path: 'resources',
  }),
  ...CRUD_ROUTES({
    action: 'ARTICLES',
    path: 'articles',
  }),
  ARTICLES_ASSIGNMENT: {
    verb: post,
    path: 'articles/assignment',
  },
  ...CRUD_ROUTES({
    action: 'GROUPS',
    path: 'groups',
  }),
  ...CRUD_ROUTES({
    action: 'ROUNDS',
    path: 'rounds',
  }),
  ...CRUD_ROUTES({
    action: 'RESPONSES',
    path: 'responses',
  }),
  ...CRUD_ROUTES({
    action: 'NOTIFICATIONS',
    path: 'notifications',
  }),
  ...CRUD_ROUTES({
    action: 'ADMIN_NOTIFICATIONS',
    path: 'admin_notifications',
  }),
  ...CRUD_ROUTES({
    action: 'PAPERS',
    path: 'papers',
  }),
  ...CRUD_ROUTES({
    action: 'CURRICULUM_CODES',
    path: 'curriculum_codes',
  }),
  EMAIL_LOGS: {
    verb: get,
    path: 'email_logs',
  },
  SESSION_LOGIN: {
    verb: post,
    path: 'authenticate',
  },
  PASSWORD_RESET: {
    verb: post,
    path: 'password_reset',
  },
  PASSWORD_CHANGE: {
    verb: post,
    path: 'password_change',
  },
  CONFIG: {
    verb: get,
    path: 'config',
  },
  CURRENT_USER: {
    verb: get,
    path: 'users/current',
  },
  USERS_SUBSTITUTE: {
    verb: get,
    path: 'users',
  },
  CURRENT_RESPONSE: {
    verb: get,
    path: 'responses/current_create',
  },
  CURRENT_ANSWER_RESPONSES: {
    verb: get,
    path: 'responses/current_answer',
  },
  CURRENT_MARK_RESPONSES: {
    verb: get,
    path: 'responses/current_mark',
  },
  CRQ_DOWNLOAD: {
    verb: get,
    path: 'downloads/crq',
  },
  PROGRESS_ROUND: {
    verb: put,
    path: 'rounds/progress',
  },
  RESET_CURRENT_RESPONSE: {
    verb: get,
    path: 'responses/reset_crq',
  },
  RESPONSES_FOR_GROUP: {
    verb: get,
    path: 'responses/for_group',
  },
  RESPONSES_MARKED: {
    verb: get,
    path: 'responses/marked',
  },
  RESPONSES_ROLLBACK_STATUS: {
    verb: put,
    path: 'responses',
  },
  RESPONSES_UPDATE_ANSWERS: {
    verb: put,
    path: 'responses',
    pathEnd: 'update_answers',
  },
  RESPONSES_UPDATE_MARKS: {
    verb: put,
    path: 'responses',
    pathEnd: 'update_marks',
  },
  MISSED_DEADLINES: {
    verb: get,
    path: 'rounds',
    pathEnd: 'missed_deadlines',
  },
};

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDropbox } from '../../attachment/dropbox';

const useResources = () => {
  const { dropboxSearch, ready } = useDropbox();
  const data = useSelector((store) => store.dropboxSearch);

  const listAll = () => {
    ready && dropboxSearch({ path: '/resources', recursive: true });
  };

  useEffect(() => {
    listAll();
  }, [ready]);

  return { data };
};
export default useResources;

import standardAPI from '../api/standardReducers';
import users from '../user/reducers';
import resources from '../resource/reducers';
import articles from '../article/reducers';
import groups from '../group/reducers';
import papers from '../paper/reducers';
import notifications from '../notifications/reducers';
import rounds from '../CurrentRound/reducers';
import responses from '../response/reducers';
import session from './sessionReducers';
import config from './configReducer';
import { attachment, dropbox, dropboxSearch } from '../attachment/reducers';
import download from '../api/download-reducer';
import curriculumCodes from '../response/ResponseMeta/reducers';
import emailLogs from '../emailLogs/reducers'

export const reducers = {
  config,
  users,
  resources,
  articles,
  groups,
  papers,
  notifications,
  rounds,
  responses,
  session,
  attachment,
  dropbox,
  dropboxSearch,
  download,
  curriculumCodes,
  emailLogs,
  ...standardAPI,
};

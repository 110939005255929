/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
// Components
import ActionModal from './ActionModal';
// Hooks
import { useModal } from '../';

const Contents = ({ model, data, columns, noShow }) => {
  const { openModal, closeModal, modalIsOpen } = useModal();
  const [modelData, setModelData] = useState({});

  const open = (data) => {
    setModelData(data);
    openModal();
  };

  const Modal = () => (
    <ActionModal model={model} data={modelData} closeModal={closeModal} modalIsOpen={modalIsOpen} />
  );
  Modal.propTypes = {
    id: propTypes.string,
  };

  const Column = ({ column, row }) => <span className="index-table-row-cell">{get(row, column.accessor)}</span>;
  Column.propTypes = {
    column: propTypes.object,
    row: propTypes.object,
  };

  function rowClass(row) {
    const classes = ['index-table-row'];

    if (row.blocked) {
      classes.push('user-blocked');
    }

    return classes.join(' ');
  }

  const NoShowRow = (row) => (
    <div className={`clickable ${rowClass(row)}`} key={row.id} onClick={() => open(row)}>
      <Columns row={row} />
    </div>
  )

  const ShowRow = (row) => (
    <div className={rowClass(row)} key={row.id}>
      <Link to={`${model.toLowerCase()}/${row.id}`}>
        <Columns row={row} />
      </Link>
      <button onClick={() => open(row)} className="index-table-row-action">
        ...
      </button>
    </div>
  );
  const Row = (row) => noShow ? NoShowRow(row) : ShowRow(row)

  const Columns = ({ row }) => columns.map((c) => <Column key={[row, c.accessor].join(' ')} row={row} column={c} />);

  const RowIndex = () => data.map((row) => Row(row));

  return (
    <div className="contents">
      <RowIndex />
      <Modal />
    </div>
  );
};

export default Contents;

Contents.propTypes = {
  data: propTypes.array,
  columns: propTypes.array,
  model: propTypes.string.isRequired,
  blocked: propTypes.bool,
  noShow: propTypes.bool,
};

import React, { useState } from 'react';
import { EditButtons, ConfirmButtons } from '../QuestionButtons';
import { InputTable } from '../../../common/';

export default ({ dispatch, props }) => {
  const [state, setState] = useState({ ...props.item, focused: '' });

  const updateTable = (value) => {
    setState((s) => ({ ...s, table: JSON.stringify(value) }));
  };

  const ActionButtons = () => {
    if (props.hideActions) {
      return null;
    }
    return props.item.confirmed ? (
      <EditButtons submitted={props.submitted} idx={props.idx} />
    ) : (
      <ConfirmButtons submitted={props.submitted} idx={props.idx} question={state} dispatch={dispatch} />
    );
  };

  return (
    <div>
      <InputTable
        originalTable={state.originalTable}
        table={state.table}
        onChange={updateTable}
        readOnly={props.item.confirmed}
        answer={!props.disableAnswer}
        clinical
      />
      <ActionButtons />
    </div>
  );
};

import React, { useState, useEffect, useContext } from 'react';
import Api from '../../api/call';
import { RESPONSES_SHOW, RESPONSES_SHOW_RESET_STORE } from '../../root/action-types';
import { useSelector, useDispatch } from 'react-redux';
import '../Response.scss';
import propTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
// Components:
import { Prefix } from '../QuestionTypes/Prefix'
import { Pane, TabGroup, TabContent, SimpleButton } from '../../common';
import Download from '../../api/Download';
import AnswerGuidance from '../AnswerGuidance/AnswerGuidance';
import Question from '../QuestionTypes/Question';
import ResponseMeta from '../ResponseMeta/ResponseMeta';
// Reducers:
import { QuestionProvider, QuestionsContext } from '../ResponseCreate/question-manager';
import { FinalComment } from '../ResponseMark/ResponseMark';

const getResponseById = (responseId) => {
  if (responseId) Api({ request: RESPONSES_SHOW, id: responseId });
};

const Wrapper = (props) => (
  <QuestionProvider><Marked {...props} /></QuestionProvider>
)

function Marked(props) {
  const dispatch = useDispatch();

  const [meta, setMeta] = useState('');
  const questions = useContext(QuestionsContext)
  const { userId: pUserId } = get(props, 'location.state', {});
  const responseId = props.match.params.responseId;
  const response = useSelector((store) => store.responses.show);
  const sUserId = useSelector((store) => store.session.current.id);

  const userId = pUserId ?? sUserId;

  const [finalComment, setFinalComment] = useState('');

  const loadResponse = () => {
    if (isEmpty(response)) getResponseById(responseId);

    if (get(response, 'data.data') && userId) {
      const data = get(response, 'data.data');

      setMeta(data.meta);

      questions.dispatch({
        type: 'loadMarks',
        data: data.questions,
        userId: userId,
      });

      setFinalComment(get(data.finalComment, userId, ''));
    }
  };

  useEffect(() => {
    loadResponse(responseId);
  }, [response, userId]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESPONSES_SHOW_RESET_STORE });
    };
  }, []);
  return (
    <RenderQuestions
      meta={meta}
      questions={questions}
      finalComment={finalComment}
      responseId={responseId}
      userId={userId}
    />
  );
}

const RenderQuestions = ({ meta, questions, finalComment, userId, responseId }) => {
  if (!meta || !questions || !responseId || !userId) return null;
  return (
    <Pane theme="response marked">
      <MarksAchieved questions={questions} />
      <TabGroup labels={['CRQ', 'AGS']}>
        <TabContent className="custom-response">
          <h2>Constructed Response Question</h2>
          <ResponseMeta form={meta} finalised />
          <QuestionIndex questions={questions} />
          <FinalComment value={finalComment} disabled />
        </TabContent>
        <TabContent className="custom-response">
          <AnswerGuidance questions={questions.data} disabled={true}>
            <ResponseMeta form={meta} finalised />
          </AnswerGuidance>
        </TabContent>
      </TabGroup>
      <div className="download-pdf">
        <Download type="crq" id={responseId} userId={userId} stage="MARK">
          <SimpleButton text="Export as PDF" />
        </Download>
      </div>
    </Pane>
  );
};

const MarksAchieved = ({ questions }) => {
  let awarded = 0;
  let total = 0;

  questions.data.forEach((item) => {
    if (item.name === 'scenario') return null;
    if (item.name === 'clinical') return null;
    awarded += get(item, 'awarded[0].value', 0);
    total += get(item, 'marks.value', 0);
  });
  return <div className="total-marks">{`${awarded} / ${total} marks`}</div>;
};

const QuestionIndex = ({ questions }) => {
  const { data } = questions;

  return data.map((item, idx) => {
    const itemProps = { idx, item };

    return (
      <div className="mark-row" key={idx}>
        <QuestionItem {...itemProps} />
        <MarkBox {...itemProps} />
      </div>
    );
  });
};

const MarkBox = ({ item, idx }) => {
  if (item.name === 'scenario' || item.name === 'clinical') {
    return null;
  }
  return (
    <div className="mark-box">
      <div className="prefix-row">
        <Prefix idx={idx} />
        <div className="mark-total">{`${get(item, 'awarded[0].value', 'n/a')} / ${get(item, 'marks.label', ' ')}`}</div>
      </div>
      <textarea value={get(item, 'comment', ' ')} readOnly disabled />
    </div>
  );
};

function QuestionItem(props) {
  const { item, idx } = props;
  const actions = { actions: {}, disableAnswer: true, hideActions: true };
  const questionProps = { item, idx, ...actions };

  return <Question name={item.name} {...questionProps} />;
}

export default Wrapper;

Marked.propTypes = {
  response: propTypes.shape({
    loading: propTypes.bool,
    errors: propTypes.array,
    created: propTypes.bool,
  }),
  location: propTypes.object,
  item: propTypes.object,
  idx: propTypes.number,
};

import React from 'react';
import { get } from 'lodash';
import './UserAccordion.scss';
import propTypes from 'prop-types';
// Components
import { Accordion } from '../../common';
// Assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const UserAccordion = ({ users, accordionState, dispatch }) => {
  const buildUserDropdown = (row) => (
    <div key={row.position} position={row.position} label={row.label}>
      <input
        className="accordion-search"
        placeholder="Search Users"
        onChange={(e) => dispatch({ type: 'search', value: e.target.value, allUsers: users })}
      />
      <div className="list">{accordionState.availableUsers.map((user) => ListItem(user, row.position))}</div>
    </div>
  );

  const ListItem = (user, position) => {
    const onClick = () => {
      const type = position === 'last' ? 'addLast' : 'add';
      dispatch({ type, user, position });
    };

    return (
      <div className="list-item" key={user.id} onClick={onClick}>
        <FontAwesomeIcon icon={faUser} />
        <div className="title">
          <div className="name">{`${user.firstName} ${user.lastName}`}</div>
          <div className="number">{`ECN ${user.eClubNumber}`}</div>
        </div>
      </div>
    );
  };

  const cancel = (position) => {
    const last = position === String(accordionState.accordionRows.length - 1);
    const type = last ? 'removeLast' : 'remove';
    dispatch({ type, position });
  };

  return (
    <div className="user-dropdowns">
      <Accordion
        onClick={(position) => dispatch({ type: 'toggleOpen', position, allUsers: users })}
        isOpen={accordionState.open}
        cancel={(position) => cancel(position)}
      >
        {get(accordionState, 'accordionRows', []).map((row) => buildUserDropdown(row))}
      </Accordion>
    </div>
  );
};
UserAccordion.propTypes = {
  users: propTypes.array,
  accordionState: propTypes.object,
  dispatch: propTypes.func,
};

export default UserAccordion;

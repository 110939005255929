import React, { useContext } from 'react';
import { ButtonRow, SimpleButton } from '../../common/';
import { QuestionsContext } from '../ResponseCreate/question-manager'

export const ConfirmButtons = ({ submitted, idx, question, dispatch }) => (
  <ButtonRow theme="right pad">
    <SimpleButton
      text="CANCEL"
      theme="muted"
      onClick={() =>
        dispatch({
          type: 'cancel',
          index: idx,
        })
      }
      disabled={submitted}
    />
    <SimpleButton
      text="Confirm"
      onClick={() =>
        dispatch({
          type: 'confirm',
          question: question,
          index: idx,
        })
      }
      disabled={submitted}
    />
  </ButtonRow>
);

export const EditButtons = ({ submitted, idx }) => {
  const { dispatch, data } = useContext(QuestionsContext)
  const item = data[idx]

  return (
    <ButtonRow theme="right pad">
      <SimpleButton
        name={item.name}
        text="DELETE"
        theme="muted danger"
        onClick={() =>
          dispatch({
            type: 'remove',
            index: idx,
          })
        }
        disabled={submitted}
      />
      <SimpleButton
        text="Edit"
        theme="muted"
        onClick={() =>
          dispatch({
            type: 'edit',
            index: idx,
          })
        }
        disabled={submitted}
      />
    </ButtonRow>
  )
}

import React from 'react';
import { formats, toolbarModules, buildEvent } from '../../common/RichText/Constants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { isArray, isString } from 'lodash';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import { Conditional } from '../';

import './input-table.scss';

// Data format is row major nested array[[],[]]
// data[row][column] = cell value
// data[0] = first row array
// data[1][2] = value of cell found in second row, third column
//
// Use readOnly flag for prefilled information tables
// Use answer flag for partially filled tables in which users will add their own answers

const sanitizeData = (raw) => {
  const sane = parseRaw(raw);
  const numRows = Math.max(sane.length, 2);
  const numColumns = Math.max(...sane.map((row) => row.length), 2);

  while (sane.length < numRows) {
    sane.push([]);
  }
  sane.forEach((row) => {
    while (row.length < numColumns) {
      row.push('');
    }
  });

  return sane;
};

const parseRaw = (raw) => {
  if (!raw) {
    return [[]];
  }
  if (isString(raw)) {
    return JSON.parse(raw);
  }
  if (isArray(raw)) {
    return raw.map((row) => [...row]);
  } // avoid mutation
  throw new Error(`Unsupported data passed to input table: ${raw}`);
};

const useCells = (originalTable, currentTable, onChange = () => {}) => {
  const prefilled = sanitizeData(originalTable);
  const cells = sanitizeData(currentTable);

  const setCells = (nextCells) => {
    onChange(nextCells);
  };
  const setCell = ({ currentTarget: { name, value } }) => {
    const nextCells = cells.map((row) => [...row]);
    const [row, column] = name.split(':');
    nextCells[row][column] = value;
    setCells(nextCells);
  };
  const addRow = () => {
    setCells([...cells, cells[0].map(() => '')]);
  };
  const addColumn = () => {
    setCells(cells.map((row) => [...row, '']));
  };
  const deleteRow = ({ currentTarget: { name } }) => {
    const nextCells = [...cells];
    nextCells.splice(name, 1);
    setCells(sanitizeData(nextCells));
  };
  const deleteColumn = ({ currentTarget: { name } }) => {
    const nextCells = cells.map((row) => [...row]);
    nextCells.forEach((row) => row.splice(name, 1));
    setCells(sanitizeData(nextCells));
  };

  return { cells, setCell, addRow, addColumn, deleteRow, deleteColumn, prefilled };
};

const RichText = ({ readOnly, value, row, column, setCell, noToolbar, placeholder }) => {
  const textChange = (content, delta, source, editor) => {
    if(value != editor.getHTML()){
      const event = buildEvent(`${row}:${column}`, editor);
      setCell(event);
    }
  };

  const modules = toolbarModules(noToolbar || readOnly)
  return (
    <ReactQuill
      theme="snow"
      className={"ql-show-toolbar"}
      value={value}
      modules={modules}
      onChange={textChange}
      placeholder={placeholder}
      readOnly={readOnly}
      formats={formats}
    />
  );
}

export const InputTable = ({ originalTable, table, className, answer, readOnly, onChange, noToolbar, clinical }) => {
  const { cells, setCell, addRow, addColumn, deleteRow, deleteColumn, prefilled } = useCells(originalTable, table, onChange);
  const resizable = !answer && !readOnly;

  return (
    <div className={`input-table ${className || ''}`}>
      <table>
        <tbody>
          {cells.map((row, r) => (
            <tr key={r}>
              {row.map((val, c) => {
                return (
                  <td key={`${r}:${c}`}>
                    <RichText
                      className="react-quill-text-area"
                      readOnly={readOnly || (answer && !!prefilled[r][c])}
                      value={val}
                      row={r}
                      column={c}
                      setCell={setCell}
                      noToolbar={noToolbar}
                      placeholder={clinical ? null : 'Answer here'}
                    />
                  </td>
                );
              })}
              <Conditional if={resizable}>
                <td className="control">
                  <button className="delete-row" onClick={deleteRow} name={r} tabIndex="-1">
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                </td>
              </Conditional>
            </tr>
          ))}
        </tbody>
        <Conditional if={resizable}>
          <tfoot>
            <tr>
              {cells[0].map((val, c) => (
                <td key={c}>
                  <button className="delete-column" onClick={deleteColumn} name={c}>
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                </td>
              ))}
            </tr>
            <tr>
              <td colSpan={cells[0].length}>
                <button className="add-row" onClick={addRow}>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </td>
            </tr>
          </tfoot>
        </Conditional>
      </table>
      <Conditional if={resizable}>
        <button className="add-column" onClick={addColumn}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </Conditional>
    </div>
  );
};

InputTable.propTypes = {
  data: propTypes.any,
  className: propTypes.string,
  readOnly: propTypes.bool,
  answer: propTypes.bool,
  onChange: propTypes.func,
};

import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { get } from 'lodash';
import propTypes from 'prop-types';
// Components:
import {
  Pane,
  useCurrentResponse,
  useCurrentRound,
  useAnswerResponses,
  useMarkResponse,
  useMarkedResponses,
  usePapers,
  useDeadlines,
  Conditional,
} from '../../common/';
import Notifications from '../../notifications/Notifications';
// Assets:
import { CreateCRQ, AnswerCRQ, MarkCRQ, MarkedPapers } from '../../root/assets/';

const MemberDashboard = () => {
  useCurrentRound({ include_responses: false });
  const groupId = useSelector((store) => store.session.current.groupId);
  const penalties = useSelector((store) => store.session.current.penalties);
  const blocked = useSelector((store) => store.session.current.blocked);
  const group = useSelector((store) => store.groups.show);

  const currentCreate = useCurrentResponse();
  const currentAnswer = useAnswerResponses();
  const currentMark = useMarkResponse();
  const { releasedResponses } = useMarkedResponses();
  const { paperForStatus, papers } = usePapers()

  const { nextDeadline } = useDeadlines(papers);

  const checkStageComplete = (status) => {
    if (status === 'create_crq') return currentCreate.complete;
    if (status === 'answer_crq') return currentAnswer.complete;
    if (status === 'mark_crq') return currentMark.complete;
  };

  const createClassName = (status) => {
    const classes = ['crq-button'];
    const paper = paperForStatus(status);
    if (blocked) return classes.join(' ');
    if (paper) classes.push('active');
    if (checkStageComplete(status)) classes.push('complete');

    return classes.join(' ');
  };

  const markedClassName = () => {
    const classes = ['crq-button'];
    if (blocked) return classes.join(' ');
    if (get(releasedResponses, 'length', 0) > 0) classes.push('active');

    return classes.join(' ');
  };

  const getPaperTitle = (status) => {
    const paper = paperForStatus(status);
    return get(paper, 'title', '-');
  };

  const PenaltyPoints = () => (
    <div className="penalties">
      <input type="checkbox" checked={penalties > 0} disabled />
      <input type="checkbox" checked={penalties > 1} disabled />
      <input type="checkbox" checked={penalties > 2} disabled />
    </div>
  );

  const CrqStatusBar = () => {
    const groupTitle = get(group, ['data', 'title'], '-----');

    return (
      <div className="crq-status-bar">
        You have been assigned to group: <b>{groupTitle}</b>&nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp; Next Deadline:{' '}
        <b>{nextDeadline}</b>&nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp; Penalty Points: <PenaltyPoints />
      </div>
    );
  };

  return (
    <Pane>
      <h2>CURRENT TASKS</h2>
      <div className="dashboard-pane-container">
        <div className="crq-tasks">
          <NavLink
            className={createClassName('create_crq')}
            to={{ pathname: '/crq/dashboard', state: { position: 0 } }}
          >
            <img src={CreateCRQ} alt="create-crq" />
            <div className="crq-description">
              <div>Create CRQ/AGS</div>
              <div>
                <b>{getPaperTitle('create_crq')}</b>
              </div>
            </div>
          </NavLink>
          <NavLink
            className={createClassName('answer_crq')}
            to={{ pathname: '/crq/dashboard', state: { position: 1 } }}
          >
            <img src={AnswerCRQ} alt="answer-crq" />
            <div className="crq-description">
              <div>Answer</div>
              <div>
                <b>{getPaperTitle('answer_crq')}</b>
              </div>
            </div>
          </NavLink>
          <NavLink className={createClassName('mark_crq')} to={{ pathname: '/crq/dashboard', state: { position: 2 } }}>
            <img src={MarkCRQ} alt="create-crq" />
            <div className="crq-description">
              <div>Mark</div>
              <div>
                <b>{getPaperTitle('mark_crq')}</b>
              </div>
            </div>
          </NavLink>
          <NavLink className={markedClassName()} to={{ pathname: '/crq/dashboard', state: { position: 3 } }}>
            <img src={MarkedPapers} alt="create-crq" />
            <div className="crq-description">
              <div>
                <b>Marked CRQs</b>
              </div>
            </div>
          </NavLink>
        </div>
        <Conditional if={groupId !== null}>
          <CrqStatusBar />
        </Conditional>
        <div className="user-dashboard-info-container">
          <Notifications />
        </div>
      </div>
    </Pane>
  );
};

export default MemberDashboard;

MemberDashboard.propTypes = {
  label: propTypes.string,
  paper: propTypes.string,
  icon: propTypes.node,
  alt: propTypes.string,
  className: propTypes.string,
  position: propTypes.number,
};

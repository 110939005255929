import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
// Components:
import ProtectedRoute, { AdminRoute } from './ProtectedRoute';
import LogIn, { ChangePassword, Welcome } from './LogIn/LogIn';
import Dashboard from '../dashboard/Dashboard/Dashboard';
import Resources from '../resource/Resources';
import Users from '../user/Users/Users';
import { UsersUpdate, CurrentUserUpdate } from '../user/UsersUpdate/UsersUpdate';
import Groups from '../group/Groups/Groups';
import GroupsShow from '../group/GroupsShow/GroupsShow';
import GroupsUpdate from '../group/GroupsUpdate/GroupsUpdate';
import Articles from '../article/Articles';
import CurrentRound from '../CurrentRound/CurrentRound';
import PreviousRounds from '../previousRounds/previousRounds';
import ResponseDashboard from '../response/ResponseDashboard/ResponseDashboard';
import ResponseCreateWrapper from '../response/ResponseCreate/ResponseCreate';
import ResponseEmptyWrapper from '../response/ResponseEmpty/ResponseEmpty';
import SubstitutePage from '../response/SubstituteCRQ/SubstituteCRQ';
import ResponseAnswer from '../response/ResponseAnswer/ResponseAnswer';
import ResponseMark from '../response/ResponseMark/ResponseMark';
import ResponseMarked from '../response/ResponseMarked/ResponseMarked';
import EmailLogs from '../emailLogs/EmailLogs'

function Routes() {
  return (
    <Switch>
      <Route exact path="/login" component={LogIn} />
      <Route exact path="/change_password/:verification" component={ChangePassword} />
      <Route exact path="/welcome/:verification" component={Welcome} />
      <ProtectedRoute exact path="/" component={Dashboard} />
      <ProtectedRoute exact path="/resources" component={Resources} />
      <AdminRoute exact path="/users" component={Users} />
      <AdminRoute exact path="/users/:id/edit" component={UsersUpdate} />
      <ProtectedRoute exact path="/users/current" component={CurrentUserUpdate} />
      <AdminRoute exact path="/emailLogs" component={EmailLogs} />
      <AdminRoute exact path="/groups" component={Groups} />
      <AdminRoute exact path="/groups/:id" component={GroupsShow} />
      <AdminRoute exact path="/groups/:id/edit" component={GroupsUpdate} />
      <ProtectedRoute exact path="/articles" component={Articles} />
      <AdminRoute exact path="/currentRound" component={CurrentRound} />
      <ProtectedRoute exact path="/previousRounds" component={PreviousRounds} />
      <ProtectedRoute exact path="/crq/dashboard" component={ResponseDashboard} />
      <ProtectedRoute exact path="/crq/create" component={ResponseCreateWrapper} />
      <ProtectedRoute exact path="/crq/substitute/:userId" component={SubstitutePage} />
      <ProtectedRoute exact path="/crq/empty/:responseId" component={ResponseEmptyWrapper} />
      <ProtectedRoute exact path="/crq/create/:userId" component={ResponseCreateWrapper} />
      <ProtectedRoute exact path="/crq/answer" component={ResponseAnswer} />
      <ProtectedRoute exact path="/crq/mark" component={ResponseMark} />
      <ProtectedRoute exact path="/crq/marked/:responseId" component={ResponseMarked} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
}

export default Routes;

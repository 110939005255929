import React from 'react';
import { Link } from 'react-router-dom';
import './ReturnButton.scss';
import propTypes from 'prop-types';
// Components:

// A return button.
function ReturnButton(props) {
  return (
    <Link to={props.to}>
      <button className="return">{props.text}</button>
    </Link>
  );
}

export default ReturnButton;

ReturnButton.propTypes = {
  to: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
};

import React from 'react';
import { get } from 'lodash';
import propTypes from 'prop-types';
// Components:
import PagingControl from './PagingControl';

function PagingRow(props) {
  const { page, data, count, total } = props;
  const pageSize = get(props.pageSize, 'value', null);
  const start = count === 0 ? 0 : page * pageSize - pageSize + 1;
  const end = count === 0 ? 0 : start + data.length - 1;

  return (
    <div className="index-table-paging-row">
      <span className="index-table-info">
        Showing {start} to {end} of {count} ({total} total) entries
      </span>
      <PagingControl {...props} />
    </div>
  );
}

export default PagingRow;

PagingRow.propTypes = {
  page: propTypes.number.isRequired,
  data: propTypes.array.isRequired,
  count: propTypes.number.isRequired,
  total: propTypes.number.isRequired,
  pageSize: propTypes.object.isRequired,
};

import React, { useState, useEffect, useContext } from 'react';
import ActionModal from '../../common/IndexTable/ActionModal';
import { LateSubmissionStyle } from '../../common/IndexTable/LateSubmissionActions';
import { ContinueSmallStyle, ContinueBigStyle } from '../../common/IndexTable/ContinueAnsweringOrMarkingActions';
import { useModal } from '../../common/Hooks/useModal';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../Response.scss';
import propTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import Api from '../../api/call';
import Download from '../../api/Download';
import {
  RESPONSES_UPDATE_MARKS_RESET_STORE,
  RESPONSES_ROLLBACK_STATUS,
  RESPONSES_ROLLBACK_STATUS_RESET_STORE,
  RESPONSES_SHOW,
  RESPONSES_SHOW_RESET_STORE,
} from '../../root/action-types';
// Components:
import {
  Pane,
  Status,
  StatusHandler,
  useForm,
  SimpleButton,
  ButtonRow,
  TabGroup,
  TabContent,
  Conditional,
} from '../../common';
import AnswerGuidance from '../AnswerGuidance/AnswerGuidance';
import MarkBox from '../MarkBox/MarkBox';
import Question from '../QuestionTypes/Question';
import ResponseMeta from '../ResponseMeta/ResponseMeta';
import TextareaAutosize from 'react-autosize-textarea';
// Reducers:
import { QuestionsContext, QuestionProvider } from '../ResponseCreate/question-manager';

const TOGGLES = {
  reset: false,
  confirmSubmit: false,
  submitted: false,
  responseLoading: true,
};

export const FinalComment = (props) => (
  <div className="comment">
    <h4>Additional Comments</h4>
    <TextareaAutosize {...props} />
  </div>
);

// unmarked are all unmarked answers for this response.
// markingForUserId is an id of an user whose answers are being marked.
// responseOwnerId is an id of an user who created this response
const intializeNextUnmarked = (unmarked, markingForUserId, responseOwnerId) => {
  if (unmarked === undefined) return undefined;

  const filtered = unmarked.filter((item) => item.state.uid != markingForUserId && item.state.uid != responseOwnerId);

  const [nextUnmarked, ...restUnmarked] = filtered;

  return nextUnmarked === undefined
    ? undefined
    : { ...nextUnmarked, state: { ...nextUnmarked.state, unmarked: restUnmarked } };
};

const ResponseMarkWrapper = (props) => (
  <QuestionProvider><ResponseMark {...props} /></QuestionProvider>
)

function ResponseMark(props) {
  const questions = useContext(QuestionsContext)
  const dispatch = useDispatch();
  const history = useHistory();
  const toggle = useForm(TOGGLES);

  const [meta, setMeta] = useState({});
  const [id, setId] = useState(null);

  const [finalComment, setFinalComment] = useState('');
  const updateFinalComment = (e) => setFinalComment(e.target.value);

  const { loading, errors, updated, unlocked } = useSelector((store) => store.responses.markResponseStatus);
  const { admin } = useSelector((store) => store.session.current);

  const response = useSelector((store) => store.responses.show);
  const copiedFrom = get(response, 'data.copiedFrom', '')

  const { responseId, uid, paperStatus, submittedLate, ecn, unmarked } = get(props, 'location.state', {});
  const userId = Number(uid);
  const nextUnmarked = intializeNextUnmarked(unmarked, userId, get(response, 'data.userId'));

  const { openModal, closeModal, modalIsOpen } = useModal();

  const autosave = () => {
    if (!toggle.form.responseLoading && !toggle.form.submitted) {
      const interval = setInterval(() => {
        submitMarks(false);
      }, 30000);
      return () => {
        clearInterval(interval);
      };
    }
  };
  useEffect(autosave, [toggle.form.submitted, toggle.form.responseLoading, questions, finalComment]);

  const loadResponse = () => {
    if (responseId === undefined) return history.push('/crq/dashboard');

    if (!toggle.form.responseLoading) {
      const data = get(response, 'data.data', '{}');
      setId(get(response, 'data.id'));
      setMeta(data.meta);
      if (data.markedFor.includes(userId)) toggle.updateForm({ submitted: true });

      questions.dispatch({
        type: 'loadMarks',
        data: data.questions,
        userId: userId,
      });
      setFinalComment(get(data.finalComment, userId, ''));
    }
  };

  const loadErrors = () => {
    if (errors) toggle.updateForm({ submitted: false });
  };

  const makeRequests = () => {
    if (responseId) Api({ request: RESPONSES_SHOW, id: responseId });

    return () => {
      dispatch({ type: RESPONSES_UPDATE_MARKS_RESET_STORE });
      dispatch({ type: RESPONSES_SHOW_RESET_STORE });
      dispatch({ type: RESPONSES_ROLLBACK_STATUS_RESET_STORE });
    };
  };

  useEffect(() => {
    if (!isEmpty(get(response, 'data'))) toggle.updateForm({ responseLoading: false });
  }, [response]);

  useEffect(loadResponse, [userId, toggle.form.responseLoading]);
  useEffect(loadErrors, [errors]);
  useEffect(makeRequests, []);

  function submitMarks(submitted) {
    if (submitted) {
      toggle.updateForm({ confirmSubmit: false, submitted: true });
    }
    questions.dispatch({
      type: 'apiSubmitMarks',
      userId,
      id,
      submitted,
      finalComment,
    });

    if (!modalIsOpen && toggle.form.confirmSubmit) {
      openModal();
    }
  }

  function resetMarks() {
    questions.dispatch({
      type: 'resetMarks',
    });
    toggle.updateForm({ reset: false });
  }

  const RenderStatus = () => {
    const successMessage = () => {
      if (unlocked) return 'CRQ has been unlocked.';

      return `CRQ marks have been ${toggle.form.submitted ? 'submitted' : 'saved'}.`;
    };

    return (
      <Status isError={errors}>
        <StatusHandler loading={loading} errors={errors} action={updated} onSuccess={successMessage()} />
      </Status>
    );
  };

  const DisplayedButtons = () => {
    if (toggle.form.reset)
      return (
        <ResetButtons
          submit={() => toggle.updateForm({ reset: false })}
          cancel={resetMarks}
          disabled={toggle.form.submitted}
        />
      );
    if (toggle.form.confirmSubmit)
      return (
        <SubmitButtons
          disabled={toggle.form.submitted}
          submit={() => submitMarks(true)}
          cancel={() => toggle.updateForm({ confirmSubmit: false })}
        />
      );
    return <ActionButtons />;
  };

  const isSubmissive = () => {
    if (toggle.form.submitted || toggle.form.responseLoading) { return false }
    if (admin) { return true }
    // Every question must have a mark
    return questions.data.filter(q => !q.name.match(/(clinical|scenario)/))
      .map(q => get(q, 'awarded[0].value', -1))
      .every(v => parseFloat(v) >= 0)
  }

  const ActionButtons = () => (
    <>
      <SimpleButton
        text="Save"
        theme="save"
        onClick={() => submitMarks(false)}
        disabled={toggle.form.submitted || toggle.form.responseLoading}
      />
      <Conditional if={admin}>
        <SimpleButton
          text="Reset"
          theme="muted"
          onClick={() => toggle.updateForm({ reset: true })}
          disabled={toggle.form.submitted || toggle.form.responseLoading}
        />
      </Conditional>
      <SimpleButton
        text="SUBMIT"
        onClick={() => toggle.updateForm({ confirmSubmit: true })}
        disabled={!isSubmissive()}
      />
    </>
  );

  const unlock = () => {
    dispatch({ type: RESPONSES_UPDATE_MARKS_RESET_STORE });
    toggle.updateForm({ submitted: false });
    const pathEnd = `${id}/rollback_status?user_id=${userId}`;
    Api({ request: RESPONSES_ROLLBACK_STATUS, id: pathEnd });
  };

  const inProgress = () => {
    if (paperStatus === 'complete') return false;
    return true;
  };

  return (
    <Pane theme="response mark">
      <DisplayModal
        openModal={openModal}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        submittedLate={submittedLate}
        nextUnmarked={nextUnmarked}
        admin={admin}
      />
      <RenderStatus />
      <Conditional if={!toggle.form.submitted}>
        <ButtonRow theme="right controls">
          <DisplayedButtons />
        </ButtonRow>
      </Conditional>
      <Conditional if={toggle.form.submitted && admin && inProgress()}>
        <ButtonRow theme="right controls">
          <CopiedFrom copiedFrom={copiedFrom} />
          <SimpleButton text="Unlock" onClick={unlock} />
        </ButtonRow>
      </Conditional>
      <TabGroup labels={['CRQ', 'AGS']}>
        <TabContent className="custom-response">
          <h2>Constructed Response Question</h2>
          <div className="field createdby">Created By: ECN-{ecn}</div>
          <ResponseMeta form={meta} finalised />
          <QuestionIndex dispatch={questions.dispatch} data={questions.data} disabled={toggle.form.submitted} />
          <FinalComment value={finalComment} onChange={updateFinalComment} disabled={toggle.form.submitted} />
        </TabContent>
        <TabContent className="custom-response">
          <AnswerGuidance questions={questions.data} dispatch={questions.dispatch} disabled={true}>
            <ResponseMeta form={meta} finalised />
          </AnswerGuidance>
        </TabContent>
      </TabGroup>
      <ButtonRow theme="right controls">
        <Conditional if={!toggle.form.submitted}>
          <DisplayedButtons />
        </Conditional>
        <Conditional if={toggle.form.submitted}>
          <div className="download-pdf">
            <Download type="crq" id={id} userId={userId} stage="MARK">
              <SimpleButton text="Export as PDF" />
            </Download>
          </div>
        </Conditional>
      </ButtonRow>
    </Pane>
  );
}

const QuestionIndex = ({ dispatch, data, disabled }) => {
  return data.map((item, idx) => {
    const itemProps = { item, idx, disabled, dispatch, itemKey: idx };

    return <QuestionWithMarkBoxMemoized key={idx} {...itemProps} />;
  });
};

const QuestionWithMarkBoxMemoized = React.memo(({ itemKey, ...itemProps }) => {
  return (
    <div className="mark-row" key={itemKey}>
      <QuestionItem {...itemProps} />
      <ShowMarkBox {...itemProps} />
    </div>
  );
});

const QuestionItem = ({ dispatch, ...props }) => {
  const { item, idx } = props;
  const questionProps = {
    item: item,
    idx: idx,
    dispatch: dispatch,
    disableAnswer: true,
    hideActions: true,
    displayOnly: true,
  };

  return <Question name={item.name} {...questionProps} />;
};

const ShowMarkBox = ({ dispatch, item, idx, disabled }) => {
  if (item.name === 'scenario') {
    return null;
  }
  if (item.name === 'clinical') {
    return null;
  }
  return <MarkBox item={item} idx={idx} dispatch={dispatch} disabled={disabled} />;
};

const ResetButtons = ({ disabled, cancel, submit }) => (
  <div className="button-container">
    <span>Are you sure you want to reset?</span>
    <SimpleButton text="Cancel" theme="muted" onClick={submit} disabled={disabled} />
    <SimpleButton text="Yes" theme="save" onClick={cancel} disabled={disabled} />
  </div>
);

const SubmitButtons = ({ disabled, submit, cancel }) => (
  <div className="button-container">
    <span>Are you sure you want to submit?</span>
    <SimpleButton text="Cancel" theme="muted" onClick={cancel} disabled={disabled} />
    <SimpleButton text="Yes, Submit" theme="save" onClick={submit} disabled={disabled} />
  </div>
);

const DisplayModal = ({ nextUnmarked, submittedLate, openModal, closeModal, modalIsOpen, admin }) => {
  const examsToMark = nextUnmarked !== undefined;

  if (examsToMark) {
    const customStyle = admin || !submittedLate ? ContinueSmallStyle : ContinueBigStyle;
    const progressionQuestion = "Are you ready to mark the next available CRQ?"
    return (
      <ActionModal
        data={{
          submittedLate,
          isAdmin: admin,
          progressionQuestion: progressionQuestion,
          nextUnfinished: nextUnmarked,
        }}
        customStyle={customStyle}
        openModal={openModal}
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        model="CONTINUE_ANSWERING_OR_MARKING"
      />
    );
  } else if (submittedLate && !admin) {
    return (
      <ActionModal
        openModal={openModal}
        closeModal={closeModal}
        customStyle={LateSubmissionStyle}
        modalIsOpen={modalIsOpen}
        model="LATE_SUBMISSION"
      />
    );
  }
  return null;
};

const CopiedFrom = ({ copiedFrom }) => {
  if (copiedFrom) {
    return <div>Substituted from {copiedFrom}</div>;
  }
  return null;
};

export default ResponseMarkWrapper;

ResponseMark.propTypes = {
  response: propTypes.shape({
    loading: propTypes.bool,
    errors: propTypes.array,
    created: propTypes.bool,
  }),
  item: propTypes.shape({
    name: propTypes.string,
  }),
  idx: propTypes.number,
  match: propTypes.shape({
    params: propTypes.shape({
      id: propTypes.string,
    }),
  }),
};

import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import propTypes from 'prop-types';

import { Conditional, TabContent, usePapers, useArticles } from '../../common';
import { TopicBar, AdminTopicBar } from './ResponseDashboard';

function ResponseAnswerTab(props) {
  const { paperForStatus } = usePapers();
  const answerResponses = useSelector((store) => store.responses.answerResponses);
  const responses = useSelector((store) => store.responses.forGroup);
  const propResponses = get(props, 'responses');

  const currentPaper = props.admin ? props.currentPaper : paperForStatus('answer_crq');
  const submittedLate = new Date() > new Date(get(currentPaper, 'answerDeadline'));
  const { selected } = useArticles(get(currentPaper, 'id'));
  const articles = props.admin ? props.articles : selected;

  const maxPos = Math.max.apply(
    Math,
    get(props, 'users', []).map((u) => parseInt(u.number))
  );

  const getUserUnansweredResponses = () => {
    let unanswered = [];
    for (let i = 0; i < Math.max(0, maxPos); i++) {
      const othersResponses = answerResponses.data.filter((e) => e.userId !== props.currentUser.id);
      const response = othersResponses.find((r) => r.articleId === get(articles, [i + 1, 'id']));
      const answered = response && response.data.answeredBy.includes(props.currentUser.id);
      if (response !== undefined && !answered)
        unanswered = [
          ...unanswered,
          {
            pathname: '/crq/answer',
            state: {
              submittedLate: submittedLate,
              responseId: response.id,
            },
          },
        ];
    }

    return unanswered;
  };

  const getUserData = (i, unansweredResponses) => {
    const othersResponses = answerResponses.data.filter((e) => e.userId !== props.currentUser.id);
    const response = othersResponses.find((r) => r.articleId === get(articles, [i + 1, 'id']));
    const answered = response && response.data.answeredBy.includes(props.currentUser.id);

    const topicClass = response ? 'userCRQ' : answered ? 'stage-completed-CRQ' : '';
    return {
      to: response
        ? {
            pathname: '/crq/answer',
            state: {
              submittedLate: submittedLate,
              unanswered: unansweredResponses,
              responseId: response.id,
            },
          }
        : props.match.url,
      title: get(articles, [i + 1, 'title']),
      attachment: get(articles, [i + 1, 'attachment']),
      topicClass,
      btnClass: answered ? 'stage-completed-CRQ' : response ? 'userCRQ' : '',
      btnText: answered ? 'Completed' : response ? 'Answer' : '',
    };
  };

  const CreateUserTopics = () => {
    const topics = [];

    const allUnanswered = getUserUnansweredResponses();
    for (let i = 0; i < Math.max(0, maxPos); i++) {
      const data = getUserData(i, allUnanswered);
      topics.push(<TopicBar {...data} key={i} prefix={i + 1} />);
    }
    return topics;
  };

  const getAdminUnansweredResponses = (user) => {
    let unanswered = [];
    for (let j = 0; j <= Math.max(0, maxPos); j++) {
      if (user && j != user.number) {
        const response =
          responses.count > 1
            ? responses.data.find((r) => r.articleId === get(articles, [j, 'id']))
            : (propResponses || []).find((r) => r.articleId === get(articles, [j, 'id']))
        const answeredBy = response ? get(response, 'data').answeredBy : [];
        const answered = answeredBy.includes(get(user, 'id'));

        if (!answered && response) {
          unanswered.push({
            pathname: '/crq/answer',
            state: {
              uid: user.id,
              responseId: get(response, 'id'),
              submittedLate: submittedLate,
              admin: props.admin,
              paperStatus: currentPaper.status,
            },
          });
        }
      }
    }
    return unanswered;
  };

  const getAdminData = (user, unansweredResponses) => {
    const otherNumbers = (props.users || []).map(u => u.number).filter(n => n != user.number)
    const questions = otherNumbers.map(j => {
      if (user && j != user.number) {
        const response =
          responses.count > 1
            ? get(responses, 'data', []).find((r) => r.articleId === get(articles, [j, 'id']))
            : (propResponses || []).find((r) => r.articleId === get(articles, [j, 'id']));
        const answeredBy = response ? get(response, 'data').answeredBy : [];
        const answered = answeredBy.includes(get(user, 'id'));

        const topicClass = answered ? 'stage-completed-CRQ' : 'stage-unstarted-CRQ';
        return {
          to: response
            ? {
                pathname: '/crq/answer',
                state: {
                  uid: user.id,
                  responseId: get(response, 'id'),
                  submittedLate: submittedLate,
                  unanswered: unansweredResponses,
                  admin: props.admin,
                  paperStatus: currentPaper.status,
                },
              }
            : props.match.url,
          topicClass,
          btnClass: topicClass,
          answered: answered,
          btnText: response ? `Q #${j}` : '',
        }
      }
    })

    return {
      title: user ? `${user.eClubNumber} - ${user.firstName} ${user.lastName}` : '',
      uid: user && user.id,
      questions,
    };
  };

  const CreateAdminTopics = () => (
    (props.users || []).map(u => {
      const unanswered = getAdminUnansweredResponses(u)
      return (
        <AdminTopicBar
          {...getAdminData(u, unanswered)}
          key={u.number}
          prefix={u.number}
        />
      )
    })
  )

  const checkVisibility = () => {
    const paperStatus = get(currentPaper, 'status', 'initial');
    return ['answer_crq', 'mark_crq', 'complete'].includes(paperStatus);
  };

  return (
    <TabContent className="response-pane">
      <h2>{get(currentPaper, 'title')}</h2>
      <Conditional if={!props.blocked}>
        {checkVisibility() ? (
          props.admin ? (
            <CreateAdminTopics />
          ) : (
            <CreateUserTopics />
          )
        ) : (
          'This stage has not been initiated.'
        )}
      </Conditional>
      <Conditional if={props.blocked}>
        <h4>You do not have permission to view this tab.</h4>
      </Conditional>
    </TabContent>
  );
}

export default ResponseAnswerTab;

ResponseAnswerTab.propTypes = {
  currentUser: propTypes.shape({
    id: propTypes.number,
  }),
  articles: propTypes.object,
  match: propTypes.shape({
    url: propTypes.string,
  }),
  admin: propTypes.bool,
  paperStatus: propTypes.string,
  users: propTypes.array,
  blocked: propTypes.bool,
  currentPaper: propTypes.object,
};

import { combineReducers } from 'redux';
import {
  SESSION_LOGIN,
  SESSION_LOGIN_RESET_STORE,
  PASSWORD_RESET,
  CURRENT_USER,
  PASSWORD_CHANGE,
} from './action-types';
import { setAuthToken, setUser } from '../api/call';

const DEFAULT_SESSION_STATE = { details: {}, error: null, active: false, loading: false };
export const details = (state = DEFAULT_SESSION_STATE, action) => {
  switch (action.type) {
    case `${SESSION_LOGIN}_START`:
    case `${PASSWORD_RESET}_START`:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case `${SESSION_LOGIN}_SUCCESS`: {
      setAuthToken(action.response.data.authToken);
      setUser(action.response.data.user);
      return {
        ...state,
        active: true,
        error: null,
        loading: false,
      };
    }
    case `${PASSWORD_RESET}_SUCCESS`:
      return {
        ...state,
        loading: false,
        reset: true,
      };
    case `${SESSION_LOGIN}_FAILURE`:
    case `${PASSWORD_RESET}_FAILURE`:
      return {
        ...state,
        error: action.response.data.error,
        loading: false,
      };
    case SESSION_LOGIN_RESET_STORE:
      return DEFAULT_SESSION_STATE;
    default:
      return state;
  }
};

export const current = (state = {}, action) => {
  switch (action.type) {
    case `${CURRENT_USER}_START`: {
      return { ...state, loading: true };
    }
    case `${CURRENT_USER}_SUCCESS`: {
      return {
        ...state,
        ...action.response.data,
        loading: false,
      };
    }
    case `${CURRENT_USER}_FAILURE`: {
      return {
        ...state,
        error: action.response.data.error,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

const change = (state = {}, action) => {
  switch (action.type) {
    case `${PASSWORD_CHANGE}_START`:
      return { loading: true, errors: [] };
    case `${PASSWORD_CHANGE}_SUCCESS`:
      return { loading: false, success: true, errors: [] };
    case `${PASSWORD_CHANGE}_FAILURE`:
      return { loading: false, errors: action.response.data.errors };
    default:
      return state;
  }
};

const session = combineReducers({
  current,
  details,
  change,
});

export default session;

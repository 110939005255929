import React, { Fragment } from 'react';
import propTypes from 'prop-types';
// Components:

import NavItem from './NavItem.jsx';

function MemberNavBar() {
  return (
    <Fragment>
      <NavItem text="Dashboard" path="/" />
      {/* <NavItem text="Current Round" path="/currentRound" /> */}
      <NavItem text="Previous Round" path="/previousRounds" />
      {/* <NavItem text="Papers" path="/crq/dashboard" /> */}
      <NavItem text="Resources" path="/resources" />
      <NavItem text="Settings" path="/users/current" />
      <aside className="queries">
        <h4>Queries?</h4>
        <a href="mailto:e-club@msoa.org.uk">e-club@msoa.org.uk</a>
      </aside>
    </Fragment>
  );
}

export default MemberNavBar;

MemberNavBar.propTypes = {
  id: propTypes.string,
  location: propTypes.object,
};

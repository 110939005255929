import { combineReducers } from 'redux';
import { CURRICULUM_CODES_CREATE, CURRICULUM_CODES } from '../../root/action-types';
import { standardModelCreateUpdateDestroy } from '../../api/standardReducers';

const DefaultState = { loading: true, data: [] };

const index = (state = DefaultState, action) => {
  switch (action.type) {
    case `${CURRICULUM_CODES}_START`:
    case `${CURRICULUM_CODES_CREATE}_START`:
      return { ...state, loading: true };

    case `${CURRICULUM_CODES}_SUCCESS`:
    case `${CURRICULUM_CODES_CREATE}_SUCCESS`:
      return { ...state, data: action.response.data, loading: false };

    case `${CURRICULUM_CODES}_FAILURE`:
    case `${CURRICULUM_CODES_CREATE}_FAILURE`:
      return { ...state, errors: action.response.data.errors, loading: false };

    case `${CURRICULUM_CODES}_RESET_STORE`:
    case `${CURRICULUM_CODES_CREATE}_RESET_STORE`:
      return DefaultState;

    default:
      return state;
  }
};

export default combineReducers({
  index,
  curriculumCodes: standardModelCreateUpdateDestroy('curriculum_codes', CURRICULUM_CODES),
});

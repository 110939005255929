import { combineReducers } from 'redux';
import { get } from 'lodash'
import {
  RESPONSES,
  RESPONSES_FOR_GROUP,
  RESPONSES_SHOW,
  RESPONSES_MARKED,
  RESPONSES_UPDATE_ANSWERS,
  RESPONSES_UPDATE_MARKS,
  RESPONSES_ROLLBACK_STATUS,
  RESPONSES_CLEAR_ERROR,
  CURRENT_RESPONSE,
  CURRENT_ANSWER_RESPONSES,
  CURRENT_MARK_RESPONSES,
} from '../root/action-types';
import { standardForCollections, standardShow } from '../api/standardReducers';

const responseStatusReducer = (action_name) => {
  const DEFAULT_STATE = { updated: false };
  return (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case `${RESPONSES_ROLLBACK_STATUS}_START`:
      case `${action_name}_START`:
        return {
          ...state,
          updated: false,
          unlocked: false,
          loading: true,
        };
      case `${action_name}_SUCCESS`:
        return {
          ...state,
          updated: true,
          errors: null,
          loading: false,
        };
      case `${RESPONSES_ROLLBACK_STATUS}_SUCCESS`:
        return {
          ...state,
          updated: true,
          unlocked: true,
          errors: null,
          loading: false,
        };
      case `${RESPONSES_ROLLBACK_STATUS}_FAILURE`:
      case `${action_name}_FAILURE`:
        return {
          ...state,
          errors: action.response.data.errors,
          errorFields: action.response.data.errorFields,
          errorData: action.response.data.errorData,
          updated: false,
          loading: false,
        };
      case `${RESPONSES_ROLLBACK_STATUS}_RESET_STORE`:
      case `${action_name}_RESET_STORE`:
        return DEFAULT_STATE;
      default: {
        return state;
      }
    }
  };
};

export const createStatusReducer = () => {
  const DEFAULT_CRUD_STATE = {
    created: false,
    updated: false,
    destroyed: false,
    errorData: {}
  };

  return (state = DEFAULT_CRUD_STATE, action) => {
    switch (action.type) {
      case `${RESPONSES}_CREATE_START`:
      case `${RESPONSES}_UPDATE_START`:
      case `${RESPONSES}_DESTROY_START`:
      case `${RESPONSES_ROLLBACK_STATUS}_START`:
        return {
          ...state,
          success: false,
          created: false,
          updated: false,
          unlocked: false,
          destroyed: false,
          loading: true,
        };
      case `${RESPONSES}_CREATE_SUCCESS`:
        return {
          ...state,
          created: true,
          success: true,
          data: action.response.data.details,
          errors: action.response.data.errors,
          errorFields: action.response.data.errorFields,
          errorData: action.response.data.errorData,
          loading: false,
        };
      case `${RESPONSES}_UPDATE_SUCCESS`:
        return {
          ...state,
          updated: true,
          success: true,
          data: action.response.data.details,
          errors: action.response.data.errors,
          errorFields: action.response.data.errorFields,
          errorData: action.response.data.errorData,
          loading: false,
        };
      case `${RESPONSES_ROLLBACK_STATUS}_SUCCESS`:
        return {
          ...state,
          updated: true,
          success: true,
          unlocked: true,
          data: action.response.data.details,
          errors: action.response.data.errors,
          errorFields: action.response.data.errorFields,
          errorData: action.response.data.errorData,
          loading: false,
        };
      case `${RESPONSES}_DESTROY_SUCCESS`:
        return {
          ...state,
          destroyed: true,
          success: true,
          data: action.response.data.details,
          errors: action.response.data.errors,
          errorFields: action.response.data.errorFields,
          errorData: action.response.data.errorData,
          loading: false,
        };
      case `${RESPONSES}_CREATE_FAILURE`:
      case `${RESPONSES}_UPDATE_FAILURE`:
      case `${RESPONSES}_DESTROY_FAILURE`:
      case `${RESPONSES_ROLLBACK_STATUS}_FAILURE`:
        return {
          ...state,
          errors: action.response.data.errors,
          errorFields: action.response.data.errorFields,
          errorData: action.response.data.errorData,
          loading: false,
        };
      case `${RESPONSES}_CREATE_RESET_STORE`:
      case `${RESPONSES}_UPDATE_RESET_STORE`:
      case `${RESPONSES}_DESTROY_RESET_STORE`:
      case `${RESPONSES_ROLLBACK_STATUS}_RESET_STORE`:
        return DEFAULT_CRUD_STATE;
      case RESPONSES_CLEAR_ERROR:
        return {
          ...state,
          errorData: {
            ...state.errorData,
            [action.section]: get(state.errorData, action.section, [])
              .filter(f => f != action.field)
          }
        }
      default: {
        return state;
      }
    }
  };
};

export default combineReducers({
  index: standardForCollections(RESPONSES),
  show: standardShow(RESPONSES_SHOW),
  current: standardShow(CURRENT_RESPONSE),
  answerResponses: standardForCollections(CURRENT_ANSWER_RESPONSES),
  markResponse: standardShow(CURRENT_MARK_RESPONSES),
  marked: standardShow(RESPONSES_MARKED),
  answerResponseStatus: responseStatusReducer(RESPONSES_UPDATE_ANSWERS),
  markResponseStatus: responseStatusReducer(RESPONSES_UPDATE_MARKS),
  responseStatus: createStatusReducer(RESPONSES),
  forGroup: standardForCollections(RESPONSES_FOR_GROUP),
});

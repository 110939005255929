import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CURRENT_RESPONSE } from '../../root/action-types';
import { get } from 'lodash';
import Api from '../../api/call';

export const useCurrentResponse = () => {
  const currentResponse = useSelector((store) => store.responses.current);

  const getResponse = () => {
    Api({ request: CURRENT_RESPONSE });
  };
  useEffect(getResponse, []);

  const load = get(currentResponse, 'data');

  let status = 'initial';
  if (load) {
    status = load.status;
  }

  const active = !!load || status === 'initial';
  const complete = load && status !== 'initial';

  return { active, complete };
};

export default useCurrentResponse;

import moment from 'moment';
import { orderBy } from 'lodash';

function past(date) {
  const today = new Date();
  const format = (date) => moment(date).startOf('day');

  if (format(date) < format(today)) {
    return;
  }

  return date;
}

export const useDeadlines = (papers) => {
  const all =
    papers &&
    papers
      .map((p) => [p.createDeadline, p.answerDeadline, p.markDeadline])
      .flat()
      .map((d) => new Date(d))
      .filter((d) => past(d));

  const ordered = all && orderBy(all);

  const nextDeadline = ordered && moment(ordered[0]).format('MMM Do');

  return { nextDeadline };
};

export default useDeadlines;

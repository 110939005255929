import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RESPONSES_FOR_GROUP } from '../root/action-types';
import Api from '../api/call';

const useGroupResponses = (groupId) => {
  const [responses, setResponses] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { loading, data } = useSelector((store) => store.responses.forGroup);

  useEffect(() => {
    if (groupId) {
      Api({ request: RESPONSES_FOR_GROUP, id: `?group_id=${groupId}` });
    }
  }, [groupId]);

  useEffect(() => {
    if (loading === false) {
      setResponses(data);
      setLoaded(true);
    }
  }, [loading, data]);

  return { responses, loaded };
};

export default useGroupResponses;

import React from 'react'

import { EMAIL_LOGS } from '../root/action-types'

import ModelIndexTable from '../common/IndexTable/ModelIndexTable'
import { Pane } from '../common/'

const COLUMNS = ['to', 'subject', 'status', 'updatedAt']

const EmailLogs = () => (
  <Pane theme="background">
    <h2>Email Logs</h2>
    <ModelIndexTable
      model="emailLogs"
      dataMap={(row) => ({ ...row })}
      requestAction={EMAIL_LOGS}
      COLUMNS={COLUMNS}
      defaultColumns={COLUMNS}
      noShow
    />
  </Pane>
)

export default EmailLogs

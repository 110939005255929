import React, { Fragment } from 'react';
import './QuestionPicker.scss';
import propTypes from 'prop-types';
// Components:
import ButtonRow from '../../common/ButtonRow/ButtonRow';
// Assets:
import Scenario from '../../root/assets/scenario.svg';
import ClinicalData from '../../root/assets/clinical_data.svg';
import ShortAnswer from '../../root/assets/short_answer.svg';
import List from '../../root/assets/list.svg';
import FillableTable from '../../root/assets/fillable_table.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

function QuestionPicker(props) {
  const ButtonContainer = ({ title, name, src, alt }) => (
    <button name={name} className="button-container" onClick={props.addQuestion}>
      <img src={src} alt={alt} />
      <div>{title}</div>
    </button>
  );

  const QuestionTypes = () => (
    <Fragment>
      <ButtonContainer name="scenario" title="Scenario" src={Scenario} alt="scenario-question" />
      <ButtonContainer name="clinical" title="Clinical Data" src={ClinicalData} alt="clinical-data-question" />
      <ButtonContainer name="short" title="Short Answer" src={ShortAnswer} alt="short-answer-question" />
      <ButtonContainer name="list" title="List" src={List} alt="list-question" />
      <ButtonContainer name="fillable" title="Fillable Table" src={FillableTable} alt="fillable-table-question" />
    </Fragment>
  );

  return (
    <div className="question-picker-container">
      <div
        className={`question-picker ${props.toggle ? 'toggled' : ''}`}
        onClick={props.toggle ? null : props.setToggle}
      >
        <div className="icon" onClick={() => props.setToggle(false)}>
          {props.toggle ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
        </div>
        <ButtonRow>
          {props.toggle ? <QuestionTypes /> : <div className="toggled-text"> Click to add a question... </div>}
        </ButtonRow>
      </div>
    </div>
  );
}

export default QuestionPicker;

QuestionPicker.propTypes = {
  setToggle: propTypes.func.isRequired,
  toggle: propTypes.bool.isRequired,
  title: propTypes.string,
  src: propTypes.node,
  name: propTypes.string,
  alt: propTypes.string,
  resetPicker: propTypes.func,
  addQuestion: propTypes.func,
};
